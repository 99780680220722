import { useCallback, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';

import { AlertItemProps } from '@/logicLayers/infrastructure/services';

import { Alert } from '@/separatedModules/components';

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction={'right'} timeout={300} />;
};

interface SnackbarItemProps extends AlertItemProps {}

export const SnackbarItem = function SnackbarItem({
  severity,
  action,
  title,
  autoHideDuration,
  content,
  isHideCloseBtn,
  onCloseAction,
  onFooterAction
}: SnackbarItemProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const [isOpen, setIsOpen] = useState(true);

  const handleCloseAction = useCallback(() => {
    setIsOpen(false);

    onCloseAction?.();
  }, []);

  const handleFooterAction = useCallback(() => {
    onFooterAction?.();
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: isSmallScreen || isTablet ? 'center' : 'left' }}
      sx={{ position: 'fixed' }}
      open={isOpen}
      TransitionComponent={TransitionDown}
      autoHideDuration={autoHideDuration}
    >
      <div>
        <Alert
          variant={'notice'}
          severity={severity}
          title={title}
          content={content}
          action={action}
          isHideCloseBtn={isHideCloseBtn}
          onCloseAction={handleCloseAction}
          onFooterAction={handleFooterAction}
        />
      </div>
    </Snackbar>
  );
};
