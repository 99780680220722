import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import { CREATE_TASK, CREATE_UNPLANNED_TASK } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { ModalDiscardAllChanges, MultiStepFooter, MultiStepHeader } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { Steps } from './components/Steps';
import { useContextState } from './ContextProvider';

import {
  DEFAULT_VALUES,
  FIELD_TASK_END_DATE,
  FIELD_TASK_SERVICE_STUFF,
  FIELD_TASK_START_DATE,
  FORM_ID,
  FormProps,
  isStartDateBeforeEndDate,
  maxDate,
  minDate,
  TaskTypes
} from './helpers';

export const ContextConsumer = () => {
  const { t: tTasks } = useTranslation('tasks');
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const methods = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const [CreateTask] = useMutation(CREATE_TASK);
  const [CreateUnplannedTask] = useMutation(CREATE_UNPLANNED_TASK);

  const watchedFields = useWatch({ control: methods.control });

  const isUnplannedTask = state.taskType === TaskTypes.UNPLANNED;

  const handleSuccess = () => {
    setState((state) => ({ ...state, isDataCreated: true }));

    AlertService.showAlert({
      title: tGlobal('alertMessages.success.taskCreated'),
      severity: 'success'
    });

    setTimeout(() => {
      setState((state) => ({ ...state, isDataCreated: false }));

      navigateTo(-1);
    }, 2500);
  };

  const handleError = () => {
    setState((state) => ({ ...state, isDataCreate: true }));

    AlertService.showAlert({
      title: tGlobal('alertMessages.errors.base'),
      severity: 'error'
    });
  };

  const handleCreateCustomTask = (data: FormProps) => {
    const startDate = data[FIELD_TASK_START_DATE]?.date.format('YYYY-MM-DD');
    const startTime = data[FIELD_TASK_START_DATE]?.time.format('HH:mm:ss');

    const endDate = data[FIELD_TASK_END_DATE]?.date.format('YYYY-MM-DD');
    const endTime = data[FIELD_TASK_END_DATE]?.time.format('HH:mm:ss');

    if (!isUnplannedTask) {
      CreateTask({
        variables: {
          request: {
            name: data.title,
            description: data.description,
            startDate: `${startDate}T${startTime}`,
            endDate: `${endDate}T${endTime}`,
            performer: (data.serviceStuff || [])[0],
            addComment: data.additionalMonitoring?.addComment,
            addPhoto: data.additionalMonitoring?.addPhoto
          }
        },
        onCompleted(data) {
          if (data?.createTask?.id) {
            handleSuccess();
          }
        },
        onError() {
          handleError();
        }
      });
    } else {
      CreateUnplannedTask({
        variables: {
          createRequest: {
            name: data.title,
            description: data.description,
            activationDate: `${startDate}T${startTime}`,
            serviceStaff: data.serviceStuff,
            roundRegulationId: data.roundTasks?.[0]
          }
        },
        onCompleted() {
          handleSuccess();
        },
        onError() {
          handleError();
        }
      });
    }
  };

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      if (
        (state.currentStep === 3 &&
          (!data[FIELD_TASK_START_DATE]?.date.isValid() ||
            !data[FIELD_TASK_START_DATE]?.time.isValid() ||
            data[FIELD_TASK_START_DATE]?.date < minDate ||
            data[FIELD_TASK_START_DATE]?.date > maxDate)) ||
        (state.currentStep === 4 &&
          state.taskType === TaskTypes.CUSTOM &&
          (!data[FIELD_TASK_END_DATE]?.date.isValid() ||
            !data[FIELD_TASK_END_DATE]?.time.isValid() ||
            data[FIELD_TASK_END_DATE]?.date < minDate ||
            data[FIELD_TASK_END_DATE]?.date > maxDate ||
            !isStartDateBeforeEndDate(data[FIELD_TASK_START_DATE], data[FIELD_TASK_END_DATE]))) ||
        (state.currentStep === 4 &&
          state.taskType === TaskTypes.UNPLANNED &&
          (!data[FIELD_TASK_END_DATE]?.date.isValid() ||
            !data[FIELD_TASK_END_DATE]?.time.isValid() ||
            data[FIELD_TASK_END_DATE]?.date < minDate))
      ) {
        return;
      }

      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else if (state.taskType === TaskTypes.CUSTOM || state.taskType === TaskTypes.UNPLANNED) {
      handleCreateCustomTask(data);
    }
  });

  const selectedServiceStuff = methods.watch(FIELD_TASK_SERVICE_STUFF);

  const isSelectedRound = state.taskType === TaskTypes.UNPLANNED && state.currentStep === 2;
  const isMore = state.currentStep > 1 && state.taskType === TaskTypes.UNPLANNED && state.currentStep !== 3;
  const isFirstStep = state.currentStep === 1;
  const isDisabledButton =
    state.currentStep === 2 && state.taskType === TaskTypes.UNPLANNED && !watchedFields?.roundTasks?.length;

  useEffect(() => {
    if (state.currentStep === 1) {
      methods.reset();
    }
  }, [state.currentStep]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={isMore ? 10 : 8} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} onSubmit={handleSubmitForm}>
            <ModalDiscardAllChanges navigateToUrl={-1 as any} sxBox={{ margin: '0 0 32px' }} />
            <MultiStepHeader
              title={
                isSelectedRound ? tTasks('createTask.multiStep.selectRoundTitle') : tTasks('createTask.multiStep.title')
              }
              titleStep={tTasks('createTask.multiStep.titleStep', {
                currentStep: state.currentStep,
                totalSteps: state.totalSteps
              })}
              description={
                !isFirstStep && (
                  <Text sx={{ marginBottom: '16px' }}>
                    {isSelectedRound
                      ? tTasks('createTask.multiStep.roundDescription')
                      : isUnplannedTask
                        ? tTasks('createTask.multiStep.unplannedDescription')
                        : tTasks('createTask.multiStep.description')}
                  </Text>
                )
              }
              currentStep={state.currentStep}
              totalStep={state.totalSteps}
              sxDescription={{ maxWidth: 660 }}
            />
            <Steps step={`step${state.currentStep}`} />
            <MultiStepFooter
              btnNextStepText={tGlobal('multiStep.btnNextStep')}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={
                selectedServiceStuff?.length
                  ? tTasks('createTask.multiStep.btnSelectCreateTask')
                  : tTasks('createTask.multiStep.btnCreateTask')
              }
              isLoadingBtnCreate={false}
              isDisabledBtnCreate={false}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              handleSetPrevStep={handleSetPrevStep}
              isRound={isMore}
              isDisabled={isDisabledButton}
            />
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
