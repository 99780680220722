import classNames from 'classnames';

import { TextBlockControl, TextBlockControlProps } from './../TextBlockControl';
import { AdditionalBlockWrapper, AdditionalBlockWrapperProps } from './components/AdditionalBlockWrapper';

import { StyledAdditionalMonitoringControl } from './style';

type TextFieldControlProps = AdditionalBlockWrapperProps & TextBlockControlProps;

export const AdditionalMonitoringControl = ({
  title = '',
  subTitle,
  sxTitle,
  sxSubTitle,
  ...rest
}: TextFieldControlProps) => {
  const isHasTitles = !title && !subTitle;

  return (
    <StyledAdditionalMonitoringControl
      className={classNames({
        'no-titles': isHasTitles
      })}
    >
      <TextBlockControl
        className={'control-text-field__text-wrp'}
        title={title}
        subTitle={subTitle}
        sxTitle={sxTitle}
        sxSubTitle={sxSubTitle}
      />
      <AdditionalBlockWrapper {...rest} />
    </StyledAdditionalMonitoringControl>
  );
};
