interface TypeList {
  [key: string]: string;
}

export const PARAMETER_TYPE: TypeList = {
  DATA_TIME: 'Date & Time',
  STRING: 'String',
  BOOLEAN: 'Boolean',
  NUMERIC_INPUT: 'Data input',
  NUMERIC_FORMULA: 'Set formula',
  NUMERIC_FIXED: 'Fixed value'
};
