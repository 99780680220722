import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { GET_DASHBOARDS, GetDashboardsQuery } from '@/logicLayers/domain';

import { PlusIcon, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ManageAccessModal } from './components/ManageAccessModal';

import { DashboardItem } from './helpers';

export const WidgetsPage = () => {
  const { t: tWidgets } = useTranslation('widgets');
  const navigateTo = useNavigate();

  const { data: dataWidgets, loading } = useQuery<GetDashboardsQuery>(GET_DASHBOARDS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          page: 0,
          pageSize: 100
        }
      }
    }
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const renderWidgets = dataWidgets?.dashboards.items;

  return (
    <Grid justifyContent={'space-between'} container row>
      <Grid xs={12} item sx={{ minHeight: '70vh' }}>
        {loading ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
        ) : renderWidgets?.length ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: '#F5F7FA',
              borderRadius: '6px',
              padding: '12px'
            }}
          >
            <Box sx={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
              {renderWidgets.map((dashboardItem: DashboardItem, index) => {
                const isSelected = selectedIndex === index;
                return (
                  <Box
                    key={dashboardItem?.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#FFF',
                      padding: '8px 16px',
                      borderRadius: '2px',
                      border: `1px solid ${isSelected ? '#326CC9' : 'rgba(41, 49, 62, 0.06)'}`,
                      cursor: 'pointer'
                    }}
                    onClick={() => setSelectedIndex(index)}
                  >
                    <Text
                      sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: isSelected ? '#326CC9' : '#44464E'
                      }}
                    >
                      {dashboardItem?.name}
                    </Text>
                  </Box>
                );
              })}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFF',
                  borderRadius: '2px',
                  border: '1px solid rgba(41, 49, 62, 0.06)'
                }}
              >
                <IconButton
                  sx={{ height: '38px', width: '38px' }}
                  onClick={() => navigateTo(LINKS_PAGES.createWidgets)}
                >
                  <PlusIcon sx={{ fill: '#265299' }} />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => setIsModalOpen(true)}>
                {tWidgets('widgets.btnShare')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '70vh'
            }}
          >
            <Text sx={{ fontSize: '28px', fontWeight: '600', color: '#121B2A', marginBottom: '16px' }}>
              {tWidgets('widgets.noWidgetsTitle')}
            </Text>
            <Text sx={{ fontSize: '16px', color: '#505864', marginBottom: '32px' }}>
              {tWidgets('widgets.noWidgetsSubTitle')}
            </Text>
            <Button startIcon={<PlusIcon />} variant="contained" onClick={() => navigateTo(LINKS_PAGES.createWidgets)}>
              {tWidgets('widgets.btnCreateWidget')}
            </Button>
          </Box>
        )}
        <ManageAccessModal
          dashboardId={renderWidgets?.[selectedIndex]?.id ?? ''}
          dashboardName={renderWidgets?.[selectedIndex]?.name ?? ''}
          isModalOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
        />
      </Grid>
    </Grid>
  );
};
