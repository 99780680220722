import { toPng } from 'html-to-image';
import JSZip from 'jszip';

import { i18n } from '@/i18n/config';

import { qrCode } from '../../../Providers/helpers';

export const generateQRCodeWithText = async (qrValue: string, size: number): Promise<string> => {
  qrCode.update({
    data: qrValue,
    width: size,
    height: size,
    margin: 6,
    dotsOptions: {
      type: 'square'
    },
    backgroundOptions: {
      color: 'transparent'
    }
  });

  const qrBlob = await qrCode.getRawData('png');

  if (!qrBlob) {
    throw new Error('Failed to generate QR code blob.');
  }

  const qrImage = await loadImageFromBlob(qrBlob);

  const padding = 20;

  const canvas = document.createElement('canvas');
  canvas.width = size + padding;
  canvas.height = size + padding;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get canvas context.');
  }

  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const x = (canvas.width - size) / 2;
  const y = (canvas.height - size) / 2;

  ctx.drawImage(qrImage, x, y, size, size);

  const qrDataUrl = canvas.toDataURL('image/png');

  return qrDataUrl;
};

const loadImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(img);
    };
    img.onerror = (e) => {
      URL.revokeObjectURL(url);
      reject(e);
    };
    img.src = url;
  });
};

export const createSticker = async (item: any, size: number, breadcrumbsLinks: any[]): Promise<string> => {
  const stickerContainer = document.createElement('div');
  stickerContainer.style.backgroundColor = 'white';
  stickerContainer.style.padding = '17px';
  stickerContainer.style.width = `${size}px`;
  stickerContainer.style.height = `${size}px`;
  stickerContainer.style.display = 'flex';
  stickerContainer.style.flexDirection = 'column';
  stickerContainer.style.justifyContent = 'space-between';

  const titleFontSize = size * 0.0877;
  const spanFontSize = Math.max(0, titleFontSize - 2);

  stickerContainer.innerHTML = `
      <div>
        <h1 style="font-size: ${titleFontSize}px; margin-bottom: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;">${item?.name}</h1>
        <div style="display: flex; flex-wrap: wrap; margin-top: 4px;">
        ${breadcrumbsLinks
          .map(({ titleFallback }, index) => {
            const isFirstItem = index === 0;
            const maxWidth = Math.min(spanFontSize * 10, size * 0.8);

            return `
            <div class="breadcrumbs__item" style="display: flex; align-items: center;">
              ${isFirstItem ? '' : '<span style="margin: 0px 5px;">&gt;</span>'} 
              <p style="
                margin: 0; 
                font-size: ${spanFontSize}px; 
                display: -webkit-box; 
                -webkit-line-clamp: 1; 
                text-overflow: ellipsis; 
                -webkit-box-orient: vertical; 
                max-width: ${maxWidth}px;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-all;">
                ${titleFallback}
              </p>
            </div>`;
          })
          .join('')}
        </div>
      </div>
      <div>
        <h1 style="font-size: ${titleFontSize}px;">System name</h1>
        <div style="margin-top: 4px;"> 
          <span style="font-size: ${spanFontSize}px;">${item?.systemName}</span>
        </div>
      </div>
    `;

  document.body.appendChild(stickerContainer);
  const stickerPngData = await toPng(stickerContainer, { quality: 1 });
  document.body.removeChild(stickerContainer);

  return stickerPngData;
};

export const addFilesToZip = (
  zip: JSZip,
  folderName: string,
  qrData: string,
  stickerData: string,
  fileName: string
) => {
  const folder = zip.folder(folderName || '');
  folder?.file(`QR_${fileName}.png`, qrData.split(',')[1], { base64: true });
  folder?.file(`SCR_${fileName}.png`, stickerData.split(',')[1], { base64: true });
};

export function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  return breadcrumbs;
}
