import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { CustomTasksQuery, CustomTasksRes, GET_CUSTOM_TASKS } from '@/logicLayers/domain';

import { EMPTY_CELL_VALUE, Flex } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

const columnHelper = createColumnHelper<CustomTasksRes>();

export const StatusesTypeTyped = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  OVERDUE: 'OVERDUE'
};

export const StatusesTypesList = [
  {
    label: 'Pending',
    value: StatusesTypeTyped.PENDING,
    checked: false,
    disabled: false
  },
  {
    label: 'In progress',
    value: StatusesTypeTyped.IN_PROGRESS,
    checked: false,
    disabled: false
  },
  {
    label: 'Completed',
    value: StatusesTypeTyped.COMPLETED,
    checked: false,
    disabled: false
  },
  {
    label: 'Cancelled',
    value: StatusesTypeTyped.CANCELLED,
    checked: false,
    disabled: false
  },
  {
    label: 'Overdue',
    value: StatusesTypeTyped.OVERDUE,
    checked: false,
    disabled: false
  }
];

export const getColumns = (selectedItems: string[], handleSelectAll: () => void, dataLength: number) => [
  columnHelper.accessor('checked', {
    header: () => (
      <Checkbox
        onClick={handleSelectAll}
        checked={selectedItems.length === dataLength}
        className="m-0"
        labelPlacement="end"
        label=""
      />
    ),
    cell: ({ row }) => {
      return (
        <Checkbox
          className="m-0"
          checked={selectedItems.includes(row.original.id ?? '')}
          labelPlacement="end"
          label=""
        />
      );
    }
  }),
  columnHelper.accessor('name', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return row.original?.name ? (
        <Text
          m={0}
          sx={{
            height: '40px',
            maxWidth: '264px',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
          }}
          className={'m-0'}
          variant={'titleSm'}
        >
          {row.original?.name}
        </Text>
      ) : (
        EMPTY_CELL_VALUE
      );
    }
  }),
  columnHelper.accessor('description', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return row.original?.description ? (
        <Text
          m={0}
          sx={{
            height: '40px',
            maxWidth: '264px',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
          }}
          className={'m-0'}
          variant={'bodyMd'}
        >
          {row.original?.description}
        </Text>
      ) : (
        EMPTY_CELL_VALUE
      );
    }
  }),
  columnHelper.accessor('performer', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Flex>
          <Box sx={{ marginRight: '8px' }}>
            <Flex>
              {row.original.performer?.userAvatar?.url ? (
                <img
                  width={24}
                  height={24}
                  src={row.original.performer?.userAvatar?.url}
                  style={{
                    borderRadius: '16px',
                    border: '1px solid #F7F8F9',
                    position: 'relative'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    flex: '0 0 24px',
                    borderRadius: '50%',
                    background: 'rgb(240, 240, 241)',
                    border: '1px solid #F7F8F9'
                  }}
                />
              )}
            </Flex>
          </Box>
          <Flex
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              margin: '4px 0 0 0 !important'
            }}
          >
            <Text sx={{ margin: '4px 0 0 0 !important' }} component={'span'} variant={'bodyMd'}>
              {row.original.performer?.id === row.original.profileId
                ? i18n.t('tasksCustom.table.selfPerformance', { ns: 'tasks' })
                : row.original.performer?.firstName.length && row.original.performer?.lastName.length
                  ? `${row.original.performer?.firstName} ${row.original.performer?.lastName}`
                  : EMPTY_CELL_VALUE}
            </Text>
          </Flex>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('status', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return <TaskStatusChip status={row.original.status} />;
    }
  }),
  columnHelper.accessor('startDate', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.startDate ? dayjs(row.original.startDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  }),
  columnHelper.accessor('endDate', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`tasksCustom.table.${props.column.id}`, { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.endDate ? dayjs(row.original.endDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDate: [],
    sortByStatus: [],
    sortByRound: [],
    filterByServiceStuff: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<CustomTasksQuery>(GET_CUSTOM_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined
        }
      },
      statuses: state.filters.sortByStatus.length ? state.filters.sortByStatus : undefined,
      performerIds: state.filters.sortByRound.length ? state.filters.sortByRound : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options
        }
      };
    });
  }, []);

  const handleChangeByServiceStuff = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByServiceStuff: options
        }
      };
    });
  }, []);

  const handleChangeByRound = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRound: options
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleSelectAll = useCallback((items: string[]) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: items
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByStatus,
    handleResetSelectedRows,
    handleChangeByRound,
    handleSelectAll,
    handleChangeByServiceStuff,
    state,
    data,
    loading,
    renderCount: data?.tasks?.total || 0,
    renderItems: data?.tasks?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
