import { gql as gqlApollo } from '@apollo/client';

export const CREATE_WIDGET = gqlApollo(`
  mutation CreateWidget($request: CreateDashboardReqInput!) {
    createDashboard(request: $request) {
      id
      name
    }
  }
`);

export const GET_DASHBOARDS = gqlApollo`
  query GetDashboards($searchCriteria: SearchCriteriaInput) {
    dashboards(searchCriteria: $searchCriteria) {
      items {
        id
        name
        widgets {
          dashboardId
          id
          resourceIds
          type
        }
      }
      total
    }
  }
`;

export const GET_USERS_WITH_DASHBOARD_ACCESS = gqlApollo(`
  query UsersWithDashboardAccess($id: String!) {
    dataItems: usersWithDashboardAccess(id: $id) {
      user {
        id
        firstName
        lastName
        userAvatar {
          url
        }
      }
      accessType
      userId
    }
  }
`);

export const EDIT_DASHBOARD_ACCESS = gqlApollo(`
  mutation EditDashboardAccess($request: ShareDashboardReqInput!) {
    editDashboardAccess(request: $request)
  }
`);
