import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SxProps, Theme } from '@mui/material/styles';

import { StyledBreadcrumbs } from '@/separatedModules/components/Breadcrumbs/style';

import { LinksBreadcrumbsT } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

interface BreadcrumbsPropsI {
  className?: string;
  links: LinksBreadcrumbsT[];
  separator?: React.ReactNode;
  sx?: SxProps<Theme>;
  onClick?: () => void;
  fontSize?: string;
  sxLinks?: SxProps<Theme>;
  separatorStyle?: CSSProperties;
}

export function Breadcrumbs({
  className,
  links,
  separator = '/',
  sx,
  onClick,
  fontSize,
  separatorStyle
}: BreadcrumbsPropsI) {
  const { t } = useTranslation('global');

  const handleClick = () => {
    onClick?.();
  };

  return (
    <StyledBreadcrumbs
      className={classNames('breadcrumbs', className)}
      role={'presentation'}
      aria-label={'breadcrumb'}
      sx={sx}
      fontSize={fontSize}
      onClick={handleClick}
    >
      {links.length
        ? links.map(({ link = '', title, titleFallback }, index) => {
            const localeTitle = title ? t(`header.menu.${title}`) : titleFallback;
            const isLastItem = index === links.length - 1;
            const isFirstItem = index === 0;

            return (
              <div key={`${link}-${index}`} className={'breadcrumbs__item'}>
                {isFirstItem ? '' : <span style={separatorStyle}>{separator}</span>}

                {isLastItem ? <p>{localeTitle}</p> : <Link to={link}>{localeTitle}</Link>}
              </div>
            );
          })
        : null}
    </StyledBreadcrumbs>
  );
}
