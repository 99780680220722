import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { useContextState } from '@/separatedModules/pages/Locations/components/Providers';

import { Flex } from '@/separatedModules/components';

export const Step3 = () => {
  const { progress, error } = useContextState();

  return (
    <Box>
      <Text
        sx={{
          fontSize: '12px',
          fontWeight: 400
        }}
      >
        {error
          ? 'Reporting this problem will help us understand it better. You can wait a bit and try again, or restart your page. That may help.'
          : 'You are going to export the QR Code in PNG format. The export process may take some time.'}
      </Text>
      {!error && (
        <Flex alignItems="center" marginTop="27px" marginBottom="35px">
          <Box
            sx={{
              height: '4px',
              overflow: 'hidden',
              borderRadius: 'var(--8, 8px)',
              background: 'var(--background-neutral-subtle-hovered, rgba(9, 14, 22, 0.06))',

              width: '100%'
            }}
          >
            <Box sx={{ height: '100%', width: `${progress}%`, background: '#53ACFE' }} />
          </Box>
          <Text
            sx={{
              fontSize: '14px',
              color: '#505864',
              fontWeight: 500,
              marginLeft: '4px'
            }}
          >{`${progress}%`}</Text>
        </Flex>
      )}
    </Box>
  );
};
