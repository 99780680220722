import { MouseEvent, useState } from 'react';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { EquipmentParameterRes, GET_TASKS, SWITCH_ROUND_ACTIVE_STATE } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AlertCheckNoticeIcon,
  DoNotDisturbOnIcon,
  ExpandLessIcon,
  ExpandMoreIcon
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

interface ActionMenuProps {
  data: EquipmentParameterRes | undefined;
}

export const ActionMenu = ({ data }: ActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchRoundActiveState] = useMutation<any>(SWITCH_ROUND_ACTIVE_STATE, {
    refetchQueries: [GET_TASKS]
  });

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeItemActiveState = () => {
    const isActive = data?.active;

    SwitchRoundActiveState({
      variables: {
        request: {
          ids: [data?.id],
          active: !isActive
        }
      }
    });

    AlertService.showAlert({
      title: !isActive
        ? tGlobal('alertMessages.success.templates.round.roundEdit.isEnabled')
        : tGlobal('alertMessages.success.templates.round.roundEdit.isDisabled'),
      severity: 'success',
      autoHideDuration: 1500
    });
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box component={'td'} onClick={handleClickBox}>
      <IconButton size={'small'} onClick={handleClickButton}>
        {anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {!data?.active ? (
          <MenuItem onClick={handleChangeItemActiveState}>
            <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

            {tGlobal('statuses.enable')}
          </MenuItem>
        ) : null}

        {data?.active ? (
          <MenuItem onClick={handleChangeItemActiveState}>
            <DoNotDisturbOnIcon />

            {tGlobal('statuses.disable')}
          </MenuItem>
        ) : null}
      </Menu>
    </Box>
  );
};
