export enum NotificationType {
  RoundCreated = 'ROUND_CREATED',
  RoundAssigned = 'ROUND_ASSIGNED',
  RoundCompletedSuccessfully = 'ROUND_COMPLETED_SUCCESSFULLY',
  RoundCompletedNoValidation = 'ROUND_COMPLETED_NO_VALIDATION',
  RoundOnHoldApproved = 'ROUND_ON_HOLD_APPROVED',
  RoundOnHoldRequested = 'ROUND_ON_HOLD_REQUESTED',
  RoundOnHoldDeclined = 'ROUND_ON_HOLD_DECLINED',
  RoundOverdue = 'ROUND_OVERDUE',
  RoundOverdueApproaching = 'ROUND_OVERDUE_APPROACHING',
  RoundServiceStaffNotAssigned = 'ROUND_SERVICE_STAFF_NOT_ASSIGNED',
  Error = 'ERROR',
  ROUND_COMPLETED_UNSUCCESSFULLY = 'ROUND_COMPLETED_UNSUCCESSFULLY',
  EQUIPMENT_DISABLED = 'EQUIPMENT_DISABLED'
}

export const ErrorNotificationTypes = [
  NotificationType.Error,
  NotificationType.RoundOverdue,
  NotificationType.ROUND_COMPLETED_UNSUCCESSFULLY,
  NotificationType.EQUIPMENT_DISABLED
];

export const WarningNotificationTypes = [
  NotificationType.RoundOverdueApproaching,
  NotificationType.RoundOnHoldDeclined,
  NotificationType.RoundServiceStaffNotAssigned
];

export const SuccessNotificationTypes = [
  NotificationType.RoundOnHoldApproved,
  NotificationType.RoundCompletedSuccessfully,
  NotificationType.RoundCompletedNoValidation
];

export const InfoNotificationTypes = [
  NotificationType.RoundAssigned,
  NotificationType.RoundCreated,
  NotificationType.RoundOnHoldRequested
];

export interface Notification {
  content?: {
    content: string;
    title: string;
  };
  id: string;
  type: string;
  __typename?: string;
  read?: boolean;
  createdDate: Date;
}
