import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';
import QRCodeStyling from 'qr-code-styling';

import { Box } from '@mui/material';

import { EMPTY_CELL_VALUE, Flex } from '@/separatedModules/components';
import { DownloadIcon } from '@/separatedModules/components/Icon/svg-components/Download';
import { QrIcon } from '@/separatedModules/components/Icon/svg-components/Qr';

import { useContextState, useContextUpdateState } from '../Providers';

import { styles } from './styles';

import { generateImageFromSvg, generateSvgString, qrCode } from '../Providers/helpers';

interface PopoverLinkProps {
  id: string;
  systemName?: string;
}

export const PopoverLink = ({ id, systemName }: PopoverLinkProps) => {
  const [open, setOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const qrRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const state = useContextState();
  const { handleClickRow } = useContextUpdateState();
  const [currentQrCode, setCurrentQrCode] = useState<QRCodeStyling | null>(null);

  const handlePopoverOpen = async () => {
    setOpen(true);

    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      setPopoverPosition({ top: rect.top + 18, left: rect.left });
    }

    const svgString = generateSvgString(systemName || EMPTY_CELL_VALUE);
    const imageData = await generateImageFromSvg(svgString);

    if (imageData && qrRef.current) {
      qrCode.update({
        data: `/locations/equipment/${id}`,
        image: imageData,
        width: 180,
        height: 180,
        backgroundOptions: {
          color: 'transparent'
        },
        dotsOptions: {
          type: 'square'
        }
      });

      setCurrentQrCode(qrCode);
    }
  };

  const handlePopoverClose = () => {
    setOpen(false);
    if (qrRef.current) {
      qrRef.current.innerHTML = '';
    }
  };

  useEffect(() => {
    if (open && currentQrCode && qrRef.current) {
      currentQrCode.append(qrRef.current);
    }

    return () => {
      if (qrRef.current) {
        qrRef.current.innerHTML = '';
      }
    };
  }, [open, currentQrCode]);

  return (
    <div
      ref={containerRef}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      style={{ display: 'inline-block', position: 'relative' }}
    >
      <Flex alignItems="center">
        <Link
          className="m-0"
          to={`/locations/equipment/${id}`}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-all',
            textDecoration: 'underline',
            marginRight: '2px'
          }}
        >
          {systemName || EMPTY_CELL_VALUE}
        </Link>
        <QrIcon />
      </Flex>

      <style>{styles}</style>

      {open && (
        <Box
          sx={{
            position: 'fixed',
            top: `${popoverPosition.top}px`,
            left: `${popoverPosition.left}px`,
            zIndex: 1,
            width: '220px',
            background: 'white',
            borderRadius: '4px',
            padding: '16px',
            boxShadow:
              '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.04), 0px 3px 6px -4px rgba(0, 0, 0, 0.01)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transition: 'opacity 0.3s ease',
            opacity: open ? 1 : 0,
            cursor: 'auto',
            pointerEvents: open ? 'auto' : 'none'
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Flex justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Text sx={{ fontSize: '14px', fontWeight: 600 }}>{systemName}</Text>
              <DownloadIcon
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  if (!state.selectedItems.includes(id)) {
                    handleClickRow(id);
                  }

                  state.setState((prevState: any) => ({
                    ...prevState,
                    isOpenQrCodeModal: !prevState.isOpenQrCodeModal
                  }));
                }}
              />
            </Flex>
            <Box
              sx={{
                marginTop: '16px',
                background: '#EFF1F4',
                borderRadius: '4px',
                width: '180px',
                height: '180px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  background: 'white',
                  width: 165,
                  height: 165,
                  paddingTop: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div ref={qrRef} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};
