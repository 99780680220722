import { Dialog } from 'infinitytechnologies-ui';

import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(({ isFirstStep = true }) => ({
  '.MuiPaper-root': {
    maxWidth: isFirstStep ? '600px' : '488px'
  },
  '.MuiModal-box': {
    maxWidth: isFirstStep ? '600px !important' : '488px !important'
  },
  '.MuiModal-content': {
    paddingTop: '24px !important',
    paddingBottom: '0 !important',
    maxHeight: '303px'
  }
}));
