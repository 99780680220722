import { WidgetType } from '@/logicLayers/domain';

import { i18n } from '@/i18n/config';

export const WIDGETS_LIST: any[] = [
  {
    id: 0,
    title: i18n.t('createWidgets.step2.resultOfCompletedTasks.title', { ns: 'widgets' }),
    description: i18n.t('createWidgets.step2.resultOfCompletedTasks.description', { ns: 'widgets' }),
    widgetType: WidgetType.RESULT_OF_COMPLETED_ROUNDS
  },
  {
    id: 1,
    title: i18n.t('createWidgets.step2.tasksByEmployee.title', { ns: 'widgets' }),
    description: i18n.t('createWidgets.step2.tasksByEmployee.description', { ns: 'widgets' }),
    widgetType: WidgetType.ROUND_BY_SERVICE_STAFF
  },
  {
    id: 2,
    title: i18n.t('createWidgets.step2.equipmentInCriticalCondition.title', { ns: 'widgets' }),
    description: i18n.t('createWidgets.step2.equipmentInCriticalCondition.description', { ns: 'widgets' }),
    widgetType: WidgetType.EQUIPMENT_IN_CRITICAL_CONDITIONS
  },
  {
    id: 3,
    title: i18n.t('createWidgets.step2.calendarOfRR.title', { ns: 'widgets' }),
    description: i18n.t('createWidgets.step2.calendarOfRR.description', { ns: 'widgets' }),
    widgetType: WidgetType.CALENDAR_OF_ROUND_REGULATIONS
  }
];
