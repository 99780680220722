import { MouseEvent, useState } from 'react';

import IconButton from '@mui/material/IconButton';

import { DotIcon, NotificationsFillIcon } from '@/separatedModules/components';

import { NotificationsPopover } from '../../../../../Header/components/RightPart/components/NotificationsPopover/NotificationsPopover';
import { RightItemAvatar } from '../RightItemAvatar';

export const RightItem = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isNewNotification, setIsNewNotification] = useState(false);

  const handleOpenPopover = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isOpenPopover = Boolean(anchorEl);
  const idPopover = isOpenPopover ? 'notifications-popover' : undefined;

  return (
    <div className={'header__right__i'}>
      <div className={'header__right__i__line'}></div>

      {/* ToDo Add aria-label */}
      <IconButton sx={{ color: '#3C4656', position: 'relative' }} aria-label={''} onClick={handleOpenPopover}>
        <NotificationsFillIcon />
        {isNewNotification && <DotIcon fill="#2D96FF" sx={{ position: 'absolute', top: '0px', right: '0px' }} />}
      </IconButton>

      {/* ToDo Add aria-label */}
      {/* <IconButton sx={{ color: '#DCDFE4' }} aria-label={''}>
        <DescriptionIcon />
      </IconButton> */}

      <RightItemAvatar />

      <NotificationsPopover
        idPopover={idPopover}
        anchorEl={anchorEl}
        isOpenPopover={isOpenPopover}
        handleClosePopover={handleClosePopover}
        setIsNewNotification={setIsNewNotification}
      />
    </div>
  );
};
