import { EquipmentQuery, updateEquipmentItemModel, updateEquipmentParameterItemModel } from '@/logicLayers/domain';

import { TranslationT } from '@/subsidiaryBinders/types';

export interface GetEditComponentProps extends EquipmentQuery {
  navigateToUrl: string;
  editType: string;
  tGlobal: TranslationT;
  tModel: TranslationT;
  templateId?: string;
  isEquipmentTemplate?: boolean;
  dataItems?: {
    active: boolean;
    archived: boolean;
    createdBy: string;
    createdDate: string;
    description: string;
    equipmentId: string;
    id: string;
    lastModifiedDate: string;
    order: number;
    propertyId: string;
    title: string;
    type: string;
    unitId: string;
    value: string;
    unit: { name: string };
    responsible: any;
  };
}

export const onSubmitUpdateName = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { name } = getValues();

    updateModel({
      variables: { id: data.equipment.id, equipment: { ...updateEquipmentItemModel(data, { name }) } }
    });
  };
};

export const onSubmitUpdateTemplateDescription = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { templateDescription } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { templateDescription }) }
      }
    });
  };
};

export const onSubmitUpdateItemDescription = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { itemDescription } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { itemDescription }) }
      }
    });
  };
};

export const onSubmitUpdateModelNumber = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { modelNumber } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { modelNumber }) }
      }
    });
  };
};

export const onSubmitUpdateManufacturer = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { manufacturer } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { manufacturer }) }
      }
    });
  };
};

export const onSubmitUpdateDomainId = (data: EquipmentQuery): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { domainId } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { domainId: domainId[0].value }) }
      }
    });
  };
};

export const onSubmitUpdateParameterTitle = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { title } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { title }) }
      }
    });
  };
};

export const onSubmitUpdateParameterNumericValue = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { value } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { value: value.replace(/[,]/g, '.') }) }
      }
    });
  };
};

export const onSubmitUpdateParameterDescription = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { description } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { description }) }
      }
    });
  };
};

export const onSubmitUpdateContact = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { emergencyContact } = getValues();

    updateModel({
      variables: {
        id: data.equipment.id,
        equipment: { ...updateEquipmentItemModel(data, { responsible: emergencyContact[0] || null }) }
      }
    });
  };
};

export const onSubmitUpdateParameterUnit = (data: any): ((...params: any[]) => void) => {
  return (getValues, updateModel) => {
    const { unitId } = getValues();

    updateModel({
      variables: {
        parameterId: data.id,
        editRequest: { ...updateEquipmentParameterItemModel(data, { unitId: unitId[0].value }) }
      }
    });
  };
};
