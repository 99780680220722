import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function QrCodeDownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M20.5 4.404L20.5 10.0962C20.5 10.3522 20.4133 10.5669 20.24 10.7402C20.0668 10.9134 19.8522 11 19.596 11H13.9037C13.6477 11 13.4331 10.9134 13.2598 10.7402C13.0866 10.5669 13 10.3522 13 10.0962L13 4.404C13 4.14783 13.0866 3.93317 13.2598 3.76C13.4331 3.58667 13.6477 3.5 13.9037 3.5H19.596C19.8522 3.5 20.0668 3.58667 20.24 3.76C20.4133 3.93317 20.5 4.14783 20.5 4.404ZM19 5H14.5V9.5H19L19 5ZM11 13.9038V19.596C11 19.8522 10.9134 20.0668 10.7402 20.24C10.5669 20.4133 10.3522 20.5 10.0962 20.5L4.404 20.5C4.14783 20.5 3.93317 20.4133 3.76 20.24C3.58667 20.0668 3.5 19.8522 3.5 19.596V13.9038C3.5 13.6478 3.58667 13.4331 3.76 13.2598C3.93317 13.0866 4.14783 13 4.404 13L10.0962 13C10.3522 13 10.5669 13.0866 10.7402 13.2598C10.9134 13.4331 11 13.6478 11 13.9038ZM9.5 14.5H5V19L9.5 19V14.5ZM5.375 3.5V5.375H3.5V3.5H5.375ZM11 9.125V11H9.125V9.125H11ZM9.125 7.25V9.125H7.25V7.25H9.125ZM11 5.375V7.25H9.125V5.375H11ZM9.125 3.5V5.375H7.25V3.5H9.125ZM7.25 5.375V7.25H5.375V5.375H7.25ZM7.25 9.125V11H5.375V9.125H7.25ZM5.375 7.25V9.125H3.5V7.25H5.375Z"
          fill="#313849"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1501 13.4411L19.1468 18.0972L18.0855 19.157L13.4294 19.1538C13.21 19.1537 13.0327 19.2247 12.8976 19.3669C12.7624 19.5092 12.6911 19.6863 12.6837 19.8985C12.6835 20.1178 12.755 20.3005 12.8982 20.4466C13.0413 20.5927 13.218 20.6657 13.4283 20.6659L19.7624 20.6703C20.018 20.6705 20.2311 20.5854 20.4017 20.4151C20.5722 20.2448 20.6576 20.0318 20.6577 19.7762L20.6622 13.4422C20.6623 13.2228 20.5895 13.0437 20.4436 12.9049C20.2978 12.7661 20.1151 12.6966 19.8958 12.6965C19.6836 12.7036 19.5064 12.7769 19.3639 12.9164C19.2215 13.0559 19.1502 13.2308 19.1501 13.4411Z"
          fill="#3C4656"
        />
        <path
          d="M18.0855 19.157L19.1468 18.0972L13.5011 12.9565C13.2206 12.7011 12.789 12.7112 12.5208 12.9794C12.2363 13.2639 12.2448 13.7278 12.5395 14.0017L18.0855 19.157Z"
          fill="#3C4656"
        />
      </svg>
    </SvgIcon>
  );
}
