import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { ReduxState } from '@/logicLayers/infrastructure/redux';

import { LogoIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { CompanyLogoName } from '../../../../../Base/components/Header/components/LeftPart/components/CompanyLogoName/CompanyLogoName';

export const LeftPart = () => {
  const company = useSelector((state: ReduxState) => state.user.company);

  return (
    <Grid className={'header__left'} lg={5} item>
      <Link className={'d-inline-block'} to={LINKS_PAGES.home}>
        {company?.name.toLowerCase() === 'cyber bms' ||
        company?.name.toLowerCase() === 'cyber-bms' ||
        company?.name.toLowerCase() === 'cyber-bms-test' ? (
          <LogoIcon sx={{ color: 'var(--white)', width: '50px', height: '40px' }} />
        ) : (
          <CompanyLogoName />
        )}
      </Link>
    </Grid>
  );
};
