import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import {
  CREATE_COMPANY,
  CREATE_USER_FOR_COMPANY,
  CreateCompanyMutation,
  CreateUserMutation,
  GET_ROLE_TEMPLATES,
  GetRoleTemplatesQuery,
  SEARCH_DOMAINS,
  SearchDomainsQuery
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import {
  AutocompleteControl,
  AutocompleteOptionT,
  ButtonLoading,
  ChipIcon,
  ImageUploader,
  ModalDiscardAllChanges,
  Preloader,
  PreloaderVariantsE,
  SelectControl,
  TextBlockControl,
  TextFieldControl
} from '@/separatedModules/components';

import { COMPANY_NAME_REG_EXP, EMAIL_REG_EXP, useSuperAdmin } from '@/utils';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { StyledBlankFlow } from './style';

interface RoleTemplate {
  value: string;
  label: string;
  key: string;
}

const FIELD_COMPANY_NAME = 'companyName';
const FIELD_COMPANY_ROLE = 'companyRole';
const FIELD_COMPANY_USER = 'companyUser';
const FIELD_DOMAIN_IDS = 'companyDomains';
const FIELD_ROLES = 'roleTemplates';

export const BlankFlow = () => {
  const { t: tCompany } = useTranslation('company');
  const { t: tGlobal } = useTranslation('global');
  const { t: tErrors } = useTranslation('errors');

  const { folderId } = useParams<{ folderId?: string }>();
  const navigateTo = useNavigate();
  const { superAdminTemplateId } = useSuperAdmin();

  const [uploadedImageKey, setUploadedImageKey] = useState('');

  const [CreateCompany, { loading: loadingCreateCompany, data: dataCreateCompany }] =
    useMutation<CreateCompanyMutation>(CREATE_COMPANY);
  const [CreateUser, { loading: loadingCreateUser, data: dataCreateUser }] =
    useMutation<CreateUserMutation>(CREATE_USER_FOR_COMPANY);
  const { data: dataRoleTemplates, loading: loadingRoleTemplates } = useQuery<GetRoleTemplatesQuery>(
    GET_ROLE_TEMPLATES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        searchCriteria: {
          pageable: {
            pageSize: 1000
          }
        }
      }
    }
  );

  const { data: domainsData } = useQuery<SearchDomainsQuery>(SEARCH_DOMAINS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      }
    }
  });

  const createdCompany = useRef<{ id: null | undefined | string }>({ id: '' });

  const [createdUser, setCreatedUser] = useState<any[]>([]);

  const {
    setError,
    getValues,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [`${FIELD_COMPANY_NAME}`]: '',
      [`${FIELD_COMPANY_ROLE}`]: '',
      [`${FIELD_COMPANY_USER}`]: '',
      [`${FIELD_DOMAIN_IDS}`]: [],
      [`${FIELD_ROLES}`]: []
    }
  });

  const handleCreateUserForCompany = (id: string, userEmail: string, companyRole: string, domainIdsArr: string[]) => {
    if (!userEmail.length) return navigateTo(LINKS_PAGES.companies);

    CreateUser({
      variables: {
        companyUid: id,
        user: { email: userEmail, roleTemplateId: companyRole, domainIds: domainIdsArr, useTemplate: true }
      },
      onCompleted: ({ createUser }) => {
        setCreatedUser((state) => [...state, createUser]);
      },
      onError: (error) => {
        const errorMessage = JSON.parse(error.message);

        if (errorMessage?.errorCode) {
          setError(FIELD_COMPANY_USER, {
            message: tErrors(`company.create.${errorMessage.errorCode.replace('error.', '')}`)
          });
        } else {
          AlertService.showAlert({
            title: tGlobal('alertMessages.errors.base'),
            severity: 'error',
            autoHideDuration: 1500
          });
        }
      }
    });
  };

  const handleSubmitForm = handleSubmit(() => {
    const { companyName, companyUser: companyUserEmail, companyRole, companyDomains, roleTemplates } = getValues();

    const userEmail = companyUserEmail;
    const domainIdsArr: string[] = companyDomains.map((item: AutocompleteOptionT) => item.value as string);
    const roleTemplatesArr: string[] = roleTemplates.map((item: AutocompleteOptionT) => item.value as string);

    if (!createdCompany.current.id) {
      CreateCompany({
        variables: {
          name: companyName,
          fileKey: uploadedImageKey,
          domainIds: domainIdsArr,
          roleTemplateIds: roleTemplatesArr,
          regionId: (folderId === 'root' ? undefined : folderId) ?? undefined
        },
        onCompleted: ({ createCompany }) => {
          const { id } = createCompany;

          createdCompany.current.id = id;

          if (!id) return;

          AlertService.showAlert({
            title: tGlobal('alertMessages.success.companyCreated'),
            severity: 'success',
            autoHideDuration: 1500
          });

          setTimeout(() => {
            if (roleTemplatesArr.length) {
              handleCreateUserForCompany(id, userEmail, companyRole, domainIdsArr);
            } else {
              navigateTo(LINKS_PAGES.companies);
            }
          }, 1600);
        },
        onError: (error) => {
          // ToDo Handle Error
          // ToDo Remove
          console.log('CreateCompany error ', error);
        }
      });
    } else {
      handleCreateUserForCompany(createdCompany.current.id, userEmail, companyRole, domainIdsArr);
    }
  });

  useEffect(() => {
    if (createdUser.length) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.userCreated'),
        severity: 'success',
        autoHideDuration: 1500
      });

      setTimeout(() => {
        navigateTo(LINKS_PAGES.companies);
      }, 1600);
    }
  }, [createdUser]);

  const domainsOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!domainsData) {
      return [];
    }

    return domainsData?.dataItems.items.map((domain) => ({
      label: domain?.name as string,
      value: domain?.id as string,
      key: domain?.id as string
    }));
  }, [domainsData]);

  const rolesOptions: AutocompleteOptionT[] = useMemo(() => {
    if (!dataRoleTemplates) {
      return [];
    }

    const filterdRoleTemplates = dataRoleTemplates?.dataItems.items.filter((i) => {
      return i?.id !== superAdminTemplateId;
    });

    return filterdRoleTemplates.map((role) => ({
      label: role?.name as string,
      value: role?.id as string,
      key: role?.id as string
    }));
  }, [dataRoleTemplates]);

  const roleTemplates: RoleTemplate[] = watch(FIELD_ROLES);
  const isBtnSubmitDisabled = Boolean(dataCreateCompany) && Boolean(dataCreateUser);
  const isBtnSubmitLoading = loadingCreateCompany || loadingCreateUser;
  const companyUser = watch(FIELD_COMPANY_USER);

  return (
    <StyledBlankFlow>
      <form onSubmit={handleSubmitForm}>
        {loadingRoleTemplates ? (
          <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '500px' }} isContainer />
        ) : (
          <Grid justifyContent={'space-between'} container row>
            <Grid xs={12} sm={10} md={8} lg={5} sx={{ margin: '0 auto' }} item>
              <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.home} />

              <TextBlockControl
                title={tCompany('create.choseFlow.title')}
                subTitle={tCompany('create.blank.subTitle')}
                isTitleLg
                isBodyLg
              />

              <Controller
                name={FIELD_COMPANY_NAME}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  },
                  pattern: {
                    value: COMPANY_NAME_REG_EXP,
                    message: tGlobal('validation.textFieldLength')
                  },
                  minLength: {
                    value: 3,
                    message: tGlobal('validation.textFieldLength')
                  },
                  maxLength: {
                    value: 64,
                    message: tGlobal('validation.textFieldLength')
                  }
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      label={tCompany('create.blank.companyNameLabel')}
                      name={FIELD_COMPANY_NAME}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_COMPANY_NAME]?.message,
                        error: errors[FIELD_COMPANY_NAME]?.message
                      }}
                      disabled={Boolean(createdCompany.current.id)}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_DOMAIN_IDS}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  }
                }}
                render={({ field: { onChange, onBlur } }) => {
                  return (
                    <AutocompleteControl
                      name={FIELD_DOMAIN_IDS}
                      title={tCompany('create.blank.domainsTitle')}
                      subTitle={tCompany('create.blank.domainsSubTitle')}
                      label={tCompany('create.blank.domainsLabel')}
                      placeholder={tCompany('create.blank.domainsPlaceholder')}
                      options={domainsOptions}
                      validation={{
                        isValid: !errors[FIELD_DOMAIN_IDS]?.message,
                        error: errors[FIELD_DOMAIN_IDS]?.message
                      }}
                      renderTags={(tagValue, getTagProps) => {
                        return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                          const { ...tagProps } = getTagProps({ index });

                          return <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />;
                        });
                      }}
                      multiple
                      disabled={Boolean(!domainsOptions.length)}
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Controller
                name={FIELD_ROLES}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: tGlobal('validation.required')
                  }
                }}
                render={({ field: { onChange, onBlur } }) => {
                  return (
                    <AutocompleteControl
                      name={FIELD_ROLES}
                      title={tCompany('create.blank.roleTitle')}
                      subTitle={tCompany('create.blank.roleSubTitle')}
                      label={tCompany('create.blank.roleLabel')}
                      placeholder={tCompany('create.blank.rolePlaceholder')}
                      validation={{
                        isValid: !errors[FIELD_ROLES]?.message,
                        error: errors[FIELD_ROLES]?.message
                      }}
                      options={rolesOptions}
                      renderTags={(tagValue, getTagProps) => {
                        return (tagValue as AutocompleteOptionT[]).map((option, index) => {
                          const { ...tagProps } = getTagProps({ index });

                          return <Chip {...tagProps} key={option.key} label={option.label} deleteIcon={<ChipIcon />} />;
                        });
                      }}
                      multiple
                      disabled={Boolean(!rolesOptions.length)}
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '32px 0px' }}>
                <TextBlockControl
                  className={'m-0'}
                  title={tCompany('settings.mainInfo.companyLogo')}
                  subTitle={tCompany('settings.mainInfo.companyLogoDescription')}
                />

                <ImageUploader
                  path={'COMPANY_LOGO'}
                  imgAlt={'Upload company logo'}
                  oldImageUrl={''}
                  oldImageFileKey={''}
                  modalTitle={tCompany('settings.modal.imageUploader.title')}
                  modalTitleToDelete={tCompany('settings.modal.imageUploader.titleToDelete')}
                  modalSubTitle={tCompany('settings.modal.imageUploader.subTitle')}
                  btnUploadPhotoText={tCompany('settings.modal.imageUploader.uploaderButtonContent')}
                  allowedExtensionsText={''}
                  minDimensionsText={'72px x 72px'}
                  maxDimensionsText={''}
                  maxFileSizeText={'5'}
                  imageMinWidth={72}
                  imageMinHeight={72}
                  imageMaxSize={5242880} // Bites
                  imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
                  imageRequired
                  withPreview
                  titleContent={
                    <Text
                      className={'m-0'}
                      variant={'bodyMd'}
                      sx={{ color: 'var(--text-subtle, #505668)', textAlign: 'center' }}
                    >
                      {tCompany('settings.modal.imageUploader.allowedExtensionsText')}{' '}
                      {tCompany('settings.modal.imageUploader.titleContent')}
                    </Text>
                  }
                  modalContent={
                    <Avatar
                      size={'136'}
                      sx={{ width: '180px', height: '180px', margin: '0 auto 24px', pointerEvents: 'none' }}
                    >
                      {'C L'}
                    </Avatar>
                  }
                  onImageUploadSuccess={(imageKey: string) => {
                    setUploadedImageKey(imageKey);
                  }}
                  onImageDelete={() => {}}
                  onImageUploadError={() => {
                    // ToDo Remove
                    console.log('ImageUploader handleSaveImage uploadedResult error');
                  }}
                />
              </Box>

              <Controller
                name={FIELD_COMPANY_USER}
                control={control}
                rules={{
                  pattern: {
                    value: EMAIL_REG_EXP,
                    message: tGlobal('validation.emailValid')
                  }
                }}
                render={({ field: { onBlur, value } }) => {
                  return (
                    <TextFieldControl
                      name={FIELD_COMPANY_USER}
                      title={tCompany('create.blank.inviteUserTitle')}
                      subTitle={tCompany('create.blank.inviteUserSubTitle')}
                      label={tCompany('create.blank.inviteUserLabel')}
                      placeholder={tCompany('create.blank.inviteUserPlaceholder')}
                      value={value}
                      validation={{
                        isValid: !errors[FIELD_COMPANY_USER]?.message,
                        error: errors[FIELD_COMPANY_USER]?.message
                      }}
                      onChange={(e: any) => {
                        setValue(FIELD_COMPANY_USER, e.target.value?.trim());
                      }}
                      onBlur={onBlur}
                    />
                  );
                }}
              />

              {dataRoleTemplates?.dataItems.items.length ? (
                <Controller
                  name={FIELD_COMPANY_ROLE}
                  control={control}
                  rules={{
                    required: companyUser.length ? tGlobal('validation.required') : false
                  }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <SelectControl
                        label={tCompany('create.blank.inviteUserAsTitle')}
                        value={value}
                        validation={{
                          isValid: !errors[FIELD_COMPANY_ROLE]?.message,
                          error: errors[FIELD_COMPANY_ROLE]?.message
                        }}
                        MenuProps={{
                          className: 'MuiMenu-root-Mui-select'
                        }}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {dataRoleTemplates?.dataItems.items
                          .filter((i) => {
                            // ToDo Refactor
                            // ToDo Hide SuperAdmin Role
                            return i?.id !== superAdminTemplateId && roleTemplates.some((item) => item.value === i?.id);
                          })
                          .map((item) => {
                            return item?.name ? (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item.name}
                              </MenuItem>
                            ) : null;
                          })}
                      </SelectControl>
                    );
                  }}
                />
              ) : null}

              <ButtonLoading
                className={'flow-blank__btn__create-company'}
                variant={'contained'}
                type={'submit'}
                color={'primary'}
                loading={isBtnSubmitLoading}
                disabled={isBtnSubmitDisabled}
              >
                {tCompany('create.blank.btn.createCompany')}
              </ButtonLoading>
            </Grid>

            <Grid lg={6} item sx={{ display: { xs: 'none', lg: 'block' } }}>
              {/* ToDo Add alt */}
              <img className={'img-fluid flow-blank__right__img'} src={LINKS_IMAGES.companyCreateFlowBlank} alt={''} />
            </Grid>
          </Grid>
        )}
      </form>
    </StyledBlankFlow>
  );
};
