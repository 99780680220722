import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text, TextField } from 'infinitytechnologies-ui';

import { EDIT_USER_PROFILE, GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { FIRST_NAME_REG_EXP, LAST_NAME_REG_EXP } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

const FIELD_FIRST_NAME = 'firstName';
const FIELD_LAST_NAME = 'lastName';

export const EditName = () => {
  const navigateTo = useNavigate();

  const { t: tProfile } = useTranslation('profile');
  const { t: tGlobal } = useTranslation('global');

  const [EditUserProfile, { data: editedUserData, error: errorEditUserProfile }] = useMutation(EDIT_USER_PROFILE);
  const { data: dataUserProfile, loading: loadingUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [`${FIELD_FIRST_NAME}`]: '',
      [`${FIELD_LAST_NAME}`]: ''
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    const { firstName, lastName } = getValues();

    EditUserProfile({
      variables: {
        firstName,
        lastName,
        phoneNumber: dataUserProfile?.profile.phoneNumber,
        fileKey: dataUserProfile?.profile.fileKey
      }
    });
  });

  useEffect(() => {
    if (editedUserData?.editProfile.id) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.profile.editName'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(LINKS_PAGES.profile);
      }, 2500);
    }

    if (errorEditUserProfile) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [editedUserData, errorEditUserProfile]);

  useEffect(() => {
    const userFirstName = dataUserProfile?.profile?.firstName;
    const userLastName = dataUserProfile?.profile?.lastName;

    if (userFirstName && userLastName) {
      setValue(FIELD_FIRST_NAME, userFirstName);
      setValue(FIELD_LAST_NAME, userLastName);
    }
  }, [dataUserProfile]);

  return (
    <form onSubmit={handleSubmitForm}>
      {loadingUserProfile ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
      ) : (
        <>
          <Text className={'edit-user__content__title'} variant={'titleLg'}>
            {tProfile('edit.name.title')}
          </Text>

          <Controller
            name={FIELD_FIRST_NAME}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.firstNameRequired')
              },
              pattern: {
                value: FIRST_NAME_REG_EXP,
                message: tGlobal('validation.firstNameLength')
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  label={tProfile('edit.name.inputFirstNameLabel')}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_FIRST_NAME]?.message,
                    error: errors[FIELD_FIRST_NAME]?.message
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          <Controller
            name={FIELD_LAST_NAME}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.lastNameRequired')
              },
              pattern: {
                value: LAST_NAME_REG_EXP,
                message: tGlobal('validation.lastNameLength')
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  label={tProfile('edit.name.inputLastNameLabel')}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_LAST_NAME]?.message,
                    error: errors[FIELD_LAST_NAME]?.message
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          <Button
            className={'edit-user__content__btn'}
            variant={'contained'}
            type={'submit'}
            disabled={Boolean(editedUserData?.editProfile.id)}
          >
            {tProfile('edit.name.btnChange')}
          </Button>
        </>
      )}
    </form>
  );
};
