import { FC, MouseEvent, ReactNode } from 'react';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { ChevronRightIcon, IconsContainer, MoreVertIcon } from '@/separatedModules/components';

import { RenderTree } from '../../helpers';

import { StyledTreeItem } from './style';

interface MuiTreeItemProps {
  nodes: RenderTree;
  rootItemIcon?: ReactNode;
  children: ReactNode;
  onNodeActionClick: (event: MouseEvent<HTMLElement>, nodes: RenderTree) => void;
}

export const MuiTreeItem: FC<MuiTreeItemProps> = ({ nodes, rootItemIcon, children, onNodeActionClick }) => {
  const isItemRoot = nodes.id === 'root';

  return (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      active={nodes.active}
      className={nodes.active ? '' : 'tree-item-disabled'}
      collapseIcon={
        <IconsContainer customIcon={isItemRoot ? rootItemIcon : null}>
          <ChevronRightIcon sx={{ transform: 'rotate(90deg)' }} />
        </IconsContainer>
      }
      expandIcon={
        <IconsContainer customIcon={isItemRoot ? rootItemIcon : null}>
          <ChevronRightIcon />
        </IconsContainer>
      }
      label={
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ whiteSpace: 'nowrap' }}>
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              textOverflow: 'ellipsis',
              wordBreak: 'break-all',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {nodes.name}
          </Text>

          {nodes.hasAction !== false ? (
            <IconButton
              className={'node_action_button'}
              sx={{ padding: '4px' }}
              onClick={(e) => onNodeActionClick(e, nodes)}
            >
              <MoreVertIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          ) : null}
        </Box>
      }
    >
      {children}
    </StyledTreeItem>
  );
};
