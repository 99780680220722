export const Project = {
  root: {
    '&:hover': {
      background: 'transparent',
      '.MuiSvgIcon-root': {
        color: '#326CC9'
      }
    },
    '&:active': {
      background: '#969AA8'
    },
    '.MuiSvgIcon-root': {
      color: '#326CC9'
    },
    '&.Mui-checked': {
      '.MuiSvgIcon-root': {
        color: '#326CC9'
      },
      '&:active': {
        background: '#090E160F'
      }
    },
    '&.Mui-disabled': {
      '.MuiSvgIcon-root': {
        color: '#090E164D'
      }
    },
    '&.MuiCheckbox-colorError, &.Mui-error': {
      '&:hover': {
        background: 'var(--extended-red-50)'
      },
      '&:active': {
        background: 'var(--extended-red-100)'
      },
      '.MuiSvgIcon-root': {
        color: 'var(--extended-red-700)'
      },
      '&.Mui-checked': {
        '.MuiSvgIcon-root': {
          color: '#BE0E2C'
        },
        '&:active': {
          background: 'var(--extended-red-100)'
        },
        ':hover': {
          background: '#FFEBF0'
        }
      }
    },
    '& + .MuiFormControlLabel-label': {
      color: 'var(--text-default, #090E16) !important',
      fontWeight: '400 !important'
    }
  },
  sizeSmall: {
    width: '32px',
    height: '32px',
    '.MuiSvgIcon-root': {
      fontSize: '18px !important',
      margin: '0 !important'
    },
    '& + .MuiFormControlLabel-label': {
      fontSize: '14px !important',
      lineHeight: '20px !important'
    }
  },
  sizeMedium: {}
};

export const Base = {
  root: {
    width: '40px',
    height: '40px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: 'var(--primary-25)',
      '.MuiSvgIcon-root': {
        color: 'var(--primary-800)'
      }
    },
    '&:active': {
      background: 'var(--primary-50)'
    },
    '.MuiSvgIcon-root': {
      transition: 'all 0.2s ease-in-out'
    },
    '&.Mui-checked': {
      '.MuiSvgIcon-root': {
        color: 'var(--primary-800)'
      },
      '&:active': {
        background: 'var(--primary-50)'
      }
    },
    '&.Mui-disabled': {
      '.MuiSvgIcon-root': {
        color: 'var(--default-gray-500)',
        opacity: '0.4'
      }
    },
    '&.MuiCheckbox-colorError, &.Mui-error': {
      '&:hover': {
        background: 'var(--extended-red-50)'
      },
      '&:active': {
        background: 'var(--extended-red-100)'
      },
      '.MuiSvgIcon-root': {
        color: 'var(--extended-red-700)'
      },
      '&.Mui-checked': {
        '.MuiSvgIcon-root': {
          color: 'var(--extended-red-700)'
        },
        '&:active': {
          background: 'var(--extended-red-100)'
        }
      }
    }
  }
};
