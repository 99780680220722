import { useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';

import {
  ArchiveIcon,
  DriveFileMoveIcon,
  EditIcon,
  StarOutlineIcon,
  TableManagement
} from '@/separatedModules/components';
import { QrCodeDownloadIcon } from '@/separatedModules/components/Icon/svg-components/QrCodeDownload';

import { useContextState, useContextUpdateState } from '../index';

export const ActionsContainer = () => {
  const state = useContextState();

  const { handleResetSelectedRows, handleToggleAside } = useContextUpdateState();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (state.selectedItems.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [state.selectedItems]);

  return (
    <Collapse in={checked}>
      <TableManagement
        count={state.selectedItems.length}
        actionNodeList={[
          {
            node: (
              <QrCodeDownloadIcon
                key={1}
                onClick={() => state.setState((state) => ({ ...state, isOpenQrCodeModal: !state.isOpenQrCodeModal }))}
              />
            ),
            disabled: false
          },
          { node: <StarOutlineIcon key={2} />, disabled: true },
          {
            node: <EditIcon key={3} />,
            disabled: true
          },
          {
            node: (
              <DriveFileMoveIcon
                onClick={() => state.setState((state) => ({ ...state, isOpenMoveModal: !state.isOpenMoveModal }))}
                key={4}
              />
            ),
            disabled: false
          },
          { node: <ArchiveIcon key={5} />, disabled: true }
        ]}
        open={checked}
        onClose={handleResetSelectedRows}
        onClickInfo={handleToggleAside}
      />
    </Collapse>
  );
};
