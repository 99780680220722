import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

import { EquipmentRes, WidgetType } from '@/logicLayers/domain';

interface StepsStateI {
  currentStep: number;
  totalSteps: number;
  selectedWidgetTypes: { [key: string]: boolean };
  selected?: EquipmentRes | undefined;
}

type ContextStateT = StepsStateI & {
  setState: Dispatch<SetStateAction<StepsStateI>>;
  handleSetPrevStep: () => void;
  handleSetWidgetType: (type: string) => void;
};

export const ContextState = createContext<ContextStateT>({
  currentStep: 1,
  totalSteps: 6,
  selectedWidgetTypes: {
    [WidgetType.RESULT_OF_COMPLETED_ROUNDS]: false,
    [WidgetType.ROUND_BY_SERVICE_STAFF]: false,
    [WidgetType.EQUIPMENT_IN_CRITICAL_CONDITIONS]: false,
    [WidgetType.CALENDAR_OF_ROUND_REGULATIONS]: false
  },
  setState: () => {},
  handleSetPrevStep: () => {},
  handleSetWidgetType: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<StepsStateI>({
    currentStep: 1,
    totalSteps: 6,
    selectedWidgetTypes: {
      [WidgetType.RESULT_OF_COMPLETED_ROUNDS]: false,
      [WidgetType.ROUND_BY_SERVICE_STAFF]: false,
      [WidgetType.EQUIPMENT_IN_CRITICAL_CONDITIONS]: false,
      [WidgetType.CALENDAR_OF_ROUND_REGULATIONS]: false
    }
  });

  const handleSetPrevStep = () => {
    setState((state: any) => ({ ...state, currentStep: state.currentStep - 1 }));
  };

  const handleSetWidgetType = (type: string) => {
    setState((state: any) => {
      const updatedWidgetTypes = { ...state.selectedWidgetTypes, [type]: !state.selectedWidgetTypes[type] };
      const selectedCount = Object.values(updatedWidgetTypes).filter((item) => item).length;

      return {
        ...state,
        selectedWidgetTypes: updatedWidgetTypes,
        totalSteps: selectedCount + 2 < 3 ? 3 : selectedCount + 2
      };
    });
  };

  const contextValue = useMemo(() => {
    return {
      ...state,
      setState,
      handleSetPrevStep,
      handleSetWidgetType
    };
  }, [state]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
