import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { GET_DATE_OF_TASKS } from '@/logicLayers/domain/tasks';
import { selectUserData } from '@/logicLayers/infrastructure/redux';

import { ChevronRightIcon, Preloader, PreloaderVariantsE } from '@/separatedModules/components';

import { useIsUserCanCRUD, useIsUserSuperAdmin } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { HeaderMobile } from './components/HeaderMobile';

import { FrequencyEnum } from './helpers';

export const HomePage = () => {
  const navigateTo = useNavigate();

  const isUserSuperAdmin = useIsUserSuperAdmin();
  const { profile } = useSelector(selectUserData);
  const navigate = useNavigate();
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_VIEWING']
  });

  const isShowLocations =
    profile.permissions?.some((permission) => permission?.name === 'ROLE_LOCATIONS_VIEWING') && isUserCanCRUD;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const { data: dataDateTasks, loading } = useQuery(GET_DATE_OF_TASKS, {
    variables: { date: dayjs().format('YYYY-MM-DD') }
  });

  if (!isUserCanCRUD && (isSmallScreen || isTablet)) {
    navigate(LINKS_PAGES.accessErrorPage);
  }

  return (
    <>
      {isSmallScreen || isTablet ? (
        <>
          <AppBar sx={{ boxShadow: 'none', borderBottom: 'none', backgroundColor: '#FFF' }} component="nav">
            <HeaderMobile />
          </AppBar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflowY: 'auto',
              padding: '90px 16px 96px 16px'
            }}
          >
            <Box sx={{ width: '100%', maxWidth: '744px' }}>
              {loading ? (
                <Preloader variant={PreloaderVariantsE.COMPANY_PLANET} isFullScreen isContainer />
              ) : (
                <Box>
                  {dataDateTasks.dataItems.map((date: keyof typeof FrequencyEnum, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        width: '100%',
                        height: '56px',
                        borderRadius: '4px',
                        border: '1px solid var(--background-disabled, rgba(41, 49, 62, 0.03))',
                        background: 'var(--background-neutral-hovered, #F5F7FA)',
                        padding: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '12px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Text sx={{ fontSize: '16px', fontWeight: '600', margin: 0 }}>{FrequencyEnum[date]}</Text>
                      <ChevronRightIcon
                        onClick={() => navigateTo(LINKS_PAGES.tasksMobile.replace(':frequency', date))}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Grid xs={12} md={10} lg={8} item>
          <Text component={'h2'}>Home Page</Text>
          <Navigate to={LINKS_PAGES[isUserSuperAdmin || !isShowLocations ? 'users' : 'locations']} />
        </Grid>
      )}
    </>
  );
};
