export const Project = {
  root: {
    minWidth: '100%',
    margin: '16px 0 0',
    '&.MuiTable-root__columns-2': {
      '.MuiTableCell-root, .MuiSkeleton-root__wrp': {
        minWidth: 'auto',
        flex: '0 1 50%',
        maxWidth: '50%'
      }
    },
    '&.MuiTable-root__columns-3': {
      '.MuiTableCell-root, .MuiSkeleton-root__wrp': {
        minWidth: 'auto',
        flex: '0 1 33.33%',
        maxWidth: '33.33%'
      }
    },
    '&.MuiTable-root__columns-4': {
      '.MuiTableCell-root, .MuiSkeleton-root__wrp': {
        minWidth: 'auto',
        flex: '0 1 25%',
        maxWidth: '25%'
      }
    },
    '&.MuiTable-root__columns-5': {
      '.MuiTableCell-root, .MuiSkeleton-root__wrp': {
        minWidth: 'auto',
        flex: '0 1 20%',
        maxWidth: '20%'
      }
    },
    '&.MuiTable-root__columns-custom-5': {
      '.MuiTableCell-root, .MuiSkeleton-root__wrp': {
        minWidth: 'auto',
        flex: '0 1 20%',
        maxWidth: '20%'
      }
    }
  }
};

export const Base = {};
