import { i18n } from '@/i18n';

export const createSortingBy = () => {
  return [
    {
      label: i18n.t('mobile.filterEarliest', { ns: 'tasks' }),
      key: i18n.t('mobile.filterEarliest', { ns: 'tasks' }),
      value: 'ASC'
    },
    {
      label: i18n.t('mobile.filterLatest', { ns: 'tasks' }),
      key: i18n.t('mobile.filterLatest', { ns: 'tasks' }),
      value: 'DESC'
    }
  ];
};
