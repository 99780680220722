import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import { GET_TASKS, REQUEST_ROUND_ON_HOLD } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { TextFieldControl } from '@/separatedModules/components';
import { ArrowBackIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

const FIELD_TASK_DESCRIPTION = 'taskDescription';

export const RequestOnHoldPage = () => {
  const { taskId = '' } = useParams();
  const { t: tTasks } = useTranslation('tasks');
  const { t: tGlobal } = useTranslation('global');

  const navigateTo = useNavigate();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [`${FIELD_TASK_DESCRIPTION}`]: ''
    }
  });

  const queryParams = new URLSearchParams(location.search);
  const taskName = queryParams.get('taskName');

  const [requestOnHold] = useMutation(REQUEST_ROUND_ON_HOLD, {
    refetchQueries: [GET_TASKS]
  });

  const onSubmit = (data: any) => {
    requestOnHold({
      variables: {
        roundId: taskId,
        reason: data[FIELD_TASK_DESCRIPTION]
      },
      onCompleted: () => {
        navigateTo('/');
        setTimeout(() => {
          AlertService.showAlert({
            title: tGlobal('alertMessages.success.taskOnHold'),
            severity: 'success'
          });
        }, 500);
      },
      onError: () => {
        AlertService.showAlert({
          title: tGlobal('alertMessages.errors.base'),
          severity: 'error'
        });
      }
    });
  };

  return (
    <Box sx={{ height: '100vh', position: 'relative' }}>
      <Box sx={{ flex: '1 1 auto', overflowY: 'auto', paddingBottom: '155px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100vw',
              justifyContent: 'flex-start',
              padding: '16px'
            }}
          >
            <IconButton onClick={() => navigateTo(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={{ marginLeft: '8px' }}>
              <Text component={'h2'} variant={'titleXl'} sx={{ fontSize: '18px', fontWeight: 600, margin: '0' }}>
                {tTasks('mobile.requestOnHold')}
              </Text>
              <Text sx={{ color: '#505864', fontSize: '12px' }}>{taskName}</Text>
            </Box>
          </Box>
          <Box sx={{ padding: '16px' }}>
            <ul>
              <li>
                <Text sx={{ fontSize: '16px' }}> {tTasks('mobile.onHoldDescription.part1')}</Text>
              </li>
              <li>
                <Text sx={{ fontSize: '16px' }}>{tTasks('mobile.onHoldDescription.part2')}</Text>
              </li>
              <li>
                <Text sx={{ fontSize: '16px' }}>{tTasks('mobile.onHoldDescription.part3')}</Text>
              </li>
            </ul>
            <Controller
              name={FIELD_TASK_DESCRIPTION}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: tGlobal('validation.required')
                },
                pattern: {
                  value: /^[\w\s`'.\-,#№()_]{3,400}$/,
                  message: tGlobal('validation.textFieldMinMaxLength', {
                    valueMin: 3,
                    valueMax: 400
                  })
                }
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldControl
                  label={tTasks('mobile.onHoldField.label')}
                  placeholder={tTasks('mobile.onHoldField.placeholder')}
                  name={FIELD_TASK_DESCRIPTION}
                  rows={5}
                  maxContentLength={400}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_TASK_DESCRIPTION]?.message,
                    error: errors[FIELD_TASK_DESCRIPTION]?.message as string
                  }}
                  multiline
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Box>
          {/* <Box
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '0px',
              left: '0px',
              padding: '16px 16px 58px 16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid rgba(41, 49, 62, 0.15)',
              background: '#FFFFFF'
            }}
          >
            <Button variant="text" size="large" sx={{ width: '100px' }} onClick={() => navigateTo(-1)}>
              {tTasks('mobile.cancelBtn')}
            </Button>
            <Button variant="contained" size="large" sx={{ width: '100px' }} type="submit" disabled={!isValid}>
              {tTasks('mobile.requestBtn')}
            </Button>
          </Box> */}
        </form>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          padding: '16px 16px 58px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '1px solid rgba(41, 49, 62, 0.15)',
          background: '#FFFFFF'
        }}
      >
        <Button variant="outlined" size="large" sx={{ width: '100px' }} onClick={() => navigateTo(-1)}>
          {tTasks('mobile.cancelBtn')}
        </Button>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100px' }}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {tTasks('mobile.requestBtn')}
        </Button>
      </Box>
    </Box>
  );
};
