import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USERS, GetUsersQuery } from '@/logicLayers/domain';

import { CheckboxesDropdown, EMPTY_CELL_VALUE } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

type FilterByServiceStuffProps = {
  handleChange: (selected: string[]) => void;
};

export const FilterByServiceStuff = ({ handleChange }: FilterByServiceStuffProps) => {
  const { t: tUsers } = useTranslation('users');

  const { data, loading } = useQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      pageSize: 1000
    }
  });

  const usersList = useMemo(() => {
    if (!data?.dataItems.items) {
      return [];
    }

    return data?.dataItems.items.map((user) => ({
      label: user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : EMPTY_CELL_VALUE,
      key: user?.id ?? '',
      value: user?.id,
      checked: true
    }));
  }, [data]);

  const isDisabled = !usersList?.length || loading;

  return (
    <CheckboxesDropdown
      id={'User-management-filter-by-role'}
      title={tUsers('table.filters.filterByServiceStuff.title')}
      menuList={usersList}
      hasSelectAll
      onChange={(options) => handleChange(options.map((finalOption) => finalOption.key))}
      disabled={isDisabled}
      hasSearch
      searchPlaceholder={tUsers('table.filters.filterByServiceStuff.searchPlaceholder')}
      isRound
    />
  );
};
