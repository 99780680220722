import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Button, Text } from 'infinitytechnologies-ui';
import 'dayjs/locale/en';

import Box from '@mui/material/Box';

import { AlertCheckIcon, AlertErrorIcon, AlertWarningIcon, DotIcon, InfoIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import {
  ErrorNotificationTypes,
  InfoNotificationTypes,
  Notification,
  NotificationType,
  SuccessNotificationTypes,
  WarningNotificationTypes
} from '../../helpers';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: (number: any) => {
      return number > 1 ? '[on] D MMM, YYYY' : '%d days';
    },
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
});

interface NotificationsPopoverProps {
  notification: Notification;
  handleMarkAsRead: (id: string) => void;
  handleRemove: (id: string) => void;
}

export const NotificationItem = ({ notification, handleMarkAsRead, handleRemove }: NotificationsPopoverProps) => {
  const { t: tGlobal } = useTranslation('global');

  const formatDate = (date: Date) => {
    const now = dayjs();
    const diffInDays = now.diff(dayjs(date), 'day');

    if (diffInDays === 0) {
      return dayjs(date).fromNow();
    } else {
      return dayjs(date).format('D MMM, YYYY');
    }
  };

  return (
    <Box
      key={notification.id}
      sx={{
        padding: '12px 16px',
        borderBottom: '1px solid rgba(41, 49, 62, 0.15)',
        background: notification.read ? 'transparent' : '#F5F7FA',
        display: 'flex'
      }}
      onClick={() => handleMarkAsRead(notification.id)}
    >
      <Box sx={{ marginRight: '12px' }}>
        {InfoNotificationTypes.includes(notification?.type as NotificationType) ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              width: '32px',
              padding: '4px',
              borderRadius: '50%',
              background: '#F4FAFF'
            }}
          >
            <InfoIcon sx={{ fontSize: '24px', fill: '#265299' }} />
          </Box>
        ) : null}
        {WarningNotificationTypes.includes(notification?.type as NotificationType) ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              width: '32px',
              padding: '4px',
              borderRadius: '50%',
              background: '#FFFAE7'
            }}
          >
            <AlertWarningIcon sx={{ fontSize: '24px', fill: '#946F00' }} />
          </Box>
        ) : null}
        {SuccessNotificationTypes.includes(notification?.type as NotificationType) ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              width: '32px',
              padding: '4px',
              borderRadius: '50%',
              background: '#E6F6EF'
            }}
          >
            <AlertCheckIcon sx={{ fontSize: '24px', fill: '#2D96FF' }} />
          </Box>
        ) : null}
        {ErrorNotificationTypes.includes(notification?.type as NotificationType) ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '32px',
              width: '32px',
              padding: '4px',
              borderRadius: '50%',
              background: '#FFF5F7'
            }}
          >
            <AlertErrorIcon sx={{ fontSize: '24px', fill: '#BE0E2C' }} />
          </Box>
        ) : null}
      </Box>
      <Box sx={{ width: 'calc(100% - 24px)' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#121B2A',
              a: { color: '#121B2A', fontSize: '14px', fontWeight: '600' }
            }}
            dangerouslySetInnerHTML={{
              __html: notification.content?.title || ''
            }}
          />
          {!notification.read ? <DotIcon sx={{ fill: '#2D96FF' }} /> : null}
        </Box>
        <Text
          sx={{ fontSize: '12px', color: '#121B2A', a: { color: '#121B2A', fontSize: '12px' } }}
          dangerouslySetInnerHTML={{
            __html: notification.content?.content || ''
          }}
        />
        <Text sx={{ fontSize: '12px', fontWeight: '500', color: '#6A7A94' }}>
          {formatDate(notification.createdDate)}
        </Text>
        <Button
          variant="round"
          size="small"
          sx={{ padding: '0', color: '#265299 !important', marginLeft: '-8px', marginTop: '10px' }}
          onClick={() => handleRemove(notification.id)}
        >
          {tGlobal('notifications.btnRemove')}
        </Button>
      </Box>
    </Box>
  );
};
