type InputObject = {
  [key: string]: string | FileList;
};

type TransformedObject = {
  id: string;
  value: string;
  note: string;
  fileKey: string;
  cancelReason: string;
};

export function transformObjectToArray(inputObj: InputObject): TransformedObject[] {
  const result: TransformedObject[] = [];

  const grouped = Object.keys(inputObj).reduce((acc: { [key: string]: any }, key: string) => {
    const [id, field] = key.split('_').slice(0, 2);

    if (!acc[id]) {
      acc[id] = {};
    }

    acc[id][field] = inputObj[key];
    return acc;
  }, {});

  Object.entries(grouped).forEach(([id, fields]) => {
    result.push({
      id,
      value:
        fields.value?.date && fields.value?.time
          ? `${fields.value.date.format('YYYY-MM-DD')}T${fields.value.time.format('HH:mm:ss')}`
          : fields.value || '',
      note: fields.note || undefined,
      fileKey: fields.photo || undefined,
      cancelReason: fields.cancelReason || undefined
    });
  });

  return result;
}
