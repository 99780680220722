import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CheckSimpleIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#3C4656', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path d="M7.95848 12.9297L15.1572 5.73101C15.2811 5.60708 15.4283 5.54378 15.5987 5.5411C15.7691 5.53843 15.9189 5.60174 16.0482 5.73101C16.1775 5.86028 16.2421 6.00878 16.2421 6.17651C16.2421 6.34424 16.1775 6.49274 16.0482 6.62201L8.48571 14.1845C8.33507 14.3351 8.15933 14.4105 7.95848 14.4105C7.75763 14.4105 7.58189 14.3351 7.43125 14.1845L3.95209 10.7053C3.82815 10.5814 3.76699 10.4342 3.76859 10.2638C3.7702 10.0934 3.83563 9.94361 3.9649 9.81435C4.09418 9.68507 4.24268 9.62043 4.4104 9.62043C4.57813 9.62043 4.72663 9.68507 4.8559 9.81435L7.95848 12.9297Z" />
    </SvgIcon>
  );
}
