import { FC } from 'react';

import Stack from '@mui/material/Stack';

import { WidgetType } from '@/logicLayers/domain';

import { Flex, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState } from '../../ContextProvider';
import { WidgetPlate } from '../WidgetPlate';

import { WIDGETS_LIST } from './helpers';

export const Step2: FC = () => {
  const { t: tWidgets } = useTranslation('widgets');

  const { handleSetWidgetType, selectedWidgetTypes } = useContextState();

  const selectParameterHandler = (id: number, type: WidgetType) => {
    handleSetWidgetType(type);
  };

  return (
    <Stack minHeight={'70vh'}>
      <TextBlockControl
        title={tWidgets('createWidgets.step2.title')}
        subTitle={tWidgets('createWidgets.step2.description')}
        sxBox={{ marginTop: '24px' }}
        sxSubTitle={{ marginBottom: 0, maxWidth: 660 }}
      />

      <Flex flexWrap={'wrap'} gap={'24px'}>
        {WIDGETS_LIST.map((widget) => (
          <WidgetPlate
            key={widget.id}
            widget={widget}
            isSelected={selectedWidgetTypes[widget.widgetType]}
            onClick={() => selectParameterHandler(widget.id, widget.widgetType)}
          />
        ))}
      </Flex>
    </Stack>
  );
};
