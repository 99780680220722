import { CheckboxesDropdown } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { frquencyOptions } from './helpers';

type FilterByFrequencyProps = {
  handleChange: (selected: string[]) => void;
};

export const FilterByFrequency = ({ handleChange }: FilterByFrequencyProps) => {
  const { t: tTasks } = useTranslation('tasks');

  return (
    <CheckboxesDropdown
      id={'User-management-filter-by-role'}
      title={tTasks('tasks.frequency')}
      menuList={frquencyOptions}
      hasSelectAll
      onChange={(options) => handleChange(options.map((finalOption) => finalOption.key))}
      isRound
    />
  );
};
