import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GetNotifications,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  SUBSCRIBE_TO_NOTIFICATIONS
} from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { NotificationItem } from './NotificationItem';

export const NotificationsPage = () => {
  const { t: tGlobal } = useTranslation('global');

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: notificationsData,
    fetchMore,
    refetch
  } = useQuery<GetNotifications>(GET_NOTIFICATIONS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 20,
          page: 0
        },
        sortable: {
          column: 'createdDate',
          direction: 'DESC'
        }
      }
    },
    fetchPolicy: 'no-cache'
  });

  const { data: newNotification } = useSubscription(SUBSCRIBE_TO_NOTIFICATIONS);

  const [markAllNotificationsAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  useEffect(() => {
    refetch();
  }, [newNotification]);

  const handleMarkAsRead = (id: string) => {
    markNotificationAsRead({ variables: { notificationId: id } });
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  const handleRemove = (id: string) => {
    deleteNotification({ variables: { notificationId: id } });
  };

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPage + 1,
            pageSize: 20
          },
          sortable: {
            column: 'createdDate',
            direction: 'DESC'
          }
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setHasMore((currentPage + 1) * 20 < fetchMoreResult.notifications.total);
        setCurrentPage(currentPage + 1);

        return {
          notifications: {
            items: [...prev.notifications.items, ...fetchMoreResult.notifications.items],
            total: fetchMoreResult.notifications.total
          }
        };
      }
    });
  };

  return (
    <Box>
      <AppBar sx={{ boxShadow: 'none', borderBottom: 'none', backgroundColor: '#FFF' }} component="nav">
        <Box
          sx={{
            height: '64px',
            padding: '12px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#FFFFFF'
          }}
        >
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tGlobal('notifications.title')}</Text>
          <Button variant={'text'} sx={{ padding: '0', color: '#265299 !important' }} onClick={handleMarkAllAsRead}>
            {tGlobal('notifications.btnMarkAllAsRead')}
          </Button>
        </Box>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto',
          padding: '64px 0px 76px 0px'
        }}
      >
        {notificationsData?.notifications.items.length ? (
          <Box sx={{ height: '100%' }}>
            <InfiniteScroll
              dataLength={notificationsData?.notifications?.items.length ?? 0}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={null}
            >
              <>
                {notificationsData?.notifications.items.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    handleMarkAsRead={handleMarkAsRead}
                    handleRemove={handleRemove}
                  />
                ))}
              </>
            </InfiniteScroll>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '544px'
            }}
          >
            <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
            <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
              {tGlobal('notifications.noNotifications')}
            </Text>
            <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
              {tGlobal('notifications.noNotificationsDescription')}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
