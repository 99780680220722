import { MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Dialog, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  GET_EQUIPMENT_ROUND_REGULATION,
  RoundRegulationQuery,
  SWITCH_ROUND_ACTIVE_STATE,
  SWITCH_ROUND_ARCHIVE_STATE
} from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { AlertCheckNoticeIcon, DeleteTrashIcon, DoNotDisturbOnIcon, MoreVertIcon } from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';
import { TranslationT } from '@/subsidiaryBinders/types';

import { useTranslation } from '@/i18n';

interface ActionMenuProps {
  data: RoundRegulationQuery | undefined;
  renderModalContent: () => ReactNode;
  isRoundTask?: boolean;
  isLocations?: boolean;
}

interface RowActionMenuState {
  isModalOpen: boolean;
  modalType: string;
}

const getModalData = (
  data: RoundRegulationQuery | undefined,
  state: RowActionMenuState,
  t: TranslationT,
  tGlobal: TranslationT,
  handleChangeItemActiveState: () => void,
  handleChangeItemArchiveState: () => void,
  isRoundTask?: boolean
) => {
  const isItemActive = data?.dataItems?.active;

  const checkDisable = (text: string) => {
    return isItemActive && !data?.dataItems?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkEnable = (text: string) => {
    return !isItemActive && !data?.dataItems?.archived && state.modalType === 'toggleActive' ? text : false;
  };

  const checkArchive = (text: string) => {
    return !isItemActive && !data?.dataItems?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const checkUnArchive = (text: string) => {
    return !isItemActive && data?.dataItems?.archived && state.modalType === 'toggleArchive' ? text : false;
  };

  const modalTitleForDisable = isRoundTask
    ? checkDisable(t('equipmentItem.round.modal.titleToDisableTask'))
    : checkDisable(t('equipmentItem.round.modal.titleToDisable'));
  const modalTitleForEnable = isRoundTask
    ? checkEnable(t('equipmentItem.round.modal.titleToEnableTask'))
    : checkEnable(t('equipmentItem.round.modal.titleToEnable'));
  const modalTitleForArchive = isRoundTask
    ? checkArchive(t('equipmentItem.round.modal.titleToArchiveTask'))
    : checkArchive(t('equipmentItem.round.modal.titleToArchive'));
  const modalTitleForUnArchive = checkUnArchive(t('equipmentItem.round.modal.titleToUnArchive'));

  const modalContentForDisable = isRoundTask
    ? checkDisable(t('equipmentItem.round.modal.contentToDisableTask'))
    : checkDisable(t('equipmentItem.round.modal.contentToDisable'));
  const modalContentForEnable = isRoundTask
    ? checkEnable(t('equipmentItem.round.modal.contentToEnableTask'))
    : checkEnable(t('equipmentItem.round.modal.contentToEnable'));
  const modalContentForArchive = isRoundTask
    ? checkArchive(t('equipmentItem.round.modal.contentToArchiveTask'))
    : checkArchive(t('equipmentItem.round.modal.contentToArchive'));
  const modalContentForUnArchive = checkUnArchive(t('equipmentItem.round.modal.contentToUnArchive'));

  const modalBtnTextForDisable = checkDisable(tGlobal('btn.btnDisable'));
  const modalBtnTextForEnable = checkEnable(tGlobal('btn.btnEnable'));
  const modalBtnTextForArchive = checkArchive(tGlobal('btn.btnRemove'));
  const modalBtnTextForUnArchive = checkUnArchive(tGlobal('btn.btnUnArchive'));

  const modalTitle = modalTitleForDisable || modalTitleForEnable || modalTitleForArchive || modalTitleForUnArchive;
  const modalContent =
    modalContentForDisable || modalContentForEnable || modalContentForArchive || modalContentForUnArchive;
  const modalBtnText =
    modalBtnTextForDisable || modalBtnTextForEnable || modalBtnTextForArchive || modalBtnTextForUnArchive;

  const handleModalAction =
    modalTitleForDisable || modalTitleForEnable ? handleChangeItemActiveState : handleChangeItemArchiveState;

  return {
    modalTitle,
    modalContent,
    modalBtnText,
    handleModalAction
  };
};

export const ActionMenu = ({ data, renderModalContent, isRoundTask, isLocations }: ActionMenuProps) => {
  const { t: tGlobal } = useTranslation('global');
  const { t } = useTranslation('templates');

  const navigateTo = useNavigate();

  const [state, setState] = useState<RowActionMenuState>({
    isModalOpen: false,
    modalType: ''
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [SwitchRoundActiveState] = useMutation<any>(SWITCH_ROUND_ACTIVE_STATE, {
    refetchQueries: [GET_EQUIPMENT_ROUND_REGULATION]
  });

  const [SwitchRoundArchiveState] = useMutation<any>(SWITCH_ROUND_ARCHIVE_STATE, {
    refetchQueries: [GET_EQUIPMENT_ROUND_REGULATION]
  });

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeItemActiveState = () => {
    const isActive = data?.dataItems?.active;

    SwitchRoundActiveState({
      variables: {
        request: {
          ids: [data?.dataItems?.id],
          active: !isActive
        }
      }
    });

    handleCloseModal();

    const alertTitle = !isActive
      ? tGlobal(`alertMessages.success.templates.round.${isLocations ? 'edit.isEnabled' : 'roundEdit.isEnabled'}`)
      : tGlobal(`alertMessages.success.templates.round.${isLocations ? 'edit.isDisabled' : 'roundEdit.isDisabled'}`);

    AlertService.showAlert({
      title: alertTitle,
      severity: 'success',
      autoHideDuration: 1500
    });
  };

  const handleChangeItemArchiveState = () => {
    if (data && data?.dataItems?.active === false) {
      const isArchive = data?.dataItems?.archived;

      SwitchRoundArchiveState({
        variables: {
          request: {
            ids: [data?.dataItems?.id],
            archived: !isArchive
          }
        }
      });

      handleCloseModal();

      const alertArchiveTitle = !isArchive
        ? tGlobal(`alertMessages.success.templates.round.${isLocations ? 'edit.isArchived' : 'roundEdit.isArchived'}`)
        : tGlobal(
            `alertMessages.success.templates.round.${isLocations ? 'edit.isUnArchived' : 'roundEdit.isUnArchived'}`
          );

      AlertService.showAlert({
        title: alertArchiveTitle,
        severity: 'success',
        autoHideDuration: 1500
      });

      setTimeout(() => {
        navigateTo(
          isLocations
            ? LINKS_PAGES.equipmentItemRoundRegulations.replace(':templateId', `${data?.dataItems?.equipment?.[0].id}`)
            : LINKS_PAGES.tasks
        );
      }, 1500);
    }
  };

  const handleOpenModal = (modalType: string) => {
    setAnchorEl(null);

    setState({ isModalOpen: true, modalType });
  };

  const handleCloseModal = () => {
    setState((state) => ({ ...state, isModalOpen: false }));
  };

  const { modalTitle, modalContent, modalBtnText, handleModalAction } = getModalData(
    data,
    state,
    t,
    tGlobal,
    handleChangeItemActiveState,
    handleChangeItemArchiveState,
    isRoundTask
  );

  return (
    <Box component={'td'} className={'table-edit-button'}>
      <IconButton size={'small'} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        {!data?.dataItems?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')} disabled={data?.dataItems?.archived}>
            <AlertCheckNoticeIcon sx={{ fill: '#06845B', fontSize: 20 }} />

            {tGlobal('statuses.enable')}
          </MenuItem>
        ) : null}

        {data?.dataItems?.active ? (
          <MenuItem onClick={() => handleOpenModal('toggleActive')}>
            <DoNotDisturbOnIcon />

            {tGlobal('statuses.disable')}
          </MenuItem>
        ) : null}

        <MenuItem disabled={data?.dataItems?.active} onClick={() => handleOpenModal('toggleArchive')}>
          <DeleteTrashIcon sx={{ fontSize: 20 }} />

          {tGlobal('btn.btnRemove')}
        </MenuItem>
      </Menu>

      <Dialog
        headerTitle={modalTitle}
        hideHeaderBorder
        contentFooter={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={'my-0'}
              variant={'text'}
              sx={{ margin: '0 24px 0 0 !important' }}
              onClick={handleCloseModal}
            >
              {tGlobal('btn.btnCancel')}
            </Button>

            <Button className={'my-0'} variant={'contained'} onClick={handleModalAction}>
              {modalBtnText}
            </Button>
          </div>
        }
        open={state.isModalOpen}
        onClose={handleCloseModal}
      >
        <Text
          className={'m-0'}
          variant={'bodyLg'}
          dangerouslySetInnerHTML={{
            __html: modalContent as string
          }}
        />

        <Box sx={{ width: '100%', flex: '0 1 100%' }}>{renderModalContent()}</Box>
      </Dialog>
    </Box>
  );
};
