import { Text } from 'infinitytechnologies-ui';

interface TextBlockStepProps {
  title?: string;
  titleSubText?: string;
  titleSubLink?: string;
  link?: string;
  isMobile?: boolean;
}

export function TextBlockStep({ title, titleSubText, titleSubLink, link, isMobile }: TextBlockStepProps) {
  return (
    <>
      {title ? (
        <Text
          className={'sign-in__step__title'}
          component={'h3'}
          sx={{ fontSize: isMobile ? '28px !important' : '24px' }}
        >
          {title}
        </Text>
      ) : null}

      {titleSubText || titleSubLink ? (
        <div className={'sign-in__step__text'}>
          {titleSubText ? <Text>{titleSubText}</Text> : null}

          {titleSubLink ? <a href={link}>{titleSubLink}</a> : null}
        </div>
      ) : null}
    </>
  );
}
