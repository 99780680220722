import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Text, TextField } from 'infinitytechnologies-ui';

import { EDIT_USER_PROFILE, GET_USER_PROFILE, GetProfileQuery } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { Preloader, PreloaderVariantsE, TextFieldMask } from '@/separatedModules/components';

import { USER_NUMBER_MASK } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

const FIELD_PHONE_NUMBER = 'phoneNumber';

export const EditPhone = () => {
  const navigateTo = useNavigate();

  const { t: tProfile } = useTranslation('profile');
  const { t: tGlobal, i18n } = useTranslation('global');

  const maskPattern = USER_NUMBER_MASK[i18n.language];

  const [EditUserProfile, { data: editedUserData, error: errorEditUserProfile }] = useMutation(EDIT_USER_PROFILE);
  const { data: dataUserProfile, loading: loadingUserProfile } = useQuery<GetProfileQuery>(GET_USER_PROFILE);

  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [`${FIELD_PHONE_NUMBER}`]: ''
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    const { phoneNumber } = getValues();

    EditUserProfile({
      variables: {
        phoneNumber,
        fileKey: dataUserProfile?.profile.fileKey,
        firstName: dataUserProfile?.profile.firstName,
        lastName: dataUserProfile?.profile.lastName
      }
    });
  });

  useEffect(() => {
    if (editedUserData?.editProfile.id) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.success.profile.editPhone'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(LINKS_PAGES.profile);
      }, 2500);
    }

    if (errorEditUserProfile) {
      AlertService.showAlert({
        title: tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [editedUserData, errorEditUserProfile]);

  useEffect(() => {
    const userPhoneNumber = dataUserProfile?.profile?.phoneNumber;

    if (userPhoneNumber) {
      setValue(FIELD_PHONE_NUMBER, userPhoneNumber);
    }
  }, [dataUserProfile]);

  return (
    <form onSubmit={handleSubmitForm}>
      {loadingUserProfile ? (
        <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} sx={{ height: '400px' }} isContainer />
      ) : (
        <>
          <Text className={'edit-user__content__title'} variant={'titleLg'}>
            {tProfile('edit.phone.title')}
          </Text>

          <Controller
            name={FIELD_PHONE_NUMBER}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.phoneRequired')
              },
              pattern: {
                value: maskPattern.patternHookForm,
                message: tGlobal('validation.phoneValid')
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  label={tProfile('edit.phone.inputNameLabel')}
                  value={value}
                  validation={{
                    isValid: !errors[FIELD_PHONE_NUMBER]?.message,
                    error: errors[FIELD_PHONE_NUMBER]?.message
                  }}
                  InputProps={{
                    inputComponent: TextFieldMask as any
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />

          <Button
            className={'edit-user__content__btn'}
            variant={'contained'}
            type={'submit'}
            disabled={Boolean(editedUserData?.editProfile.id)}
          >
            {tProfile('edit.phone.btnChange')}
          </Button>
        </>
      )}
    </form>
  );
};
