import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';

import { EditEquipmentReqInput, EquipmentQuery } from '@/logicLayers/domain';

export const updateEquipmentItemModel = (
  data: EquipmentQuery,
  update: Partial<EditEquipmentReqInput>
): EditEquipmentReqInput => {
  return merge(
    {
      fileKey: data.equipment.fileUrl?.fileKey || '',
      name: data.equipment.name || '',
      itemDescription: data.equipment.itemDescription || '',
      templateDescription: data.equipment.templateDescription || '',
      manufacturer: data.equipment.manufacturer || '',
      modelNumber: data.equipment.modelNumber || '',
      domainId: data.equipment.domainId || '',
      ...(data.equipment.responsible ? { responsible: data.equipment.responsible.id } : {})
    },
    update
  );
};

export const updateEquipmentParameterItemModel = (data: any, update: any) => {
  return merge(
    {
      title: data.title,
      unitId: data.unitId,
      description: data.description,
      value: data.value
    },
    update
  );
};

export const updateEquipmentRoundItemModel = (data: any, update: any) => {
  const result = merge(
    {
      name: data.name,
      serviceStaff: update.serviceStaff?.length ? data?.serviceStaff.map((staff: { id: any }) => staff.id) : [],
      description: data.description,
      activationTargetDate: data.activationTargetDate,
      frequency: data.frequency?.frequency || 'DAILY'
    },
    update
  );

  if (isArray(update.serviceStaff)) {
    result.serviceStaff = cloneDeep(update.serviceStaff);
  }

  return result;
};

export const updateEquipmentRoundTaskModel = (data: any, update: any) => {
  const result = merge(
    {
      name: data.name,
      serviceStaff: data?.serviceStaff.map((staff: { id: any }) => staff.id),
      description: data.description
    },
    update
  );

  if (isArray(update.serviceStaff)) {
    result.serviceStaff = cloneDeep(update.serviceStaff);
  }

  return result;
};
