import { useMemo, useState } from 'react';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { CheckSimpleIcon, CloseIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { createSortingBy } from './helpers';

interface MobileLocationFilterProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onChangeSort: (direction: string) => void;
}

export const MobileSort = ({ isOpen, onClose, onOpen, onChangeSort }: MobileLocationFilterProps) => {
  const { t: tTasks } = useTranslation('tasks');

  const [selectedSort, setSelectedSort] = useState<string>('');

  const handleApplyClick = () => {
    onChangeSort(selectedSort);
    onClose();
  };

  const handleCancelClick = () => {
    setSelectedSort('');
    onChangeSort('');
    onClose();
  };

  const sortingList = useMemo(() => createSortingBy(), []);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '32px',
              height: '4px',
              backgroundColor: '#5C6C83',
              opacity: '0.4',
              borderRadius: '100px',
              margin: '16px'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '40px',
            position: 'relative',
            alignItems: 'center'
          }}
        >
          <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
            {tTasks('mobile.sortTitle')}
          </Text>
          <CloseIcon
            sx={{
              fontSize: '24px',
              position: 'absolute',
              right: '12px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '16px 16px 8px 16px', gap: '16px' }}>
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}> {tTasks('mobile.dueTimeSort')}</Text>
        </Box>
        <Box>
          {sortingList.map((option) => (
            <Box
              key={option.key}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 16px',
                backgroundColor: option.value === selectedSort ? '#E6F3FF' : 'transparent'
              }}
              onClick={() => setSelectedSort(option.value)}
            >
              <Text sx={{ fontSize: '14px' }}>{option.label}</Text>
              {option.value === selectedSort ? <CheckSimpleIcon /> : null}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '12px 16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0px 37px 0px'
          }}
        >
          <Button variant="text" onClick={handleCancelClick}>
            {tTasks('mobile.resetBtn')}
          </Button>
          <Button variant={'contained'} sx={{ margin: '16px' }} onClick={handleApplyClick}>
            {tTasks('mobile.applyBtn')}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
