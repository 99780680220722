import { i18n } from '@/i18n/config';

export function getBreadcrumbs(locationFolder: any, isShort: boolean, isMobile: boolean = false) {
  const breadcrumbs = !isMobile
    ? [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }]
    : [{ link: '/', titleFallback: i18n.t('title', { ns: 'home' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  if (isShort) {
    if (breadcrumbs.length > 2) {
      breadcrumbs.splice(1, breadcrumbs.length - 2, { link: '', titleFallback: '...' });
    }
  }

  return breadcrumbs;
}
