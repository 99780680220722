export interface TaskItem {
  id: number;
  title: string;
  description: string;
  disabled?: boolean;
}

type Widget = {
  type: string;
  resourceIds: string[];
};

type OutputFormat = {
  name: string;
  widgets: Widget[];
};

export function formatData(formData: FormProps): OutputFormat {
  const widgets: Widget[] = [];

  const fields: Record<string, string[] | undefined> = {
    [FIELD_RESULTS_OF_COMPLETED_ROUNDS]: formData[FIELD_RESULTS_OF_COMPLETED_ROUNDS],
    [FIELD_ROUNDS_BY_EMPLOYEE]: formData[FIELD_ROUNDS_BY_EMPLOYEE],
    [FIELD_CUSTOM_TASKS_BY_EMPLOYEE]: formData[FIELD_CUSTOM_TASKS_BY_EMPLOYEE],
    [FIELD_EQUIPMENT_IN_CRITICAL_CONDITION]: formData[FIELD_EQUIPMENT_IN_CRITICAL_CONDITION],
    [FIELD_CALENDAR_OF_ROUND_REGULATIONS]: formData[FIELD_CALENDAR_OF_ROUND_REGULATIONS]
  };

  Object.entries(fields).forEach(([type, resourceIds]) => {
    if (resourceIds) {
      widgets.push({
        type,
        resourceIds
      });
    }
  });

  return {
    name: formData[FIELD_WIDGET_NAME],
    widgets
  };
}

export const FORM_ID = 'create-widget';

export const FIELD_WIDGET_NAME = 'widgetName';
export const FIELD_RESULTS_OF_COMPLETED_ROUNDS = 'RESULT_OF_COMPLETED_ROUNDS';
export const FIELD_ROUNDS_BY_EMPLOYEE = 'ROUND_BY_EMPLOYEE';
export const FIELD_CUSTOM_TASKS_BY_EMPLOYEE = 'CUSTOM_TASK_BY_EMPLOYEE';
export const FIELD_EQUIPMENT_IN_CRITICAL_CONDITION = 'EQUIPMENT_IN_CRITICAL_CONDITIONS';
export const FIELD_CALENDAR_OF_ROUND_REGULATIONS = 'CALENDAR_OF_ROUND_REGULATIONS';

export interface FormProps {
  [FIELD_WIDGET_NAME]: string;
  [FIELD_RESULTS_OF_COMPLETED_ROUNDS]: string[] | undefined;
  [FIELD_ROUNDS_BY_EMPLOYEE]: string[] | undefined;
  [FIELD_CUSTOM_TASKS_BY_EMPLOYEE]: string[] | undefined;
  [FIELD_EQUIPMENT_IN_CRITICAL_CONDITION]: string[] | undefined;
  [FIELD_CALENDAR_OF_ROUND_REGULATIONS]: string[] | undefined;
}

export const DEFAULT_VALUES: FormProps = {
  widgetName: '',
  RESULT_OF_COMPLETED_ROUNDS: undefined,
  ROUND_BY_EMPLOYEE: undefined,
  CUSTOM_TASK_BY_EMPLOYEE: undefined,
  EQUIPMENT_IN_CRITICAL_CONDITIONS: undefined,
  CALENDAR_OF_ROUND_REGULATIONS: undefined
};
