import { useFormContext } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { Box, Checkbox } from '@mui/material';

import { Flex } from '@/separatedModules/components';

import { useContextState } from '../../ContextProvider';

import { DateAndTimePicker } from './components/DateAndTimePicker';

import { ROUND } from '../../helpers';
import { Frequency, getDisplayText } from './helpers';

export interface ActivateRoundProps {
  onChange: (date: Dayjs, time: Dayjs) => void;
  value: {
    date: Dayjs;
    time: Dayjs;
  };
}

export const ActivateRound = ({ onChange, value }: ActivateRoundProps) => {
  const { isShowDate, handleChangeIsShowDate } = useContextState();

  const minDate = dayjs();
  const maxDate = dayjs().add(2, 'year').endOf('year');
  const minTime = dayjs().subtract(1, 'minute');

  const { watch } = useFormContext();

  const watchedValue = watch(ROUND) as Frequency;

  const displayText = getDisplayText(watchedValue, value.date, value.time);

  return (
    <Box
      sx={{
        borderRadius: '4px',
        background: 'var(--background-neutral-hovered, #F7F8F9)',
        padding: '8px 0px',
        marginTop: '24px'
      }}
    >
      <Flex alignItems={'center'} sx={{ paddingLeft: '1px' }}>
        <Checkbox
          sx={{ width: '46px' }}
          checked={isShowDate}
          onClick={() => {
            handleChangeIsShowDate();
          }}
        />
        <Text sx={{ fontSize: '14px', fontWeight: '500' }}>Activate round</Text>
      </Flex>
      {isShowDate && (
        <DateAndTimePicker
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          displayText={displayText}
          minTime={minTime}
        />
      )}
    </Box>
  );
};
