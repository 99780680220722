import { useQuery } from '@apollo/client';

import { EquipmentQuery, GET_EQUIPMENT } from '@/logicLayers/domain';

export const ITEM_FIELDS = {
  NAME: 'name',
  TEMPLATE_DESCRIPTION: 'templateDescription',
  ITEM_DESCRIPTION: 'itemDescription',
  MANUFACTURER: 'manufacturer',
  MODEL_NUMBER: 'modelNumber',
  DOMAIN_ID: 'domainId',
  PARAMETER_TITLE: 'parameterTitle',
  NUMERIC_VALUE: 'numericValue',
  GUIDE: 'guide',
  UNIT: 'unit',
  FORMULA: 'formula',
  CONTACT: 'emergencyContact'
};

export const useQueryData = ({ templateId, skip }: { templateId: string; skip?: boolean }) => {
  const { data: dataEquipmentTemplate, loading: loadingEquipmentTemplate } = useQuery<EquipmentQuery>(GET_EQUIPMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: templateId || undefined
    },
    skip
  });

  return {
    dataEquipmentTemplate,
    loadingEquipmentTemplate
  };
};

// TO DO add types
export const getBreadcrumbs = (data: any) => {
  const breadcrumbParts = data?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(data?.name);

  const breadcrumbsLinks = breadcrumbParts
    .filter((item: string | undefined) => item !== undefined)
    .map((item: string) => ({
      titleFallback: item
    }));

  if (breadcrumbsLinks.length > 2) {
    breadcrumbsLinks.splice(1, breadcrumbsLinks.length - 2, { titleFallback: '...' });
  }

  return breadcrumbsLinks;
};
