import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';

import { EquipmentRes } from '@/logicLayers/domain';

import {
  ArrowForwardIcon,
  Breadcrumbs,
  ChevronRightIcon,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  ImageLazy
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { getBreadcrumbs } from './helpers';

const Title = ({ title }: { title: string }) => {
  return (
    <Text variant={'labelSm'} sx={{ fontSize: '12px', lineHeight: '16px', margin: '0 0 8px' }}>
      {title}
    </Text>
  );
};

export const EquipmentItem = ({
  name,
  domain,
  fileUrl,
  systemName,
  createdDate,
  lastModifiedDate,
  id,
  location
}: EquipmentRes) => {
  const { t: tEquipment } = useTranslation('equipment');

  const breadcrumbsLinks = getBreadcrumbs(location);

  const navigateTo = useNavigate();

  const handleClickDetails = () =>
    navigateTo(LINKS_PAGES.equipmentModel.replace(':templateId', String(id)).replace('/:taskId', ''));

  return (
    <Box>
      {fileUrl?.url ? (
        <Box sx={{ margin: '20px 0' }}>
          <ImageLazy
            src={fileUrl?.url}
            alt={`Equipment ${name} logo`}
            variant={'rectangular'}
            width={'100%'}
            height={'auto'}
            minHeight={170}
            sxStyle={{
              img: {
                maxHeight: '170px',
                objectFit: 'cover',
                borderRadius: 'var(--4, 4px)'
              }
            }}
          />
        </Box>
      ) : null}

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tEquipment('allEquipment.table.aside.location')} />

        <Text className={'m-0'} variant={'bodyLg'}>
          {breadcrumbsLinks.length > 0 ? (
            <Breadcrumbs
              links={breadcrumbsLinks}
              separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
              sx={{ padding: 0, width: '260px' }}
            />
          ) : (
            EMPTY_CELL_VALUE
          )}
        </Text>
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tEquipment('allEquipment.table.aside.systemName')} />

        <Text className={'m-0'} variant={'bodyLg'}>
          {systemName || EMPTY_CELL_VALUE}
        </Text>
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tEquipment('allEquipment.table.aside.domain')} />

        {domain?.id ? (
          <DomainStatusChip color={domain.color} label={domain.name} />
        ) : (
          <Text className={'m-0'} variant={'bodyLg'}>
            {EMPTY_CELL_VALUE}
          </Text>
        )}
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tEquipment('allEquipment.table.aside.dateModified')} />

        <Text className={'m-0'} variant={'bodyLg'}>
          {lastModifiedDate ? dayjs(lastModifiedDate).format('DD MMM YYYY') : EMPTY_CELL_VALUE}
        </Text>
      </Box>

      <Box sx={{ margin: '0 0 32px 0' }}>
        <Title title={tEquipment('allEquipment.table.aside.dateCreated')} />

        <Text className={'m-0'} variant={'bodyLg'}>
          {createdDate ? dayjs(createdDate).format('DD MMM YYYY') : EMPTY_CELL_VALUE}
        </Text>
      </Box>

      <Button
        variant={'text'}
        size={'small'}
        sx={{
          margin: '0 0 0 auto !important',
          '&.MuiButton-text': {
            '&:not(:active, :hover)': {
              background: 'transparent'
            }
          }
        }}
        endIcon={<SvgIcon component={ArrowForwardIcon} />}
        onClick={handleClickDetails}
      >
        {tEquipment('allEquipment.table.aside.btnDetails')}
      </Button>
    </Box>
  );
};
