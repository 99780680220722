export const Project = {
  root: {}
};

export const Base = {
  root: {
    '&.MuiRadio-colorPrimary': {
      '& > span': {
        alignItems: 'center'
      },
      '.MuiSvgIcon-root': {
        color: 'var(--default-gray-500)',
        transition: 'all 0.2s ease-in-out',
        '&:nth-child(2)': {
          width: '22px',
          height: '22px',
          left: '50%',
          marginLeft: '-11px'
        }
      },
      '&:hover, &.Mui-checked': {
        '.MuiSvgIcon-root': {
          color: 'rgb(25, 118, 210)'
        }
      },
      '&.Mui-disabled': {
        opacity: 0.4,
        '.MuiSvgIcon-root': {
          color: 'var(--default-gray-500)'
        }
      }
    }
  }
};
