import { ReactNode, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';

import { InfoIcon, SearchAutocomplete } from '@/separatedModules/components';

import { StyledTableFiltersBox } from './style';

type TableFiltersBoxProps = {
  searchPlaceholder: string;
  sxFiltersContainer?: SxProps<Theme>;
  sxCollapse?: SxProps<Theme>;
  sxBoxFilters?: SxProps<Theme>;
  sxBoxRight?: SxProps<Theme>;
  selectedItems?: any[];
  children: ReactNode;
  withSearch?: boolean;
  withInfoIcon?: boolean;
  isCompactSearch?: boolean;
  handleToggleAside?: () => void;
  handleChangeBySearch: (value: string) => void;
};

export const TableFiltersBox = ({
  searchPlaceholder,
  sxFiltersContainer,
  sxCollapse,
  sxBoxFilters,
  sxBoxRight,
  selectedItems = [],
  children,
  withSearch = true,
  withInfoIcon = true,
  handleToggleAside,
  handleChangeBySearch,
  isCompactSearch = false
}: TableFiltersBoxProps) => {
  const [checked, setChecked] = useState(true);

  const handleClearSearch = () => handleChangeBySearch('');
  const handleChangeSearch = (value: string) => handleChangeBySearch(value || '');

  useEffect(() => {
    if (selectedItems.length) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [selectedItems]);

  return (
    <StyledTableFiltersBox sx={sxFiltersContainer} withSearch={withSearch}>
      <Collapse
        classes={{ root: 'MuiCollapse-root-filters', wrapperInner: 'MuiCollapse-wrapperInner-filters' }}
        in={checked}
        sx={sxCollapse}
      >
        <Box sx={sxBoxFilters}>{children}</Box>

        <Box sx={sxBoxRight}>
          <SearchAutocomplete
            placeholder={searchPlaceholder}
            options={[]}
            sx={{ margin: '0', width: isCompactSearch ? '138px' : '100%' }}
            onChange={handleChangeSearch}
            onClear={handleClearSearch}
          />

          {withInfoIcon && (
            <IconButton size={'medium'} sx={{ borderRadius: '100%' }} onClick={handleToggleAside}>
              <InfoIcon />
            </IconButton>
          )}
        </Box>
      </Collapse>
    </StyledTableFiltersBox>
  );
};
