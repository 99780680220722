import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import { DOMAIN_STATUS_COLORS } from '@/logicLayers/domain';

interface StyledChipProps {
  colorVariant: string;
  fontSize?: string;
}

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop: string) => prop !== 'colorVariant'
})(({ colorVariant, fontSize = '14px' }: StyledChipProps) => ({
  background: DOMAIN_STATUS_COLORS[colorVariant].background,
  color: DOMAIN_STATUS_COLORS[colorVariant].color,
  maxWidth: '280px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',

  '& .MuiChip-label': {
    fontSize
  }
}));
