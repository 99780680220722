import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { EquipmentRes, EquipmentsQuery, EquipmentType, GET_EQUIPMENTS } from '@/logicLayers/domain';

import { checkTablePagination } from '@/separatedModules/components';

import { ContextStateI, FirstMountState, initState, initStateI, TableProviderProps } from './helpers';

export const ContextState = createContext<ContextStateI>({
  ...initState,
  loadingEquipmentItems: false,
  setState: () => {}
});

export const useContextState = () => useContext(ContextState);

export const ProviderState = ({ children }: TableProviderProps) => {
  const { folderId } = useParams();

  const [state, setState] = useState<initStateI>(initState);

  const firstMountState = useRef<FirstMountState>({
    isFirstMount: false,
    initCount: 0
  });

  const { data: dataEquipmentItems, loading: loadingEquipmentItems } = useQuery<EquipmentsQuery>(GET_EQUIPMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        domainIds: state.filters.sortByDomains.length ? state.filters.sortByDomains : undefined,
        isArchive: false,
        pageable: {
          page: state.pagination.page[0],
          pageSize: state.pagination.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0].value : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        }
      },
      locationIds: folderId ? [folderId] : undefined,
      type: EquipmentType.Equipment
    }
  });

  const contextValue = useMemo(() => {
    return {
      ...state,
      loadingEquipmentItems,
      setState
    };
  }, [state]);

  useEffect(() => {
    if (!dataEquipmentItems) return;

    const { isVisiblePagination, rowsPerPageOptions } = checkTablePagination(
      dataEquipmentItems?.dataItems.total || 0,
      state.pagination.rowsPerPageOptions
    );

    const correctItems = dataEquipmentItems?.dataItems?.items.map((item) => ({
      ...item,
      checked: true
    }));

    const bodyDataRows = (correctItems || []) as EquipmentRes[];
    const resultSearchCount = bodyDataRows?.length || 0;

    if (!firstMountState.current.isFirstMount) {
      firstMountState.current.initCount = dataEquipmentItems?.dataItems.total;
      firstMountState.current.isFirstMount = true;
    }

    setState((state) => {
      return {
        ...state,
        isVisiblePagination,
        bodyDataRows,
        loadingEquipmentItems,
        resultSearchCount,
        resultTotalCount: dataEquipmentItems?.dataItems.total,
        pagination: {
          ...state.pagination,
          rowsPerPageOptions
        }
      };
    });
  }, [dataEquipmentItems, loadingEquipmentItems]);

  return <ContextState.Provider value={contextValue}>{children}</ContextState.Provider>;
};
