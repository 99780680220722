import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import { AlertItemProps, AlertObservableService } from '@/logicLayers/infrastructure/services';

import { SnackbarItem } from './components/Snackbar';

export const AppSnackbarAlertList = () => {
  const [alertsList, setAlertsList] = useState([]);

  const handleUpdateAlerts = (alertsListArr: any) => setAlertsList(alertsListArr);

  useEffect(() => {
    AlertObservableService.subscribe(handleUpdateAlerts);
  }, []);

  const isOpen = Boolean(alertsList.length);

  return (
    <Box
      sx={{
        maxHeight: '500px',
        zIndex: '10000',
        transition: 'all 0.5s linear',
        overflowY: 'auto',
        padding: '12px',
        display: isOpen ? 'block' : 'none',
        '.MuiSnackbar-root:not(:last-child)': {
          margin: '0 0 12px'
        },
        '&:empty': {
          display: 'none'
        }
      }}
    >
      {alertsList.map(({ id, ...props }: AlertItemProps) => {
        return <SnackbarItem key={id} id={id} {...props} />;
      })}
    </Box>
  );
};
