import { Flex } from '@/separatedModules/components/Layout';

import { AdditionalBlock } from '../AdditionalBlock';

export interface AdditionalBlockWrapperProps {
  value: {
    addPhoto: boolean;
    addComment: boolean;
  };
  onChange: (value: { addPhoto?: boolean; addComment?: boolean }) => void;
}

export const AdditionalBlockWrapper = ({ value, onChange }: AdditionalBlockWrapperProps) => {
  return (
    <Flex justifyContent="space-between" marginTop="32px">
      <AdditionalBlock
        title="Add a Photo"
        subTitle="Ask your mechanic to add a photo for further inspection."
        value={value.addPhoto}
        onChange={(condition) => onChange({ ...value, addPhoto: condition })}
      />
      <AdditionalBlock
        title="Add a Comment"
        subTitle="For additional control, ask your mechanic to add a comment."
        value={value.addComment}
        onChange={(condition) => onChange({ ...value, addComment: condition })}
      />
    </Flex>
  );
};
