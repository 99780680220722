export const Project = {
  root: {
    '.MuiSwitch-track': {
      border: '2px solid rgb(25, 118, 210)'
    },
    '.MuiSwitch-switchBase': {
      '.MuiSwitch-thumb': {
        background: 'rgb(25, 118, 210)',
        '&:hover': {
          background: 'rgba(209, 213, 219, 0.3)'
        }
      },
      '&.Mui-disabled': {
        '.MuiSwitch-thumb': {
          background: 'rgb(25, 118, 210) !important',
          opacity: '0.4'
        },
        '& + .MuiSwitch-track': {
          borderColor: 'rgb(25, 118, 210) !important',
          opacity: '0.4 !important'
        }
      },
      '&.Mui-checked': {
        '&:hover': {
          background: 'rgba(254, 248, 255, 0.5)'
        },
        '.MuiSwitch-thumb': {
          background: 'var(--white)'
        },
        '& + .MuiSwitch-track': {
          background: '#326CC9 !important',
          borderColor: '#326CC9 !important'
        },
        '&.Mui-disabled': {
          '.MuiSwitch-thumb': {
            opacity: '1'
          },
          '& + .MuiSwitch-track': {
            opacity: '0.4 !important',
            background: 'var(--button-content-disabled)'
          }
        }
      }
    }
  },
  sizeSmall: {
    '.MuiSwitch-track': {
      border: '2px solid #D0D1D4'
    },
    '.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
      width: '12px',
      height: '12px',
      marginLeft: '-7px',
      marginTop: '-2px'
    },
    '.MuiSwitch-switchBase': {
      '.MuiSwitch-thumb': {
        background: '#ffffff',
        '&:hover': {
          background: 'rgba(209, 213, 219, 0.3)'
        }
      },
      '&.Mui-disabled': {
        '.MuiSwitch-thumb': {
          background: '#11182733'
        },
        '& + .MuiSwitch-track': {
          borderColor: '#090E1626'
        }
      },
      '&.Mui-checked': {
        '&:hover': {
          background: 'rgba(254, 248, 255, 0.5)'
        },
        '.MuiSwitch-thumb': {
          background: 'var(--white)'
        },
        '& + .MuiSwitch-track': {
          background: '#313849',
          borderColor: '#313849'
        },
        '&.Mui-disabled': {
          '.MuiSwitch-thumb': {},
          '& + .MuiSwitch-track': {
            background: '#090E160F'
          }
        }
      }
    }
  }
};

export const Base = {
  root: {
    borderRadius: '100px',
    border: 'none',
    width: '52px',
    height: '32px',
    overflow: 'visible',
    padding: 0,
    '.MuiSwitch-track': {
      borderRadius: '100px',
      border: 'paleturquoise',
      width: '52px',
      height: '32px',
      backgroundColor: 'transparent',
      opacity: '1 !important'
    },
    '.MuiSwitch-switchBase': {
      padding: '12px',
      marginTop: '-4px',
      marginLeft: '-4px',
      '.MuiSwitch-thumb': {
        width: '16px',
        height: '16px',
        boxShadow: 'none',
        background: 'paleturquoise',
        '&:hover': {
          background: 'paleturquoise'
        }
      },
      '.MuiSwitch-input': {
        left: '0',
        right: '0',
        width: '100%'
      },
      '&.Mui-disabled': {
        '.MuiSwitch-thumb': {
          background: 'paleturquoise',
          opacity: '0.4'
        },
        '& + .MuiSwitch-track': {
          border: '2px solid paleturquoise',
          opacity: '0.4 !important'
        }
      },
      '&.Mui-checked': {
        padding: '8px',
        '&:hover': {
          background: 'paleturquoise'
        },
        '.MuiSwitch-thumb': {
          background: 'paleturquoise',
          width: '24px',
          height: '24px'
        },
        '& + .MuiSwitch-track': {
          background: 'paleturquoise',
          border: '2px solid paleturquoise'
        },
        '&.Mui-disabled': {
          '.MuiSwitch-thumb': {
            background: 'darkgrey',
            opacity: '1'
          },
          '& + .MuiSwitch-track': {
            opacity: '0.4 !important',
            background: 'darkgrey',
            borderColor: 'darkgrey'
          }
        }
      }
    }
  },
  sizeSmall: {
    borderRadius: '100px',
    border: 'none',
    width: '32px',
    height: '16px',
    overflow: 'visible',
    padding: 0,

    '.MuiSwitch-track': {
      borderRadius: '100px',
      width: '28px',
      height: '16px',
      backgroundColor: '#11182733'
    },
    '.MuiSwitch-switchBase': {
      padding: '6px',
      marginTop: '-4px',
      marginLeft: '-3px',
      '.MuiSwitch-thumb': {
        width: '12px',
        height: '12px',
        boxShadow: 'none',
        background: 'paleturquoise',
        '&:hover': {
          background: '#D0D1D4'
        }
      },

      '&.Mui-disabled': {
        '.MuiSwitch-thumb': {
          borderColor: '#090E1626'
        },
        '& + .MuiSwitch-track': {
          border: '2px solid #090E1626'
        }
      }
    }
  }
};
