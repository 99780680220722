import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export function formatDate(date: string) {
  const eventDateTime = dayjs(date);
  const now = dayjs();

  if (eventDateTime.isAfter(now) && eventDateTime.isSame(now, 'day')) {
    const timeLeft = eventDateTime.fromNow(true);
    return `${timeLeft} left`;
  } else {
    return eventDateTime.format('D MMM YYYY, h:mm A');
  }
}
