import { Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { RoundParametersRes } from '@/logicLayers/domain';

import { PARAMETER_TYPE } from './helpers';

type Parameter = {
  item: RoundParametersRes;
};

export const ParameterItemMobile: React.FC<Parameter> = ({ item }) => {
  const renderParameterTitle = item.parameterDetails.title;
  const renderParameterDescription = item.parameterDetails.description;
  const renderParameterType = item.parameterDetails.type;
  const isNumeric = renderParameterType.includes('NUMERIC');

  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid rgba(41, 49, 62, 0.03)',
        borderRadius: 'var(--4, 4px)',
        padding: '16px',
        marginBottom: '24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: '#F5F7FA'
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              flexGrow: '1'
            }}
          >
            <Text
              className={'line-clamp-1 m-0'}
              sx={{
                color: 'var(--Custom-styles-Main-Black, #111827)',
                fontSize: '14px',
                fontWeight: '600',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {renderParameterTitle}
            </Text>
            <Box
              sx={{
                height: '24px',
                borderRadius: '16px',
                background: 'var(--background-neutral-subtle-default, rgba(9, 14, 22, 0.03))',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '7px 10px'
              }}
            >
              <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', margin: '0px' }}>
                {isNumeric ? 'Numeric data' : PARAMETER_TYPE[renderParameterType]}
              </Text>
              {/* {isNumeric ? (
                <Text variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)', margin: '0px 0px 0px 8px' }}>
                  {PARAMETER_TYPE[renderParameterType]}
                </Text>
              ) : null} */}
            </Box>
          </Box>
          <Box>
            <Text
              variant={'bodyMd'}
              className={'line-clamp-12'}
              sx={{
                color: 'var(--text-subtle, #505668)',
                lineHeight: '16px',
                margin: '4px 0 0 0',
                display: '-webkit-box',
                WebkitLineClamp: '12',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {renderParameterDescription}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
