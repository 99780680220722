import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import {
  EquipmentParameterRes,
  FrequencyType,
  GET_EQUIPMENT_ROUND_REGULATIONS,
  RoundRegulationsQuery
} from '@/logicLayers/domain';

import {
  AlertCheckNoticeIcon,
  DoNotDisturbOnIcon,
  Flex,
  SystemStatusChip,
  SystemStatusesEnum,
  TooltipOverflowItem
} from '@/separatedModules/components';
import { EMPTY_CELL_VALUE, skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const createStatuses = () => {
  return [
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      value: true,
      icon: <AlertCheckNoticeIcon sx={{ fontSize: 20 }} />
    },
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      value: false,
      icon: <DoNotDisturbOnIcon />
    }
  ];
};

export const ParametersTypesList = [
  {
    label: 'Daily',
    value: FrequencyType.Daily,
    checked: false,
    disabled: false
  },
  {
    label: 'Weekly',
    value: FrequencyType.Weekly,
    checked: false,
    disabled: false
  },
  {
    label: 'Monthly',
    value: FrequencyType.Monthly,
    checked: false,
    disabled: false
  },
  {
    label: 'Every 3 month',
    value: FrequencyType.EveryThirdMonth,
    checked: false,
    disabled: false
  },
  {
    label: 'Every 6 month',
    value: FrequencyType.EverySixthMonth,
    checked: false,
    disabled: false
  },
  {
    label: 'Yearly',
    value: FrequencyType.Yearly,
    checked: false,
    disabled: false
  },
  {
    label: 'Single-time round',
    value: FrequencyType.SingleTimeRound,
    checked: false,
    disabled: false
  }
];

export const columns = [
  columnHelper.accessor('title', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box component={'span'} sx={{ width: 268 }}>
          {row.original?.name ? (
            <Text component={'span'} className={'m-0'} variant={'titleSm'}>
              <TooltipOverflowItem>{row.original?.name}</TooltipOverflowItem>
            </Text>
          ) : null}

          {row.original?.description ? (
            <Text
              component={'span'}
              variant={'bodySm'}
              sx={{ margin: '4px 0 0 0 !important', color: 'var(--text-subtle, #505668)' }}
            >
              <TooltipOverflowItem>{row.original?.description}</TooltipOverflowItem>
            </Text>
          ) : (
            EMPTY_CELL_VALUE
          )}
        </Box>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'staff',
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Flex>
          <Box sx={{ marginRight: '8px' }}>
            <Flex>
              {row.original.serviceStaff.map((service, index) => (
                <>
                  {service?.userAvatar?.url ? (
                    <img
                      width={24}
                      height={24}
                      key={index}
                      src={service?.userAvatar?.url}
                      style={{
                        borderRadius: '16px',
                        border: '1px solid #F7F8F9',
                        position: 'relative',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        flex: '0 0 24px',
                        borderRadius: '50%',
                        background: 'rgb(240, 240, 241)',
                        border: '1px solid #F7F8F9',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  )}
                </>
              ))}
            </Flex>
          </Box>
          <Flex
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              margin: '4px 0 0 0 !important'
            }}
          >
            {row.original.serviceStaff.map((service, index) => {
              const isLast = index === row.original.serviceStaff.length - 1;
              const displayName =
                service?.firstName.length && service?.lastName.length
                  ? `${service.firstName} ${service.lastName}`
                  : EMPTY_CELL_VALUE;

              return (
                <Text sx={{ margin: '4px 0 0 0 !important' }} component={'span'} variant={'bodyMd'} key={index}>
                  {`${displayName}${!isLast ? ', ' : ''}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('createdDate', {
    id: 'date',
    header: (props) => (
      <Text
        className={'m-0'}
        component={'span'}
        variant={'labelMd'}
        sx={{ color: 'var(--text-subtle, #505668)', margin: '4px 0 0 0 !important' }}
      >
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.activationTargetDate
            ? dayjs(row.original.activationTargetDate).format('DD MMM YYYY, HH:mm A')
            : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'frequency',
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      let capitalizedText;

      if (!row.original?.frequency) {
        capitalizedText = 'Single-time round';
      } else {
        const formattedText = row.original.frequency.frequency.toLowerCase();
        capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
      }

      return (
        <Text component={'p'} variant={'bodySm'} sx={{ fontSize: '14px', margin: '4px 0 0 0 !important' }}>
          {capitalizedText}
        </Text>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'status',
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.parameters.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      const status = row.original.active ? SystemStatusesEnum.Enabled : SystemStatusesEnum.Disabled;

      return <SystemStatusChip status={status} />;
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDomains: [],
    sortByType: [],
    sortByTypeSelected: '',
    sortByDate: {},
    isActive: undefined,
    frequencies: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT, templateId: string | undefined) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<RoundRegulationsQuery>(GET_EQUIPMENT_ROUND_REGULATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      equipmentIds: [templateId] || undefined,
      type: 'ROUND_REGULATION',
      frequencies: state.filters.frequencies.length ? state.filters.frequencies : undefined,
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate?.key || undefined,
          direction: state.filters.sortByDate?.value || undefined
        },
        isActive: state.filters.isActive
      }
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: { key: string; value: string }) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByType = useCallback((value: string[], type: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByType: value,
          sortByTypeSelected: type
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((value: boolean | undefined) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          isActive: value
        }
      };
    });
  }, []);

  const handleChangeByFrequencies = useCallback((value: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          frequencies: value
        }
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByType,
    handleChangeByStatus,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0,
    handleChangeByFrequencies
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
