import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';

import { CREATE_WIDGET, WidgetType } from '@/logicLayers/domain';
import { AlertService } from '@/logicLayers/infrastructure/services';

import { ModalDiscardAllChanges, MultiStepFooter, MultiStepHeader } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { Steps } from './componants/Steps';
import { useContextState } from './ContextProvider';

import { DEFAULT_VALUES, FORM_ID, formatData, FormProps } from './helpers';

export const ContextConsumer = () => {
  const { t: tWidgets } = useTranslation('widgets');
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const methods = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES
  });

  const [CreateWidget] = useMutation(CREATE_WIDGET);

  const { setState, handleSetPrevStep, ...state } = useContextState();

  const handleSuccess = () => {
    AlertService.showAlert({
      title: tGlobal('alertMessages.success.widgetCreated'),
      severity: 'success'
    });

    setTimeout(() => {
      navigateTo(-1);
    }, 2500);
  };

  const handleError = () => {
    AlertService.showAlert({
      title: tGlobal('alertMessages.errors.base'),
      severity: 'error'
    });
  };

  const handleCreateWidget = (data: FormProps) => {
    const formattedData = formatData(data);

    CreateWidget({
      variables: {
        request: formattedData
      },
      onCompleted() {
        handleSuccess();
      },
      onError() {
        handleError();
      }
    });
  };

  const handleSubmitForm = methods.handleSubmit((data) => {
    if (state.currentStep < state.totalSteps) {
      const newStep = state.currentStep + 1;
      setState((state) => ({ ...state, currentStep: newStep > state.totalSteps ? state.totalSteps : newStep }));
    } else {
      handleCreateWidget(data);
    }
  });

  useEffect(() => {
    if (state.currentStep === 1) {
      methods.reset();
    }
  }, [state.currentStep]);

  const isDisabledButton = () => {
    const data = methods.watch();

    const widgetChecks = {
      [WidgetType.RESULT_OF_COMPLETED_ROUNDS]: !!data.RESULT_OF_COMPLETED_ROUNDS?.[0],
      [WidgetType.ROUND_BY_SERVICE_STAFF]: !!data.ROUND_BY_EMPLOYEE?.[0] || !!data.CUSTOM_TASK_BY_EMPLOYEE?.[0],
      [WidgetType.EQUIPMENT_IN_CRITICAL_CONDITIONS]: !!data.EQUIPMENT_IN_CRITICAL_CONDITIONS?.[0],
      [WidgetType.CALENDAR_OF_ROUND_REGULATIONS]: !!data.CALENDAR_OF_ROUND_REGULATIONS?.[0]
    };

    const values = Object.entries(state.selectedWidgetTypes)
      .filter((item) => item[1])
      .map(([key], index) => ({ key, data: widgetChecks[key as keyof typeof widgetChecks], page: index + 3 }));

    return (
      (!values.find((item) => item.page === state.currentStep)?.data &&
        state.currentStep !== 1 &&
        state.currentStep !== 2) ||
      (Object.values(state.selectedWidgetTypes).filter((item) => item).length === 0 && state.currentStep === 2)
    );
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={10} item>
        <FormProvider {...methods}>
          <form id={FORM_ID} onSubmit={handleSubmitForm}>
            <ModalDiscardAllChanges navigateToUrl={-1 as any} sxBox={{ margin: '0 0 32px' }} />
            <MultiStepHeader
              title={tWidgets('createWidgets.multiStep.title')}
              titleStep={
                state.currentStep === 1 || state.currentStep === 2
                  ? tWidgets('createWidgets.multiStep.titleStepWithoutTotal', {
                      currentStep: state.currentStep
                    })
                  : tWidgets('createWidgets.multiStep.titleStep', {
                      currentStep: state.currentStep,
                      totalSteps: state.totalSteps
                    })
              }
              description={<Text sx={{ marginBottom: '16px' }}>{tWidgets('createWidgets.multiStep.description')}</Text>}
              currentStep={state.currentStep}
              totalStep={state.totalSteps}
              sxDescription={{ maxWidth: 660 }}
            />
            <Steps step={state.currentStep} />
            <MultiStepFooter
              btnNextStepText={tGlobal('multiStep.btnNextStep')}
              btnPrevStepText={tGlobal('multiStep.btnPrevStep')}
              btnCreateTemplateText={tWidgets('createWidgets.multiStep.btnCreateWidget')}
              isLoadingBtnCreate={false}
              currentStep={state.currentStep}
              totalSteps={state.totalSteps}
              handleSetPrevStep={handleSetPrevStep}
              isDisabled={isDisabledButton()}
              isDisabledBtnCreate={isDisabledButton()}
            />
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};
