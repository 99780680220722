import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';

import { GET_REGIONS_SIDEBAR, GetRegionsSidebarQuery } from '@/logicLayers/domain';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  ModalDiscardAllChanges,
  TextBlockControl
} from '@/separatedModules/components';

import { LINKS_IMAGES, LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { ChooseFlowItem } from './components/ChooseFlowItem';

import { getBreadcrumbs, transformDataToGroupItems } from './helpers';

const FlowTypes: Record<string, string> = {
  blank: 'blank',
  template: 'template'
};

type Keys = keyof typeof FlowTypes;

interface ChooseFlowProps {
  setSelectedFlow: (type: (typeof FlowTypes)[Keys]) => void;
}

export const ChooseFlow = ({ setSelectedFlow }: ChooseFlowProps) => {
  const { t: tCompany } = useTranslation('company');
  const { folderId } = useParams<{ folderId?: string }>();

  const [selectedFlowType, setSelectedFlowType] = useState<string | null>(null);
  const [selectedFlowError, setSelectedFlowError] = useState(false);

  const { data: dataRegions } = useQuery<GetRegionsSidebarQuery>(GET_REGIONS_SIDEBAR, {
    variables: {
      pageSize: 1000,
      page: 0
    }
  });

  const handleSelectFlow = useCallback((id: string) => {
    setSelectedFlowType(id);
    setSelectedFlowError(false);
  }, []);

  const handleValidateFlow = () => {
    if (!selectedFlowType) {
      setSelectedFlowError(true);
    } else {
      setSelectedFlow(selectedFlowType);
    }
  };

  const breadcrumbsLinks = getBreadcrumbs([...transformDataToGroupItems(dataRegions?.dataItems.items || [])], folderId);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} sm={10} md={8} lg={6} item>
        <ModalDiscardAllChanges navigateToUrl={LINKS_PAGES.home} />

        <TextBlockControl title={tCompany('create.choseFlow.title')} isTitleLg sxBox={{ marginBottom: '0px' }} />

        {breadcrumbsLinks?.length ? (
          <Box sx={{ marginBottom: '24px' }}>
            <Text sx={{ marginBottom: '16px' }}>{tCompany('create.choseFlow.description')}</Text>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                padding: '20px',
                background: 'var(--background-neutral-hovered, #F7F8F9)',
                borderRadius: 'var(--4, 4px)',
                gap: '8px',
                width: 'fit-content'
              }}
            >
              <BuildingIcon />
              <Breadcrumbs
                links={breadcrumbsLinks}
                separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
                sx={{ padding: 0 }}
              />
            </Box>
          </Box>
        ) : null}

        <Grid justifyContent={'center'} container row>
          <Grid xs={12} sm={8} md={6} item>
            <ChooseFlowItem
              id={FlowTypes.blank}
              title={tCompany('create.choseFlow.blankTitle')}
              titleSub={tCompany('create.choseFlow.blankDescription')}
              imageSrc={LINKS_IMAGES.companyCreateSelectFlowBlank}
              selected={FlowTypes.blank === selectedFlowType}
              onClick={handleSelectFlow}
            />
          </Grid>

          <Grid xs={12} sm={8} md={6} item>
            <ChooseFlowItem
              id={FlowTypes.template}
              title={tCompany('create.choseFlow.templateTitle')}
              titleSub={tCompany('create.choseFlow.templateDescription')}
              imageSrc={LINKS_IMAGES.companyCreateSelectFlowTemplate}
              disabled
              selected={FlowTypes.template === selectedFlowType}
              onClick={handleSelectFlow}
            />
          </Grid>
        </Grid>

        {!selectedFlowType && selectedFlowError ? (
          <FormHelperText component={'div'} sx={{ margin: '8px 0 0 0' }} error={selectedFlowError}>
            <ErrorOutlineIcon sx={{ fontSize: '16px' }} />

            <div className={'MuiFormHelperText-root__text'}>{tCompany('create.validation.selectFLow')}</div>
          </FormHelperText>
        ) : null}

        <Button className={'create-company__content__btn'} variant={'contained'} onClick={handleValidateFlow}>
          {tCompany('create.choseFlow.btn.continue')}
        </Button>
      </Grid>
    </Grid>
  );
};
