import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_USERS, GetUsersQuery } from '@/logicLayers/domain';

import { CheckboxesDropdown } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

type FilterByRoleProps = {
  handleChange: (selected: string[]) => void;
};

export const FilterByRole = ({ handleChange }: FilterByRoleProps) => {
  const { t: tUsers } = useTranslation('users');

  const { data, loading } = useQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      pageSize: 1000
    }
  });

  const usersFilteredByRoles = useMemo(() => {
    if (!data?.dataItems.items) {
      return [];
    }
    return data?.dataItems.items.filter(
      (obj, index) => data?.dataItems.items.findIndex((item) => item?.permissionTag === obj?.permissionTag) === index
    );
  }, [data?.dataItems.items]);

  const rolesList = useMemo(() => {
    return usersFilteredByRoles?.map((user) => ({
      label: user?.permissionTag ?? '',
      key: user?.permissionTag ?? '',
      value: user?.permissionTag,
      checked: true
    }));
  }, [usersFilteredByRoles]);

  const isDisabled = !rolesList.length || loading;

  return (
    <CheckboxesDropdown
      id={'User-management-filter-by-role'}
      title={tUsers('table.filters.filterByRole.title')}
      menuList={rolesList}
      hasSelectAll
      onChange={(options) => handleChange(options.map((finalOption) => finalOption.key))}
      disabled={isDisabled}
      isRound
    />
  );
};
