import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function ImageIcon2(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#29313E', fontSize: 24, ...sx }}
      {...restProps}
    >
      <path
        d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM7.65388 16.75H16.423C16.6038 16.75 16.7377 16.6679 16.8249 16.5038C16.9121 16.3397 16.8987 16.1807 16.7845 16.0269L14.4 12.8327C14.3064 12.7122 14.1859 12.6519 14.0384 12.6519C13.891 12.6519 13.7705 12.7122 13.6769 12.8327L11.2308 16.0192L9.5923 13.9192C9.49872 13.8051 9.37981 13.7481 9.23558 13.7481C9.09134 13.7481 8.97243 13.8083 8.87885 13.9289L7.30195 16.0269C7.18143 16.1807 7.16477 16.3397 7.25195 16.5038C7.33913 16.6679 7.47311 16.75 7.65388 16.75Z"
        fillOpacity="0.3"
      />
    </SvgIcon>
  );
}
