import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function NotFoundCompanies(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      viewBox={'0 0 20 20'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, width: '80px', height: '80px', ...sx }}
      {...restProps}
    >
      <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3032_30711)">
          <path
            d="M22.6929 41.677H2.8125C1.26563 41.677 0 40.4114 0 38.8645V18.9844C0 17.4375 1.26563 16.1719 2.8125 16.1719H22.6929C24.2398 16.1719 25.5054 17.4375 25.5054 18.9844V38.8648C25.5054 40.4114 24.2398 41.677 22.6929 41.677Z"
            fill="#CCE0FF"
          />
          <path
            d="M22.6929 76.0503H2.8125C1.26563 76.0503 0 74.7847 0 73.2378V53.3574C0 51.8105 1.26563 50.5449 2.8125 50.5449H22.6929C24.2398 50.5449 25.5054 51.8105 25.5054 53.3574V73.2378C25.5054 74.7847 24.2398 76.0503 22.6929 76.0503Z"
            fill="#CCE0FF"
          />
          <path
            d="M22.6929 110.423H2.8125C1.26563 110.423 0 109.158 0 107.611V87.7305C0 86.1836 1.26563 84.918 2.8125 84.918H22.6929C24.2398 84.918 25.5054 86.1836 25.5054 87.7305V107.611C25.5054 109.158 24.2398 110.423 22.6929 110.423Z"
            fill="#CCE0FF"
          />
          <path
            d="M22.6078 37.2989H2.89758C1.30383 37.2989 0 35.9951 0 34.4014V38.7793C0 40.373 1.30383 41.6768 2.89758 41.6768H22.6078C24.2016 41.6768 25.5054 40.373 25.5054 38.7793V34.4014C25.5052 35.9951 24.2013 37.2989 22.6078 37.2989Z"
            fill="#85B8FF"
          />
          <path
            d="M22.6078 71.672H2.89758C1.30383 71.672 0 70.3682 0 68.7744V73.1523C0 74.7461 1.30383 76.0499 2.89758 76.0499H22.6078C24.2016 76.0499 25.5054 74.7461 25.5054 73.1523V68.7744C25.5052 70.3682 24.2013 71.672 22.6078 71.672Z"
            fill="#85B8FF"
          />
          <path
            d="M22.6078 106.045H2.89758C1.30383 106.045 0 104.741 0 103.147V107.525C0 109.119 1.30383 110.423 2.89758 110.423H22.6078C24.2016 110.423 25.5054 109.119 25.5054 107.525V103.147C25.5052 104.741 24.2013 106.045 22.6078 106.045Z"
            fill="#85B8FF"
          />
          <path
            d="M114.057 16.1719C57.5445 16.1719 63.4921 16.1719 40.2726 16.1719C37.0044 16.1719 34.3301 18.5262 34.3301 21.4038V36.4453C34.3301 39.3227 37.0041 41.6773 40.2726 41.6773C42.5827 41.6773 110.848 41.6773 114.057 41.6773C117.326 41.6773 120 39.323 120 36.4453V21.4036C120 18.5262 117.326 16.1719 114.057 16.1719Z"
            fill="#F1F2F4"
          />
          <path
            d="M114.057 84.918C98.7495 84.918 54.4912 84.918 40.2726 84.918C37.0044 84.918 34.3301 87.2723 34.3301 90.1499V105.191C34.3301 108.069 37.0041 110.423 40.2726 110.423C43.3994 110.423 110.939 110.423 114.057 110.423C117.326 110.423 120 108.069 120 105.191V90.1499C120 87.2723 117.326 84.918 114.057 84.918Z"
            fill="#F1F2F4"
          />
          <path
            d="M114.057 50.5449C112.082 50.5449 42.1563 50.5449 40.2726 50.5449C37.0044 50.5449 34.3301 52.8992 34.3301 55.7769V70.8184C34.3301 73.6958 37.0041 76.0503 40.2726 76.0503C43.0542 76.0503 111.098 76.0503 114.057 76.0503C117.326 76.0503 120 73.696 120 70.8184V55.7769C120 52.8992 117.326 50.5449 114.057 50.5449Z"
            fill="#F1F2F4"
          />
          <path
            d="M114.057 36.772C52.0932 36.772 68.1108 36.772 40.2726 36.772C37.0044 36.772 34.3301 34.4177 34.3301 31.54V36.4448C34.3301 39.3222 37.0041 41.6768 40.2726 41.6768C43.0201 41.6768 111.447 41.6768 114.057 41.6768C117.326 41.6768 120 39.3225 120 36.4448V31.5403C120 34.4177 117.326 36.772 114.057 36.772Z"
            fill="#DCDFE4"
          />
          <path
            d="M114.057 105.518C111.117 105.518 43.1609 105.518 40.2726 105.518C37.0044 105.518 34.3301 103.164 34.3301 100.286V105.191C34.3301 108.068 37.0041 110.423 40.2726 110.423C43.3994 110.423 110.939 110.423 114.057 110.423C117.326 110.423 120 108.069 120 105.191V100.286C120 103.164 117.326 105.518 114.057 105.518Z"
            fill="#DCDFE4"
          />
          <path
            d="M114.057 71.145C110.857 71.145 43.4636 71.145 40.2726 71.145C37.0044 71.145 34.3301 68.7907 34.3301 65.9131V70.8179C34.3301 73.6953 37.0041 76.0498 40.2726 76.0498C40.8881 76.0498 112.291 76.0498 114.057 76.0498C117.326 76.0498 120 73.6955 120 70.8179V65.9131C120 68.7907 117.326 71.145 114.057 71.145Z"
            fill="#DCDFE4"
          />
          <path
            d="M108.032 30.7353H46.2972C45.1613 30.7353 44.2402 29.9246 44.2402 28.9243C44.2402 27.924 45.161 27.1133 46.2972 27.1133H108.032C109.168 27.1133 110.089 27.924 110.089 28.9243C110.089 29.9246 109.168 30.7353 108.032 30.7353Z"
            fill="#BFC3CD"
          />
          <path
            d="M108.032 65.1084H46.2972C45.1613 65.1084 44.2402 64.2977 44.2402 63.2973C44.2402 62.297 45.161 61.4863 46.2972 61.4863H108.032C109.168 61.4863 110.089 62.297 110.089 63.2973C110.089 64.2977 109.168 65.1084 108.032 65.1084Z"
            fill="#BFC3CD"
          />
          <path
            d="M108.032 99.4814H46.2972C45.1613 99.4814 44.2402 98.6707 44.2402 97.6704C44.2402 96.6701 45.161 95.8594 46.2972 95.8594H108.032C109.168 95.8594 110.089 96.6701 110.089 97.6704C110.089 98.6707 109.168 99.4814 108.032 99.4814Z"
            fill="#BFC3CD"
          />
        </g>
        <defs>
          <clipPath id="clip0_3032_30711">
            <rect width="120" height="120" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
