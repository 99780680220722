import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSearchInput = styled(OutlinedInput)(() => ({
  height: '42px',
  borderRadius: '16px',
  border: '1px solid #090E1626',
  fontSize: '14px',
  paddingLeft: '12px',
  width: '100%',

  '.MuiSvgIcon-root': {
    color: 'inherit',
    marginLeft: '8px'
  }
}));
