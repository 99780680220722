import { FC } from 'react';

import { useContextState } from '../../ContextProvider';
import { CalendarOfRoundRegulations } from '../CalendarOfRoundRegulations';
import { EquipmentInCriticalCondition } from '../EquipmentInCriticalCondition';
import { ResultOfCompletedTasks } from '../ResultsOfCompletedTasks';
import { Step1 } from '../Step1';
import { Step2 } from '../Step2';
import { TasksByEmployee } from '../TasksByEmployee';

interface StepsProps {
  step: number;
}

const BaseSteps: { [step: string]: FC } = {
  step1: Step1,
  step2: Step2
};

const WidgetComponents: { [key: string]: FC } = {
  RESULT_OF_COMPLETED_ROUNDS: ResultOfCompletedTasks,
  ROUND_BY_SERVICE_STAFF: TasksByEmployee,
  EQUIPMENT_IN_CRITICAL_CONDITIONS: EquipmentInCriticalCondition,
  CALENDAR_OF_ROUND_REGULATIONS: CalendarOfRoundRegulations
};

export const Steps = ({ step }: StepsProps) => {
  const { selectedWidgetTypes } = useContextState();

  const enabledWidgets = Object.keys(selectedWidgetTypes)
    .filter((widgetType) => selectedWidgetTypes[widgetType])
    .map((widgetType) => WidgetComponents[widgetType]);

  const allSteps = [BaseSteps.step1, BaseSteps.step2, ...enabledWidgets];
  const CurrentStepComponent = allSteps[step - 1];

  return <CurrentStepComponent />;
};
