import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'infinitytechnologies-ui';

import { Box, IconButton } from '@mui/material';

import { RoundRegulationsQuery } from '@/logicLayers/domain';

import { MobileActions } from '@/separatedModules/pages/Tasks/pages/TaskDetails/components/MobileActions';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  EquipmentIcon,
  MoreVertIcon
} from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { getBreadcrumbs } from '../../helpers';

type MobileTaskItemProps = {
  item: RoundRegulationsQuery['dataItems']['items'][0];
  isFromEquipment: boolean;
};

export const MobileTaskItem = ({ item, isFromEquipment = false }: MobileTaskItemProps) => {
  const { t: tTemplates } = useTranslation('templates');
  const navigateTo = useNavigate();

  const [mobileActionsOpen, setMobileActionsOpen] = useState(false);

  const handleOpenMobileActions = () => {
    setMobileActionsOpen(true);
  };

  const handleCloseMobileActions = () => {
    setMobileActionsOpen(false);
  };

  const location = item?.equipment?.[0]?.location;
  const equipmentName = item?.equipment?.[0]?.name;
  const breadcrumbsLinks = getBreadcrumbs(location);

  return (
    <Box
      key={item.id}
      sx={{
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        borderRadius: '4px',
        border: '1px solid var(--background-disabled, rgba(41, 49, 62, 0.03))',
        background: 'var(--background-neutral-hovered, #F5F7FA)',
        marginTop: '16px',
        padding: '12px',
        width: '100%',
        boxSizing: 'border-box'
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{item.name}</Text>
        {(item.status === 'PENDING' || item.status === 'IN_PROGRESS') && (
          <IconButton sx={{ padding: '4px' }} onClick={handleOpenMobileActions}>
            <MoreVertIcon sx={{ width: '32px', height: '32px' }} />
          </IconButton>
        )}
      </Box>
      {breadcrumbsLinks.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'var(--background-neutral-hovered, #F7F8F9)',
            borderRadius: 'var(--4, 4px)',
            gap: '8px',
            width: 'fit-content'
          }}
        >
          <BuildingIcon sx={{ fontSize: '16px' }} />
          <Breadcrumbs
            links={breadcrumbsLinks}
            separator={<ChevronRightIcon sx={{ height: '16px', width: '16px' }} />}
            sx={{ padding: 0 }}
            fontSize="12px"
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
        <EquipmentIcon sx={{ fontSize: '16px' }} />
        <Text sx={{ fontSize: '12px' }}>{equipmentName}</Text>
      </Box>
      <Box
        sx={{
          marginTop: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <TaskStatusChip status={item.status} />
        <Button
          variant={'text'}
          size={'small'}
          sx={{
            margin: '0 0 0 auto !important',
            '&.MuiButton-text': {
              '&:not(:active, :hover)': {
                background: 'transparent'
              }
            },
            span: {
              color: '#265299'
            }
          }}
          onClick={() => {
            navigateTo(LINKS_PAGES.tasksDetails.replace(':taskId', `${item.id}`), {
              state: { isQRScanned: isFromEquipment }
            });
          }}
        >
          {tTemplates('equipmentItem.mobile.detailsBtn')}
        </Button>
      </Box>
      <MobileActions
        isOpen={mobileActionsOpen}
        onOpen={handleOpenMobileActions}
        onClose={handleCloseMobileActions}
        taskName={item.name}
        itemId={String(item.id)}
      />
    </Box>
  );
};
