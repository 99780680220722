import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, Stack } from '@mui/material';

import { TextFieldControl } from '@/separatedModules/components';

import { ROLE_DESCRIPTION_REG_EXP } from '@/utils';

const WIDGET_NAME = 'widgetName';

export const Step1 = () => {
  const { t: tGlobal } = useTranslation('global');
  const { t: tWidgets } = useTranslation('widgets');

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Stack minHeight={'95vh'}>
      <Grid justifyContent={'center'} container row mb={'77px'}>
        <Grid xs={12} sm={10} md={8} lg={7} item>
          <Controller
            name={WIDGET_NAME}
            control={control}
            rules={{
              required: {
                value: true,
                message: tGlobal('validation.required')
              },
              pattern: {
                value: ROLE_DESCRIPTION_REG_EXP,
                message: tGlobal('validation.descriptionLength', {
                  value: 100
                })
              }
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextFieldControl
                  title={tWidgets('createWidgets.step1.title')}
                  subTitle={`<ul>
                      <li>${tWidgets('createWidgets.step1.description.part1')}</li>
                      <li>${tWidgets('createWidgets.step1.description.part2')}</li>
                      </ul>`}
                  label={tWidgets('createWidgets.step1.label')}
                  placeholder={tWidgets('createWidgets.step1.placeholder')}
                  name={WIDGET_NAME}
                  value={value}
                  validation={{
                    isValid: !errors[WIDGET_NAME]?.message,
                    error: errors[WIDGET_NAME]?.message as string
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
