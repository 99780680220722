import { Checkbox } from '@mui/material';

import { useContextState, useContextUpdateState } from '@/separatedModules/pages/Locations/components/Providers';

export const TableTitleWithContext = () => {
  const { toggleAllChecked } = useContextUpdateState();
  const { allChecked } = useContextState();

  return (
    <Checkbox
      onClick={() => {
        toggleAllChecked(!allChecked);
      }}
      sx={{
        '& .MuiSvgIcon-root': {
          fill: '#326CC9'
        }
      }}
      checked={allChecked}
    />
  );
};
