import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Button, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GetNotifications,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  SUBSCRIBE_TO_NOTIFICATIONS
} from '@/logicLayers/domain';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { NotificationItem } from './components/NotificationItem';

interface NotificationsPopoverProps {
  idPopover: string | undefined;
  anchorEl: HTMLElement | null;
  isOpenPopover: boolean;
  handleClosePopover: () => void;
  setIsNewNotification: (value: boolean) => void;
}

export const NotificationsPopover = ({
  idPopover,
  anchorEl,
  isOpenPopover,
  handleClosePopover,
  setIsNewNotification
}: NotificationsPopoverProps) => {
  const { t: tGlobal } = useTranslation('global');

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: notificationsData,
    fetchMore,
    refetch
  } = useQuery<GetNotifications>(GET_NOTIFICATIONS, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 20,
          page: 0
        },
        sortable: {
          column: 'createdDate',
          direction: 'DESC'
        }
      }
    },
    fetchPolicy: 'no-cache'
  });

  const { data: newNotification } = useSubscription(SUBSCRIBE_TO_NOTIFICATIONS);

  const [markAllNotificationsAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [GET_NOTIFICATIONS]
  });

  useEffect(() => {
    refetch();
  }, [newNotification]);

  useEffect(() => {
    if (notificationsData?.notifications.items.some((notification) => !notification.read)) {
      setIsNewNotification(true);
    } else {
      setIsNewNotification(false);
    }
  }, [notificationsData]);

  const handleMarkAsRead = (id: string) => {
    markNotificationAsRead({ variables: { notificationId: id } });
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  const handleRemove = (id: string) => {
    deleteNotification({ variables: { notificationId: id } });
  };

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        searchCriteria: {
          pageable: {
            page: currentPage + 1,
            pageSize: 20
          },
          sortable: {
            column: 'createdDate',
            direction: 'DESC'
          }
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        setHasMore((currentPage + 1) * 20 < fetchMoreResult.notifications.total);
        setCurrentPage(currentPage + 1);

        return {
          notifications: {
            items: [...prev.notifications.items, ...fetchMoreResult.notifications.items],
            total: fetchMoreResult.notifications.total
          }
        };
      }
    });
  };

  return (
    <Popover
      id={idPopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={isOpenPopover}
      onClose={handleClosePopover}
    >
      <Box
        sx={{ padding: '4px 0', margin: '0 0 8px', width: '450px', height: '600px', overflowY: 'scroll' }}
        id="scrollableDiv"
      >
        <Box
          sx={{
            height: '56px',
            padding: '12px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(41, 49, 62, 0.15)',
            position: 'sticky',
            top: '-4px',
            zIndex: '1',
            background: '#FFFFFF',
            marginTop: '-8px'
          }}
        >
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}>{tGlobal('notifications.title')}</Text>
          <Button variant={'text'} sx={{ padding: '0', color: '#265299 !important' }} onClick={handleMarkAllAsRead}>
            {tGlobal('notifications.btnMarkAllAsRead')}
          </Button>
        </Box>
        <Box>
          {notificationsData?.notifications.items.length ? (
            <Box sx={{ height: '100%' }}>
              <InfiniteScroll
                dataLength={notificationsData?.notifications?.items.length ?? 0}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={null}
                scrollableTarget={'scrollableDiv'}
              >
                <>
                  {notificationsData?.notifications.items.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      handleMarkAsRead={handleMarkAsRead}
                      handleRemove={handleRemove}
                    />
                  ))}
                </>
              </InfiniteScroll>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '544px'
              }}
            >
              <img src={LINKS_IMAGES.notFoundSearch} alt="No notifications" />
              <Text sx={{ fontSize: '16px', fontWeight: '600', marginTop: '32px' }}>
                {tGlobal('notifications.noNotifications')}
              </Text>
              <Text sx={{ fontSize: '14px', fontWeight: '400', marginTop: '16px' }}>
                {tGlobal('notifications.noNotificationsDescription')}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
