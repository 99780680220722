import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Text } from 'infinitytechnologies-ui';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import {
  GET_ACCESS_TYPE,
  GET_COMPANY,
  GetCompanyQuery,
  SWITCH_COMPANY_STATE,
  UPDATE_COMPANY,
  updateCompanyModel
} from '@/logicLayers/domain';

import { COMPANY_ITEM_FIELDS } from '@/separatedModules/pages/Company';

import {
  Alert,
  BlockInfoWithEdit,
  BlockInfoWithEditItem,
  EditIcon,
  ImageUploader,
  PageTitle,
  Preloader,
  PreloaderVariantsE
} from '@/separatedModules/components';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { Modals as CompanySettingsModals } from './components/Modals';

export const CompanySettingsPage = () => {
  const { t: tCompany } = useTranslation('company');
  const { companyId = '' } = useParams();
  const navigateTo = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    loading: loadingCompany,
    error: errorCompany,
    data: dataCompany
  } = useQuery<GetCompanyQuery>(GET_COMPANY, { fetchPolicy: 'no-cache', variables: { companyId } });
  const [SwitchCompanyState, { loading: loadingSwitchCompanyState, error: errorSwitchCompanyState }] =
    useMutation(SWITCH_COMPANY_STATE);
  const [UpdateCompany, { loading: loadingUpdateCompany, error: errorUpdateCompany }] = useMutation(UPDATE_COMPANY);

  const { data: dataPermission } = useQuery(GET_ACCESS_TYPE, {
    variables: {
      resourceId: companyId
    }
  });

  const isDisabled = !dataCompany?.company?.active;
  const isHasLogo = dataCompany?.company?.logo?.url || '';

  const handleEditItem = (toPage: string) => {
    navigateTo({ pathname: LINKS_PAGES.company + '/' + companyId + '/edit', search: `field=${toPage}` });
  };

  const handleDisableCompany = () => {
    SwitchCompanyState({
      variables: {
        companyId,
        isActive: isDisabled
      },
      refetchQueries: [GET_COMPANY]
    });

    setIsModalOpen(false);
  };

  const handleClickButton = () => {
    setIsModalOpen(true);
  };

  const handleImageUploadOrRemove = (imageKey: string) => {
    if (dataCompany) {
      UpdateCompany({
        variables: {
          id: companyId,
          ...updateCompanyModel(dataCompany, { fileKey: imageKey })
        },
        refetchQueries: [GET_COMPANY]
      });
    }
  };

  useEffect(() => {
    // ToDo Add workflow for error
    if (errorCompany) console.log('CompanySettingsPage errorCompany - ', errorCompany);
    // ToDo Add workflow for error
    if (errorSwitchCompanyState) console.log('CompanySettingsPage errorSwitchCompanyState - ', errorSwitchCompanyState);
    // ToDo Add workflow for error
    if (errorUpdateCompany) console.log('CompanySettingsPage errorUpdateCompany - ', errorSwitchCompanyState);
  }, [errorCompany, errorSwitchCompanyState]);

  const isLoading = loadingCompany || loadingSwitchCompanyState || loadingUpdateCompany;
  const noPermissions = dataPermission?.accessTypeForResource !== 'CREATE_UPDATE';

  // ToDo Remove
  // console.log('CompanySettingsPage dataCompany - ', dataCompany);

  return isLoading ? (
    <Preloader variant={PreloaderVariantsE.COMPANY_CIRCLE} isContainer />
  ) : (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} md={10} xl={8} item>
        <PageTitle title={tCompany('settings.pageTitle')} subTitle={tCompany('settings.pageSubTitle')} />

        {isDisabled ? (
          <Alert
            severity={'info'}
            title={tCompany('settings.alert.title')}
            content={tCompany('settings.alert.content')}
          />
        ) : null}

        <BlockInfoWithEdit disabled={isDisabled}>
          <BlockInfoWithEditItem
            title={tCompany('settings.mainInfo.title')}
            label={tCompany('settings.mainInfo.companyLogo')}
            Edit={
              <ImageUploader
                path={'COMPANY_LOGO'}
                imgAlt={`${dataCompany?.company?.name} logo`}
                oldImageUrl={isHasLogo}
                oldImageFileKey={dataCompany?.company?.logo?.fileKey || ''}
                modalTitle={tCompany('settings.modal.imageUploader.title')}
                modalTitleToDelete={tCompany('settings.modal.imageUploader.titleToDelete')}
                modalSubTitle={tCompany('settings.modal.imageUploader.subTitle')}
                btnUploadPhotoText={tCompany('settings.modal.imageUploader.uploaderButtonContent')}
                allowedExtensionsText={''}
                minDimensionsText={'72px x 72px'}
                maxDimensionsText={''}
                maxFileSizeText={'5'}
                imageMinWidth={72}
                imageMinHeight={72}
                imageMaxSize={5242880} // Bites
                imageAllowedExtensions={['png', 'jpeg', 'jpg', 'svg']}
                imageRequired
                isDisabled={noPermissions}
                titleContent={
                  <Text
                    className={'m-0'}
                    variant={'bodyMd'}
                    sx={{ color: 'var(--text-subtle, #505668)', textAlign: 'center' }}
                  >
                    {tCompany('settings.modal.imageUploader.allowedExtensionsText')}{' '}
                    {tCompany('settings.modal.imageUploader.titleContent')}
                  </Text>
                }
                modalContent={
                  dataCompany?.company?.name ? (
                    <Avatar
                      size={'136'}
                      sx={{ width: '180px', height: '180px', margin: '0 auto 24px', pointerEvents: 'none' }}
                    >
                      {dataCompany?.company?.name.slice(0, 2)}
                    </Avatar>
                  ) : null
                }
                onImageUploadSuccess={handleImageUploadOrRemove}
                onImageDelete={() => {
                  handleImageUploadOrRemove('');
                }}
                onImageUploadError={() => {
                  // ToDo Remove
                  console.log('ImageUploader handleSaveImage uploadedResult error');
                }}
              />
            }
          />

          <BlockInfoWithEditItem
            label={tCompany('settings.mainInfo.companyName')}
            description={!dataCompany?.company.name && tCompany('settings.pleaseProvideValue')}
            descriptionSecond={dataCompany?.company.name}
            Edit={
              noPermissions ? null : (
                <IconButton
                  onClick={() => {
                    handleEditItem(COMPANY_ITEM_FIELDS.NAME);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          />

          <BlockInfoWithEditItem
            label={tCompany('settings.mainInfo.companyAddress')}
            description={!dataCompany?.company.address && tCompany('settings.mainInfo.provideCompanyAddress')}
            descriptionSecond={dataCompany?.company.address}
            Edit={
              noPermissions ? null : (
                <IconButton
                  onClick={() => {
                    handleEditItem(COMPANY_ITEM_FIELDS.ADDRESS);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit disabled={isDisabled}>
          <BlockInfoWithEditItem
            title={tCompany('settings.contactInfo.title')}
            label={tCompany('settings.contactInfo.companyRepresentative')}
            description={
              !dataCompany?.company.contactInfo.representative &&
              tCompany('settings.contactInfo.provideRepresentativeName')
            }
            descriptionSecond={dataCompany?.company.contactInfo.representative}
            Edit={
              noPermissions ? null : (
                <IconButton
                  onClick={() => {
                    handleEditItem(COMPANY_ITEM_FIELDS.REPRESENTATIVE);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          />

          <BlockInfoWithEditItem
            label={tCompany('settings.contactInfo.companyPhoneNumber')}
            description={
              !dataCompany?.company.contactInfo.phoneNumber &&
              tCompany('settings.contactInfo.provideRepresentativeNumber')
            }
            descriptionSecond={dataCompany?.company.contactInfo.phoneNumber}
            Edit={
              noPermissions ? null : (
                <IconButton
                  onClick={() => {
                    handleEditItem(COMPANY_ITEM_FIELDS.PHONE_NUMBER);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          />

          <BlockInfoWithEditItem
            label={tCompany('settings.contactInfo.companyPhoneEmail')}
            description={
              !dataCompany?.company.contactInfo.email && tCompany('settings.contactInfo.provideRepresentativeEmail')
            }
            descriptionSecond={dataCompany?.company.contactInfo.email}
            Edit={
              noPermissions ? null : (
                <IconButton
                  onClick={() => {
                    handleEditItem(COMPANY_ITEM_FIELDS.EMAIL);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )
            }
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit disabled={isDisabled}>
          <BlockInfoWithEditItem
            title={tCompany('settings.workspacePreferences.title')}
            label={tCompany('settings.workspacePreferences.companySystemLanguage')}
            descriptionSecond={'English'}
          />
        </BlockInfoWithEdit>

        <BlockInfoWithEdit>
          <BlockInfoWithEditItem
            title={tCompany('settings.companyStatus.title')}
            label={isDisabled ? tCompany('settings.btn.disabled') : tCompany('settings.btn.enabled')}
            description={
              isDisabled
                ? tCompany('settings.companyStatus.descriptionIsHidden')
                : tCompany('settings.companyStatus.descriptionIsVisible')
            }
            Edit={
              noPermissions ? null : (
                <Button sx={{ margin: '0 !important', whiteSpace: 'nowrap' }} onClick={handleClickButton}>
                  {isDisabled ? tCompany('settings.btn.disabled') : tCompany('settings.btn.enabled')}
                </Button>
              )
            }
          />
        </BlockInfoWithEdit>

        <CompanySettingsModals
          companyName={dataCompany?.company.name ?? ''}
          isModalOpen={isModalOpen}
          isDisabled={isDisabled}
          setIsModalOpen={setIsModalOpen}
          handleDisableCompany={handleDisableCompany}
        />
      </Grid>
    </Grid>
  );
};
