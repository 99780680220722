import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { Box, SxProps, Theme } from '@mui/material';

import { AlertService } from '@/logicLayers/infrastructure/services';

import { ButtonLoading, TextBlockControl } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { EditContactUsers } from '../EditContactUsers';

export interface EditDateAndTimeProps {
  editFieldName: string;
  editFieldValue: string[] | [];
  navigateToUrl: string;
  mutationQuery: TypedDocumentNode<any, any>;
  successAlertMessage: string;
  errorAlertMessage: string;
  requiredMessage: string;
  pageTitle: string;
  pageSubTitle?: string;
  submitBtnText: string;
  ContentAfterTitle?: ReactNode;
  sxTitle?: SxProps<Theme>;
  onSubmitForm: (getValues: any, updateModel: any) => void;
}

const styles = [
  'color: #090E16',
  'background: #E6F6EF',
  'font-size: 10px',
  'border: 1px solid #06845B',
  'padding: 2px'
].join(';');

export const EditEmergencyContact = ({
  editFieldName,
  editFieldValue,
  navigateToUrl,
  mutationQuery,
  successAlertMessage,
  errorAlertMessage,
  pageTitle,
  pageSubTitle,
  submitBtnText,
  ContentAfterTitle,
  onSubmitForm,
  sxTitle
}: EditDateAndTimeProps) => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();

  const [updatedData, { loading, data, error }] = useMutation(mutationQuery);

  const { getValues, control, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      [editFieldName]: editFieldValue
    }
  });

  const handleSubmitForm = handleSubmit(() => {
    onSubmitForm?.(getValues, updatedData);
  });

  useEffect(() => {
    if (data) {
      AlertService.showAlert({
        title: successAlertMessage || tGlobal('alertMessages.success.edits'),
        severity: 'success'
      });

      setTimeout(() => {
        navigateTo(navigateToUrl);
      }, 2500);
    }

    if (error) {
      AlertService.showAlert({
        title: errorAlertMessage || tGlobal('alertMessages.errors.base'),
        severity: 'error'
      });
    }
  }, [data, error]);

  const SUBMIT_BTN_TEXT = submitBtnText || tGlobal('editPage.btnSave');

  // ToDo Remove
  console.log('%c' + `editFieldName, editFieldValue - ${editFieldName}, ${editFieldValue}`, styles);

  return (
    <form onSubmit={handleSubmitForm} style={{ marginBottom: '72px' }}>
      <TextBlockControl
        title={pageTitle}
        subTitle={pageSubTitle}
        ContentAfterTitle={ContentAfterTitle}
        isTitleLg
        isBodyLg
        sxTitle={sxTitle}
      />

      <Controller
        name={editFieldName}
        control={control}
        render={({ field }) => {
          const { onChange, value } = field;

          return <EditContactUsers onChange={onChange} value={value} />;
        }}
      />

      <Box
        sx={{
          background: 'var(--background-neutral-default, #FFF)',
          bottom: '0',
          right: '0',
          left: '0',
          borderTop: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
          position: 'fixed',
          zIndex: 5,
          padding: '24px 205px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <ButtonLoading variant={'contained'} type={'submit'} loading={loading} disabled={Boolean(data)}>
            {SUBMIT_BTN_TEXT}
          </ButtonLoading>
        </Box>
      </Box>
    </form>
  );
};
