import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Text } from 'infinitytechnologies-ui';

import { AuthService } from '@/logicLayers/infrastructure/services';

import { CancelIcon, Flex } from '@/separatedModules/components';

import { useQueryData } from '../Templates/pages/Equipment/pages/EquipmentItem/helpers';

export const AccessErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { equipmentId = '' } = useParams();

  const isErrorScannedPage = location.pathname.includes('scanned');

  const { dataEquipmentTemplate } = useQueryData({ templateId: equipmentId, skip: !isErrorScannedPage });

  const handleLogOut = useCallback(() => {
    if (isErrorScannedPage) {
      return navigate('/');
    }

    AuthService.handleLogOut();
  }, []);

  return (
    <Flex
      sx={{
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          textAlign: 'center',
          maxWidth: '268px'
        }}
      >
        <Flex alignItems="center" padding="0px 12px">
          <CancelIcon sx={{ fill: '#265299', width: '28px', height: '28px' }} />
          <Text sx={{ color: '#265299', fontSize: '24px', fontWeight: 600, marginLeft: '4px' }}>Access restricted</Text>
        </Flex>
        <Text sx={{ fontWeight: 500, color: '#505864', marginTop: '16px' }}>
          {!isErrorScannedPage
            ? 'You do not have permission to equipments. Please contact your system administrator to gain access'
            : `This task does not apply to this equipment. Please scan the equipment "${dataEquipmentTemplate?.equipment.name}"`}
        </Text>
        <Text sx={{ fontWeight: 500, color: '#265299', marginTop: '50px' }} onClick={handleLogOut}>
          {isErrorScannedPage ? 'Back to home' : 'Log out'}
        </Text>
      </Flex>
    </Flex>
  );
};
