import { useLocation, useParams } from 'react-router-dom';

import { Sidebar, SideBarGroup } from '@/separatedModules/containers';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { SideBarTasks } from './components/SideBarTasks';
import {
  SideBarCompanies,
  SideBarCompany,
  SideBarLocations,
  SideBarProfile,
  SideBarRoleTemplateDetails,
  SideBarTemplates,
  SideBarTemplatesEquipment,
  SideBarUserProfile,
  SideBarUsers
} from './components';

const templatesSidebarRoutes = [
  LINKS_PAGES.templatesEquipment,
  LINKS_PAGES.templatesRoles,
  LINKS_PAGES.templatesReports,
  LINKS_PAGES.templatesDomains,
  LINKS_PAGES.templatesRecent,
  LINKS_PAGES.templatesStarred,
  LINKS_PAGES.templatesArchive
];

export const SideBarProvider = () => {
  const location = useLocation();
  const { companyId = '', userId, templateId } = useParams();

  if (location.pathname.includes('/role') && !location.pathname.includes('/templates')) {
    return null;
  }

  if (location.pathname.includes('/widgets')) {
    return null;
  }

  switch (true) {
    case location.pathname.includes(LINKS_PAGES.profile): {
      return <SideBarProfile />;
    }
    case location.pathname.includes(LINKS_PAGES.company) && !!companyId: {
      return <SideBarCompany companyId={companyId} />;
    }
    case location.pathname.includes(LINKS_PAGES.users): {
      return <SideBarUsers />;
    }
    case location.pathname.includes(LINKS_PAGES.user): {
      return <SideBarUserProfile userId={userId} />;
    }
    case location.pathname.includes(LINKS_PAGES.companies): {
      return <SideBarCompanies />;
    }
    case templateId &&
      (location.pathname.includes(LINKS_PAGES.templatesEquipmentItem.replace(':templateId', '')) ||
        location.pathname.includes(LINKS_PAGES.equipmentModel.replace(':templateId', ''))): {
      return <SideBarTemplatesEquipment templateId={templateId} />;
    }
    case location.pathname.includes(LINKS_PAGES.locations): {
      return <SideBarLocations />;
    }
    case templatesSidebarRoutes.some((route) => route === location.pathname): {
      return <SideBarTemplates />;
    }
    case templateId &&
      (location.pathname.includes(LINKS_PAGES.templatesRolesRoleDetails.replace(':templateId', templateId)) ||
        location.pathname.includes(LINKS_PAGES.templatesRolesRolePermissions.replace(':templateId', templateId))): {
      return <SideBarRoleTemplateDetails />;
    }
    case location.pathname.includes(LINKS_PAGES.tasks): {
      return <SideBarTasks />;
    }
    default: {
      return (
        <Sidebar>
          <SideBarGroup>Other Sidebar</SideBarGroup>
        </Sidebar>
      );
    }
  }
};
