import { MouseEvent, useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { GET_ACCESS_TYPE } from '@/logicLayers/domain';

import { ArchiveIcon, DriveFileMoveIcon, EditIcon, MoreVertIcon, StarOutlineIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { useContextState, useContextUpdateState } from '../../../Providers';

export const RowActionMenu = ({ row }: { row: any }) => {
  const { t: tGlobal } = useTranslation('global');
  const { handleSelectRow } = useContextUpdateState();
  const contextState = useContextState();

  const { data: dataPermission } = useQuery(GET_ACCESS_TYPE, {
    variables: {
      resourceId: row.id
    }
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleSelectRow(row?.id || '');
  };

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickBox = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOpenMoveModal = () => {
    contextState.setState((state: any) => ({ ...state, isOpenMoveModal: !state.isOpenMoveModal }));
  };

  return dataPermission?.accessTypeForResource === 'CREATE_UPDATE' ? (
    <Box
      component={'td'}
      className={'table-edit-button'}
      sx={{
        display: 'block',
        '.MuiIconButton-root': {
          visibility: row?.isTableVariant ? 'hidden' : 'visible'
        }
      }}
      onClick={handleClickBox}
    >
      <IconButton size={'small'} sx={{ visibility: open ? 'visible' : 'hidden' }} onClick={handleClickButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={'equipment-templates-item-menu'}
        elevation={0}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'equipment-templates-item-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          margin: '12px 0 0 0',
          '.MuiMenu-paper': {
            width: '200px',
            minWidth: '200px'
          }
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <StarOutlineIcon sx={{ fontSize: 20 }} />

          {tGlobal('btn.btnAddToFavorites')}
        </MenuItem>

        <MenuItem disabled>
          <EditIcon sx={{ fontSize: 20 }} />

          {tGlobal('btn.btnEdit')}
        </MenuItem>

        <MenuItem onClick={() => handleOpenMoveModal()}>
          <DriveFileMoveIcon sx={{ fontSize: 20 }} />
          {tGlobal('btn.btnMove')}
        </MenuItem>

        <Box
          sx={{
            height: '1px',
            background: 'var(--border-default, rgba(9, 14, 22, 0.15))'
          }}
        />

        <MenuItem disabled>
          <ArchiveIcon sx={{ fontSize: 20 }} />

          {row?.archived ? tGlobal('btn.btnUnArchive') : tGlobal('btn.btnArchive')}
        </MenuItem>
      </Menu>
    </Box>
  ) : null;
};
