import { useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { TablePageTopBox } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { CustomTasks } from './components/CustomTasks/CustomTasks';
import { Rounds } from './components/Rounds/Rounds';

export const TasksByEmployee = () => {
  const { t: tWidgets } = useTranslation('widgets');

  const [tabIndex, setTabIndex] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newIndex: string) => {
    setTabIndex(newIndex);
  };

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item sx={{ marginTop: '20px' }}>
        <TablePageTopBox title={tWidgets('createWidgets.tasksByEmployee.title')} isHideButton={true} />
        <Text
          sx={{
            maxWidth: '664px',
            fontSize: '14px',
            color: 'var(--text-subtle, #505668)',
            fontWeight: '400',
            marginBottom: '24px'
          }}
        >
          {tWidgets('createWidgets.tasksByEmployee.subTitle')}
        </Text>
        <TabContext value={tabIndex}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab
              label="One-time tasks"
              value="1"
              sx={{
                fontSize: '16px',
                backgroundColor: '#FFFFFF',
                textTransform: 'none',
                color: '#505864',
                '&.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: '#121B2A'
                }
              }}
            />
            <Tab
              label="Custom tasks"
              value="2"
              sx={{
                fontSize: '16px',
                backgroundColor: '#FFFFFF',
                textTransform: 'none',
                color: '#505864',
                '&.Mui-selected': {
                  backgroundColor: '#FFFFFF',
                  textTransform: 'none',
                  color: '#121B2A'
                }
              }}
            />
          </Tabs>
          <TabPanel value="1">
            <Rounds />
          </TabPanel>
          <TabPanel value="2">
            <CustomTasks />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
