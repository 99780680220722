import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import classNames from 'classnames';
import { Button, TextField } from 'infinitytechnologies-ui';

import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

import { ApiService, AuthContext } from '@/logicLayers/infrastructure/services';

import { EMAIL_REG_EXP } from '@/utils';

import { CLIENT_ID_NAME, CLIENT_SECRET_NAME } from '@/subsidiaryBinders/constants';

import { useTranslation } from '@/i18n';

import { SignInLeftPart, TextBlockStep } from './..';

const FIELD_EMAIL = 'email';

// https://www.dhiwise.com/post/how-to-use-react-oidc-in-modern-authentication
export const SignIn = () => {
  const theme = useContext(AuthContext);

  const auth = useAuth();
  const { t: tGlobal } = useTranslation('global');
  const authTheme = useTheme();
  const isSmallScreen = useMediaQuery(authTheme.breakpoints.down('sm'));

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      [`${FIELD_EMAIL}`]: ''
    }
  });

  const handleSubmitForm = handleSubmit(async (fields) => {
    if (!fields.email) return;

    const { data }: { data: any | undefined } = await ApiService.Auth.getAuthClientSecret(fields.email);

    if (data?.availableCompany?.[0]) {
      const authConfig = data?.availableCompany?.[0];

      if (authConfig.clientId && authConfig.secret) {
        localStorage.setItem(CLIENT_ID_NAME, authConfig.clientId);
        localStorage.setItem(CLIENT_SECRET_NAME, authConfig.secret);

        theme.handleSetClientId({
          client_id: authConfig.clientId,
          client_secret: authConfig.secret,
          email: fields.email
        });
      } else {
        localStorage.removeItem(CLIENT_ID_NAME);
        localStorage.removeItem(CLIENT_SECRET_NAME);
      }
    }
  });

  useEffect(() => {
    // @ts-ignore
    if (auth.settings.client_id && theme.state.email) {
      auth.signinRedirect({
        extraQueryParams: {
          // @ts-ignore
          email: theme.state.email
        }
      });
    }
  });

  return (
    <main className={'sign-in'}>
      <div className={'sign-in__bg'}>
        <Grid container alignContent={'center'} justifyContent={'center'}>
          <Grid justifyContent={'center'} alignItems={'center'} container row>
            <Grid xs={12} sm={10} md={7} item sx={{ padding: !isSmallScreen ? '0px 16px' : '0' }}>
              <SignInLeftPart />

              <div className={'sign-in__right'}>
                <form id={'FORM_ID'} onSubmit={handleSubmitForm}>
                  <div className={classNames('sign-in__step', 'sign-in__step-1')}>
                    <div className={'sign-in__step__top'}>
                      <TextBlockStep title={tGlobal('signIn.pageTitle')} isMobile={isSmallScreen} />
                    </div>

                    <div className={'sign-in__form'}>
                      <Controller
                        name={FIELD_EMAIL}
                        control={control}
                        rules={{
                          pattern: {
                            value: EMAIL_REG_EXP,
                            message: tGlobal('validation.emailValid')
                          }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <TextField
                              name={FIELD_EMAIL}
                              label={tGlobal('signIn.inputEmailLabel')}
                              value={value}
                              validation={{
                                isValid: !errors[FIELD_EMAIL]?.message,
                                error: errors[FIELD_EMAIL]?.message
                              }}
                              onChange={onChange}
                              onBlur={onBlur}
                              sx={{
                                marginBottom: isSmallScreen ? '48px' : '0px'
                              }}
                            />
                          );
                        }}
                      />

                      <Button
                        variant={'contained'}
                        size={'medium'}
                        className={!isSmallScreen ? 'sign-in__form__btn' : 'm-0'}
                        type={'submit'}
                        sx={isSmallScreen ? { width: '100%', height: '48px' } : {}}
                      >
                        {tGlobal('signIn.btnContinue')}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};
