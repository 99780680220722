import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { GET_LOCATIONS_LIST, GetLocationsList } from '@/logicLayers/domain';

import { CheckboxesDropdown } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

type FilterByServiceStuffProps = {
  handleChange: (selected: string[]) => void;
};

export const FilterByLocation = ({ handleChange }: FilterByServiceStuffProps) => {
  const { t: tLocations } = useTranslation('locations');

  const { data, loading } = useQuery<GetLocationsList>(GET_LOCATIONS_LIST, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      },
      includeHierarchy: false
    }
  });

  const locationsList = useMemo(() => {
    if (!data?.locationFolders.items) {
      return [];
    }

    return data?.locationFolders.items.map((location) => {
      const locationLabelNames = location.parentsLocationFolders
        .map((parent) => parent.name)
        .reverse()
        .join(' / ');
      const locationLabel = locationLabelNames.length ? locationLabelNames.concat(' / ') : locationLabelNames;

      return {
        label: `${locationLabel} ${location.name}`,
        key: location?.id ?? '',
        value: location?.id,
        checked: true
      };
    });
  }, [data]);

  const isDisabled = !locationsList?.length || loading;

  return (
    <CheckboxesDropdown
      id={'User-management-filter-by-role'}
      title={tLocations('table.filters.filterByLocations.title')}
      menuList={locationsList}
      hasSelectAll
      onChange={(options) => handleChange(options.map((finalOption) => finalOption.key))}
      disabled={isDisabled}
      hasSearch
      searchPlaceholder={tLocations('table.filters.filterByLocations.searchPlaceholder')}
      isRound
    />
  );
};
