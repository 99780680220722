import { useState } from 'react';
import { Text } from 'infinitytechnologies-ui';

import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';

import { RoundParametersRes } from '@/logicLayers/domain';

import { ParameterTypeBox } from '@/separatedModules/pages';

import {
  CloseIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  ImageLazy,
  NoteIcon,
  PhotoIcon
} from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';

import { useTranslation } from '@/i18n';

import { PARAMETER_TYPE, PARAMETER_TYPE_LIST } from '../../helpers';
import { checkIsFormulaOrFixed } from '../../helpers';
import { conditionTypes } from './helpers';

interface Parameter {
  item: RoundParametersRes;
  isAccomplished?: boolean;
}

export const ParameterItem = ({ item, isAccomplished = false }: Parameter) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t: tTemplates } = useTranslation('templates');

  const imageUrl = PARAMETER_TYPE_LIST[item.parameterDetails.type];
  const renderParameterTitle = item.parameterDetails.title;
  const renderParameterDescription = item.parameterDetails.description;
  const renderParameterType = item.parameterDetails.type;
  const isNumeric = renderParameterType.includes('NUMERIC');
  const uploadPhoto = item.uploadPhoto;
  const addNote = item.addNote;
  const conditionType = item.condition?.type;
  const conditionMinValue = item.condition?.minValue;
  const conditionMaxValue = item.condition?.maxValue;
  const conditionExactValue = item.condition?.exactValue;
  const conditionResultValue = item.parameterResult?.value;
  const conditionResultNote = item.parameterResult?.note;
  const conditionResultImage = item.parameterResult?.photoUrl;
  const conditionResultCancel = item.parameterResult?.cancelReason;

  const [dialogImageUrl, setDialogImageUrl] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = (url: string) => {
    setDialogImageUrl(url);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogImageUrl('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        border: '1px solid var(--border-default, rgba(9, 14, 22, 0.15))',
        borderRadius: 'var(--4, 4px)',
        padding: '20px',
        marginBottom: '24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '48px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '16px'
            }}
          >
            <ImageLazy
              src={imageUrl}
              alt={'parameter-type'}
              variant={'rectangular'}
              width={48}
              height={48}
              sxStyle={{
                margin: '0px',
                display: 'flex',
                alignItems: 'center'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              height: '48px'
            }}
          >
            <Text
              variant={'labelMd'}
              className={'line-clamp-1 m-0'}
              sx={{
                color: 'var(--Custom-styles-Main-Black, #111827)',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {renderParameterTitle}
            </Text>
            <Text
              variant={'bodyMd'}
              className={'line-clamp-1'}
              sx={{
                color: 'var(--text-subtle, #505668)',
                lineHeight: '16px',
                margin: '4px 0 0 0',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {renderParameterDescription}
            </Text>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', height: '48px', minWidth: '224px' }}>
            <ParameterTypeBox title={isNumeric ? 'Numeric data' : PARAMETER_TYPE[renderParameterType]} />

            {isNumeric ? <ParameterTypeBox title={PARAMETER_TYPE[renderParameterType]} /> : null}
          </Box>
          <Box sx={{ cursor: 'pointer', marginLeft: '18px' }} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <ExpandMoreIcon sx={{ fontSize: '22px' }} /> : <ExpandLessIcon sx={{ fontSize: '22px' }} />}
          </Box>
        </Box>
      </Box>
      {isOpen && (
        <Box sx={{ width: '100%', marginTop: '20px' }}>
          <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', margin: '8px 0' }} />
          <Box sx={{ marginTop: '20px' }}>
            <Text variant={'bodyMd'} sx={{ fontWeight: '500' }}>
              {tTemplates('equipmentItem.roundRegulationsDetails.features')}
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                {tTemplates('equipmentItem.roundRegulationsDetails.visibility')}
              </Text>
              {checkIsFormulaOrFixed(renderParameterType)}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                {tTemplates('equipmentItem.roundRegulationsDetails.photo')}
              </Text>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PhotoIcon />
                <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                  {uploadPhoto ? 'Yes' : 'No'}
                </Text>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                {tTemplates('equipmentItem.roundRegulationsDetails.note')}
              </Text>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <NoteIcon />
                <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                  {addNote ? 'Yes' : 'No'}
                </Text>
              </Box>
            </Box>
          </Box>
          {conditionType && (
            <Box sx={{ marginTop: '20px' }}>
              <Text variant={'bodyMd'} sx={{ fontWeight: '500' }}>
                {tTemplates('equipmentItem.roundRegulationsDetails.validationConditions')}
              </Text>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                    {tTemplates('equipmentItem.roundRegulationsDetails.conditionType')}
                  </Text>
                  <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                    {conditionTypes[conditionType as keyof typeof conditionTypes]}
                  </Text>
                </Box>
                {conditionExactValue ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                    <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.value')}
                    </Text>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                        {conditionExactValue === 'true'
                          ? tTemplates('equipmentItem.roundRegulationsDetails.true')
                          : conditionExactValue === 'false'
                            ? tTemplates('equipmentItem.roundRegulationsDetails.false')
                            : conditionExactValue}
                      </Text>
                    </Box>
                  </Box>
                ) : conditionMinValue ? (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                      <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                        {tTemplates('equipmentItem.roundRegulationsDetails.minValue')}
                      </Text>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                          {conditionMinValue}
                        </Text>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                      <Text variant={'bodyMd'} sx={{ width: '160px' }} m={0}>
                        {tTemplates('equipmentItem.roundRegulationsDetails.maxValue')}
                      </Text>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                          {conditionMaxValue}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}
          {isAccomplished && (
            <>
              <Divider sx={{ background: 'rgba(9, 14, 22, 0.15))', marginTop: '20px' }} />
              <Box sx={{ marginTop: '20px' }}>
                <Text variant={'bodyMd'} sx={{ fontWeight: '500' }}>
                  {tTemplates('equipmentItem.roundRegulationsDetails.parameterOutcome')}
                </Text>
                {conditionResultValue && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Text variant={'bodyMd'} sx={{ width: '160px', color: '#505864' }} m={0}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.parameterValue')}
                    </Text>
                    <Text variant={'bodyMd'} m={0} sx={{ marginLeft: '8px', fontSize: '16px' }}>
                      {conditionResultValue === 'true'
                        ? tTemplates('equipmentItem.roundRegulationsDetails.true')
                        : conditionResultValue === 'false'
                          ? tTemplates('equipmentItem.roundRegulationsDetails.false')
                          : conditionResultValue}
                    </Text>
                  </Box>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
                  <Text variant={'bodyMd'} sx={{ width: '160px', color: '#505864' }} m={0}>
                    {tTemplates('equipmentItem.roundRegulationsDetails.status')}
                  </Text>
                  <TaskStatusChip status={item.parameterResult.status} />
                </Box>
                {conditionResultImage?.fileKey && conditionResultImage.url && (
                  <Box sx={{ display: 'flex', marginTop: '13px' }}>
                    <Text variant={'bodyMd'} sx={{ width: '160px', color: '#505864' }} m={0}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.attachedPhoto')}
                    </Text>
                    <Box
                      sx={{
                        cursor: 'pointer'
                      }}
                      onClick={() => handleOpenDialog(conditionResultImage.url || '')}
                    >
                      <ImageLazy
                        src={conditionResultImage.url}
                        alt={conditionResultImage.fileKey}
                        variant={'rectangular'}
                        width={136}
                        height={136}
                        sxStyle={{
                          margin: '0px',
                          marginLeft: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          borderRadius: 'var(--4, 4px)'
                        }}
                      />
                    </Box>
                  </Box>
                )}
                {conditionResultNote && (
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
                    <Text variant={'bodyMd'} sx={{ width: '160px', color: '#505864' }} m={0}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.attachedNote')}
                    </Text>
                    <Text
                      variant={'bodyMd'}
                      m={0}
                      sx={{
                        marginLeft: '8px',
                        fontSize: '16px',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all',
                        overflow: 'hidden',
                        maxWidth: '655px'
                      }}
                    >
                      {conditionResultNote}
                    </Text>
                  </Box>
                )}
                {conditionResultCancel && (
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '13px' }}>
                    <Text variant={'bodyMd'} sx={{ width: '160px', color: '#505864' }} m={0}>
                      {tTemplates('equipmentItem.roundRegulationsDetails.cancelReason')}
                    </Text>
                    <Text
                      variant={'bodyMd'}
                      m={0}
                      sx={{
                        marginLeft: '8px',
                        fontSize: '16px',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        wordBreak: 'break-all',
                        overflow: 'hidden',
                        maxWidth: '655px'
                      }}
                    >
                      {conditionResultCancel}
                    </Text>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle sx={{ position: 'relative' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            sx={{ position: 'absolute', right: '15px', top: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={dialogImageUrl} alt="Enlarged view" style={{ width: '950px', height: '632px' }} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
