import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { EquipmentParameterRes, RoundRegulationsQuery } from '@/logicLayers/domain';
import { GET_TASKS } from '@/logicLayers/domain/tasks';

import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps';

import { DomainStatusChip } from '@/separatedModules/components';
import {
  Breadcrumbs,
  ChevronRightIcon,
  EMPTY_CELL_VALUE,
  Flex,
  TooltipOverflowItem
} from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  if (breadcrumbs.length > 2) {
    breadcrumbs.splice(1, breadcrumbs.length - 2, { link: '', titleFallback: '...' });
  }

  return breadcrumbs;
}

export const getParamItemConfig = (row: any) => {
  return PARAMETERS_LIST_FULL.find((paramItem) => row.original.type === paramItem.parameterType);
};

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const getColumns = (selectedItems: string[], handleSelectAll: () => void, dataLength: number) => [
  columnHelper.accessor('checked', {
    header: () => (
      <Checkbox
        onClick={handleSelectAll}
        checked={selectedItems.length === dataLength}
        className="m-0"
        labelPlacement="end"
        label=""
      />
    ),
    cell: ({ row }) => {
      return (
        <Checkbox
          className="m-0"
          checked={selectedItems.includes(row.original.id ?? '')}
          labelPlacement="end"
          label=""
        />
      );
    }
  }),
  columnHelper.accessor('title', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Task title
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box component={'span'} sx={{ width: 268 }}>
          {row.original?.name ? (
            <Text component={'span'} className={'m-0'} variant={'titleSm'}>
              <TooltipOverflowItem>{row.original?.name}</TooltipOverflowItem>
            </Text>
          ) : null}

          {row.original?.description ? (
            <Text
              component={'span'}
              variant={'bodySm'}
              sx={{ margin: '4px 0 0 0 !important', color: 'var(--text-subtle, #505668)' }}
            >
              <TooltipOverflowItem>{row.original?.description}</TooltipOverflowItem>
            </Text>
          ) : (
            EMPTY_CELL_VALUE
          )}
        </Box>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'staff',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Service Stuff
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Flex>
          <Box sx={{ marginRight: '8px' }}>
            <Flex>
              {row.original.serviceStaff.map((service, index) => (
                <>
                  {service?.userAvatar?.url ? (
                    <img
                      width={24}
                      height={24}
                      key={index}
                      src={service.userAvatar?.url}
                      style={{
                        borderRadius: '16px',
                        border: '1px solid #F7F8F9',
                        position: 'relative',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        flex: '0 0 24px',
                        borderRadius: '50%',
                        background: 'rgb(240, 240, 241)',
                        border: '1px solid #F7F8F9',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  )}
                </>
              ))}
            </Flex>
          </Box>
          <Flex
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              margin: '4px 0 0 0 !important'
            }}
          >
            {row.original.serviceStaff.map((service, index) => {
              const isLast = index === row.original.serviceStaff.length - 1;
              const displayName =
                service?.firstName.length && service?.lastName.length
                  ? `${service.firstName} ${service.lastName}`
                  : EMPTY_CELL_VALUE;

              return (
                <Text sx={{ margin: '4px 0 0 0 !important' }} component={'span'} variant={'bodyMd'} key={index}>
                  {`${displayName}${!isLast ? ', ' : ''}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('equipment', {
    id: 'domain',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Domain
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <>
          {row.original.equipment[0]?.domain?.id ? (
            <DomainStatusChip
              color={row.original.equipment[0]?.domain?.color}
              label={row.original.equipment[0]?.domain?.name}
            />
          ) : (
            <Text className={'m-0'} variant={'bodyLg'}>
              {EMPTY_CELL_VALUE}
            </Text>
          )}
        </>
      );
    }
  }),
  columnHelper.accessor('equipment', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Equipment
      </Text>
    ),
    cell: ({ row }) => {
      const name = row.original.equipment[0].name;

      return (
        <Flex alignItems="center">
          <Box sx={{ margin: '0 0 0 8px' }}>
            {name ? (
              <Text
                className={'m-0'}
                variant={'inherit'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {name}
              </Text>
            ) : (
              EMPTY_CELL_VALUE
            )}
          </Box>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('equipment', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Location
      </Text>
    ),
    cell: ({ row }) => {
      const breadcrumbsLinks = getBreadcrumbs(row.original.equipment[0].location);

      return (
        <Flex alignItems="center">
          <Box sx={{ margin: '0 0 0 8px' }}>
            <Text className={'m-0'} variant={'bodyLg'}>
              {breadcrumbsLinks.length > 0 ? (
                <Breadcrumbs
                  links={breadcrumbsLinks}
                  separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                  sx={{ padding: 0, width: '160px' }}
                  fontSize="14px"
                  separatorStyle={{ padding: '0px', width: '16px' }}
                />
              ) : (
                EMPTY_CELL_VALUE
              )}
            </Text>
          </Box>
        </Flex>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDate: [],
    sortByStatus: [],
    sortByRound: [],
    sortByDomains: [],
    filterByRole: [],
    filterByServiceStuff: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<RoundRegulationsQuery>(GET_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        domainIds: state.filters.sortByDomains.length ? state.filters.sortByDomains : undefined
      },
      type: 'ROUND',
      statuses: state.filters.sortByStatus.length ? state.filters.sortByStatus : undefined,
      userPermissionTags: state.filters.filterByRole.length ? state.filters.filterByRole : undefined,
      serviceStaffIds: state.filters.filterByServiceStuff.length ? state.filters.filterByServiceStuff : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options
        }
      };
    });
  }, []);

  const handleChangeByRound = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRound: options
        }
      };
    });
  }, []);

  const handleChangeSortByDomains = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeFilterByRole = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByRole: options.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeFilterByServiceStuff = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByServiceStuff: options.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        filters: {
          search: [],
          hiddenColumns: [],
          sortByDate: [],
          sortByStatus: [],
          sortByRound: []
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleSelectAll = useCallback((items: string[]) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: items
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleSelectAll,
    handleDoubleClickRow,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByStatus,
    handleResetSelectedRows,
    handleChangeByRound,
    handleChangeSortByDomains,
    handleResetFilters,
    handleChangeFilterByRole,
    handleChangeFilterByServiceStuff,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
