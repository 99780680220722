export const getBreadcrumbs = (data: any) => {
  const breadcrumbParts = data?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  breadcrumbParts.push(data?.name);

  const breadcrumbsLinks = breadcrumbParts
    .filter((item: string | undefined) => item !== undefined)
    .map((item: string) => ({
      titleFallback: item
    }));

  if (breadcrumbsLinks.length > 2) {
    breadcrumbsLinks.splice(1, breadcrumbsLinks.length - 2, { titleFallback: '...' });
  }

  return breadcrumbsLinks;
};

export enum FrequencyEnum {
  DAILY = 'Daily round',
  WEEKLY = 'Weekly round',
  MONTHLY = 'Monthly round',
  EVERY_3_MONTH = 'Every 3 months round',
  EVERY_6_MONTH = 'Every 6 months round',
  YEARLY = 'Yearly round',
  SINGLE_TIME_ROUND = 'Single time round'
}
