import { i18n } from '@/i18n/config';

interface Breadcrumb {
  link: string;
  titleFallback: string;
}

interface GroupItem {
  active: boolean;
  id: string;
  name: string;
  __typename: string;
  breadcrumbs?: Breadcrumb[];
  groupItems?: { items: GroupItem[]; __typename: string };
}

export const transformDataToGroupItems = (data: any[]): GroupItem[] => {
  return data.map((item) => ({
    active: item.active || false,
    id: item.id,
    name: item.name,
    __typename: item.__typename,
    breadcrumbs: item.breadcrumbs,
    groupItems: item.groupItems
      ? {
          items: transformDataToGroupItems(item.groupItems.items),
          __typename: item.groupItems.__typename
        }
      : undefined
  }));
};

export const getBreadcrumbs = (data: GroupItem[], folderId: string | undefined): Breadcrumb[] | undefined => {
  const result: GroupItem[] = [];

  const processItems = (items: GroupItem[]) => {
    items.forEach((item: GroupItem) => {
      const newItem = { ...item };

      if (!newItem.breadcrumbs) {
        newItem.breadcrumbs = [{ link: '/companies', titleFallback: i18n.t('pageTitle', { ns: 'company' }) }];
      }

      if (newItem.groupItems?.items?.length) {
        newItem.groupItems = {
          ...newItem.groupItems,
          items: newItem.groupItems.items.map((i: GroupItem) => ({
            ...i,
            breadcrumbs: [...newItem.breadcrumbs!, { link: `/companies/${newItem.id}`, titleFallback: newItem.name }]
          }))
        };

        processItems(newItem.groupItems.items);
      }

      result.push(newItem);
    });
  };

  processItems(data);

  const resultItem = result.find((item: GroupItem) => item.id === folderId);

  const resultItemBreadcrumbs = resultItem?.breadcrumbs;

  if (resultItemBreadcrumbs) {
    resultItemBreadcrumbs.push({ link: `/companies/${resultItem.id}`, titleFallback: resultItem.name });
  }

  return resultItemBreadcrumbs;
};
