import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { LocationFolderRes, Maybe } from '@/logicLayers/domain';
import { Color, EquipmentsQuery, EquipmentType, GET_EQUIPMENTS } from '@/logicLayers/domain';

import {
  AlertCheckNoticeIcon,
  ChevronRightIcon,
  DoNotDisturbOnIcon,
  EMPTY_CELL_VALUE,
  ImageLazy,
  MenuOption,
  SystemStatusChip,
  SystemStatusesEnum
} from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

interface EquipmentsType {
  __typename?: 'EquipmentRes';
  active: boolean;
  archived: boolean;
  createdDate: any;
  templateDescription?: string | null;
  itemDescription?: string | null;
  domainId: string;
  id?: string | null;
  manufacturer?: string | null;
  modelNumber?: string | null;
  name: string;
  locationId?: string | null;
  location?: LocationFolderRes;
  specifications?: string | null;
  systemName?: string | null;
  domain: { __typename?: 'DomainDto'; color: Color; id?: string | null; name: string; itemsCount: any };
  fileUrl?: { __typename?: 'UrlInfo'; url: string; fileKey: string } | null;
}

const columnHelper = createColumnHelper<EquipmentsType>();

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const createStatuses = () => {
  return [
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.enabled', { ns: 'templates' }),
      value: true,
      icon: <AlertCheckNoticeIcon sx={{ fontSize: 20 }} />
    },
    {
      checked: false,
      label: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      key: i18n.t('equipment.table.statuses.disabled', { ns: 'templates' }),
      value: false,
      icon: <DoNotDisturbOnIcon />
    }
  ];
};

export const renderBreadcrumbs = (location?: Maybe<LocationFolderRes>) => {
  const breadcrumbParts = location?.parentsLocationFolders?.map((folder: any) => folder.name).reverse() || [];

  const links: { link?: string; titleFallback: any }[] = breadcrumbParts
    .filter((item: string | undefined) => !!item)
    .map((item: string) => ({
      titleFallback: item
    }));

  if (location?.name) {
    links.push({
      link: '', // todo add link
      titleFallback: location.name
    });
  }

  if (links.length > 2) {
    links.splice(1, links.length - 2, { titleFallback: '...' });
  }

  return links.length ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {links.map(({ link, titleFallback }, index) => {
        const isLastItem = index === links.length - 1;
        const isFirstItem = index === 0;

        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            {isFirstItem ? '' : <ChevronRightIcon />}

            {isLastItem ? (
              <Link to={link ?? ''}>
                <Text
                  sx={{
                    color: '#0C66E4',
                    fontSize: '14px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {titleFallback}
                </Text>
              </Link>
            ) : (
              <Text
                sx={{
                  fontSize: '14px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical'
                }}
              >
                {titleFallback}
              </Text>
            )}
          </Box>
        );
      })}
    </Box>
  ) : (
    EMPTY_CELL_VALUE
  );
};

export const columns = [
  columnHelper.accessor('name', {
    header: (props) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
          {i18n.t(`equipmentItem.items.table.cells.${props.column.id}`, { ns: 'templates' })}
        </Text>
      );
    },
    cell: ({ row }) => {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {row.original.fileUrl ? (
            <ImageLazy
              src={row.original.fileUrl.url}
              variant={'rectangular'}
              width={24}
              height={24}
              alt={`Equipment ${name} - image`}
            />
          ) : (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                flex: '0 0 24px',
                margin: '0',
                background: 'rgba(9, 14, 22, 0.06)',
                border: '1px solid var(--border-inverse, #F7F8F9)'
              }}
            />
          )}

          <Box sx={{ margin: '0 0 0 16px' }}>
            {row.original.name ? (
              <Text
                className={'m-0'}
                variant={'titleSm'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {row.original.name}
              </Text>
            ) : null}

            {row.original.itemDescription ? (
              <Text
                className={'m-0'}
                variant={'bodySm'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {row.original.itemDescription}
              </Text>
            ) : (
              EMPTY_CELL_VALUE
            )}
          </Box>
        </Box>
      );
    }
  }),
  columnHelper.accessor('systemName', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.items.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Link to={''}>
          <Text
            className={'m-0'}
            variant={'bodyMd'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-all',
              color: '#0C66E4'
            }}
          >
            {row.original.systemName || EMPTY_CELL_VALUE}
          </Text>
        </Link>
      );
    }
  }),
  columnHelper.accessor('locationId', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.items.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      return renderBreadcrumbs(row.original.location);
    }
  }),
  columnHelper.accessor('active', {
    header: (props) => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t(`equipmentItem.items.table.cells.${props.column.id}`, { ns: 'templates' })}
      </Text>
    ),
    cell: ({ row }) => {
      const status = row.original.active
        ? SystemStatusesEnum.Enabled
        : !row.original.active && !row.original.archived
          ? SystemStatusesEnum.Disabled
          : SystemStatusesEnum.Archived;

      return <SystemStatusChip status={status} />;
    }
  })
];

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDomains: [],
    sortByType: [],
    sortByTypeSelected: '',
    sortByDate: [],
    sortByStatus: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT, templateId: string | undefined) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<EquipmentsQuery>(GET_EQUIPMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      templateId: templateId || undefined,
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        isActive: state.filters.sortByStatus.length ? Boolean(state.filters.sortByStatus[0].value) : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        }
      },
      type: EquipmentType.Equipment
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeSortByStatus = useCallback((options?: MenuOption[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeSortByStatus,
    handleResetSelectedRows,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
