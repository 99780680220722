import { gql as gqlApollo } from '@apollo/client';

export const LOCATION_ITEM_FRAGMENT = gqlApollo(`
  fragment locationFolderRes on LocationFolderRes {
    id
    name
    active
  }
`);

export const GET_LOCATIONS_SIDEBAR = gqlApollo`
  ${LOCATION_ITEM_FRAGMENT}
  query GetLocationsSidebar($searchCriteria: SearchCriteriaInput, $locationFolderId: String) {
    locationFolders(searchCriteria: $searchCriteria, locationFolderId: $locationFolderId) {
      items {
        ...locationFolderRes,
        groupItems: locationFolders(searchCriteria: $searchCriteria) {
          items {
            ...locationFolderRes,
            groupItems: locationFolders(searchCriteria: $searchCriteria) {
              items {
                ...locationFolderRes,
                groupItems: locationFolders(searchCriteria: $searchCriteria) {
                  items {
                    ...locationFolderRes,
                    groupItems: locationFolders(searchCriteria: $searchCriteria) {
                      items {
                        ...locationFolderRes
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS_LIST = gqlApollo(`
  query GetLocationsList($searchCriteria: SearchCriteriaInput, $includeHierarchy: Boolean) {
    locationFolders(searchCriteria: $searchCriteria, includeHierarchy: $includeHierarchy) {
      total
      items {
        id
        name
        parentsLocationFolders {
          id
          name
          depth
        }
      }
    }
  }
`);

export const CREATE_LOCATION_FOLDER = gqlApollo(`
  mutation CreateLocationFolder($name: String!, $description: String, $parentId: String) {
    createLocationFolder(locationFolder: { name: $name, description: $description, parentId: $parentId}) {
      id
      name
    }
  }
`);

export const GET_LOCATION_FOLDER = gqlApollo(`
  query LocationFolder($id: String!) {
    locationFolder(id: $id) {
      id
      name
      description
      parentsLocationFolders {
        depth
        id
        name
      }
    }
  }
`);

export const GET_LOCATION_FOLDER_WITH_STATUSES = gqlApollo(`
  query LocationFolderWithStatuses($id: String!) {
    locationFolder(id: $id) {
      active
      archived
    }
  }
`);

export const GET_LOCATIONS_TAB = gqlApollo(`
  query GetLocationsTab($searchCriteria: SearchCriteriaInput, $locationFolderId: String) {
    dataItems: locationFolders(searchCriteria: $searchCriteria, locationFolderId: $locationFolderId) {
      items {
        id
        name
        active
      }
    }
  }
`);

export const GET_LOCATION_FOLDER_BY_ID = gqlApollo(`
  query LocationFolderById($id: String!, $searchCriteria: SearchCriteriaInput) {
    locationFolder(id: $id) {
      id
      name
      locationFolders(searchCriteria: $searchCriteria) {
        items {
          id
          name
          active
        }
      }
    }
  }
`);

export const MOVE_LOCATION_TO_LOCATION = gqlApollo(`
  mutation MoveLocationToLocation($sourceLocationId: String!, $destinationLocationId: String) {
    moveLocationToLocation(moveRequest: { sourceLocationId: $sourceLocationId, destinationLocationId: $destinationLocationId })
  }
`);

export const SWITCH_LOCATIONS_ACTIVE_STATE = gqlApollo(`
  mutation SwitchLocationsActiveState($locationIds: [String]!, $active: Boolean!) {
    switchLocationsActiveState(request: { locationIds: $locationIds, active: $active})
  }
`);

export const SWITCH_LOCATIONS_ARCHIVE_STATE = gqlApollo(`
  mutation SwitchLocationsArchiveState($locationIds: [String]!, $archived: Boolean!) {
    switchLocationsArchiveState(request: { locationIds: $locationIds, archived: $archived})
  }
`);

export const EDIT_LOCATION_FOLDER = gqlApollo(`
  mutation EditLocationFolder($id: String!, $name: String!, $description: String) {
    editLocationFolder(id: $id, locationFolder: { name: $name, description: $description }) {
      id
      name
    }
  }
`);
