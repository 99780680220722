import { useMediaQuery, useTheme } from '@mui/material';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

export function SignInLeftPart() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={'sign-in__logo'} style={isSmallScreen ? { display: 'flex', justifyContent: 'center' } : {}}>
      <img src={LINKS_IMAGES.logoAuthHeader} width={51} height={40} alt={'sign-in logo desktop'} loading={'eager'} />
    </div>
  );
}
