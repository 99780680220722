import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { EquipmentParameterRes, RoundRegulationsQuery } from '@/logicLayers/domain';
import { GET_UNPLANNED_TASKS } from '@/logicLayers/domain/tasks';

import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps';

import { DomainStatusChip, EMPTY_CELL_VALUE, Flex, TooltipOverflowItem } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

export const getParamItemConfig = (row: any) => {
  return PARAMETERS_LIST_FULL.find((paramItem) => row.original.type === paramItem.parameterType);
};

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const ParameterTypeTyped = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  ON_HOLD: 'ON_HOLD',
  ON_HOLD_REQUESTED: 'ON_HOLD_REQUESTED'
};

export const ParameterTypeTypedAccomplished = {
  COMPLETED: 'COMPLETED',
  OVERDUE: 'OVERDUE',
  CANCELLED: 'CANCELLED'
};

export const ParametersTypesList = [
  {
    label: 'Pending',
    value: ParameterTypeTyped.PENDING,
    checked: false,
    disabled: false
  },
  {
    label: 'In progress',
    value: ParameterTypeTyped.IN_PROGRESS,
    checked: false,
    disabled: false
  },
  {
    label: 'On hold',
    value: ParameterTypeTyped.ON_HOLD,
    checked: false,
    disabled: false
  },
  {
    label: 'On hold pending',
    value: ParameterTypeTyped.ON_HOLD_REQUESTED,
    checked: false,
    disabled: false
  },
  {
    label: 'Completed',
    value: ParameterTypeTypedAccomplished.COMPLETED,
    checked: false,
    disabled: false
  },
  {
    label: 'Overdue',
    value: ParameterTypeTypedAccomplished.OVERDUE,
    checked: false,
    disabled: false
  },
  {
    label: 'Cancelled',
    value: ParameterTypeTypedAccomplished.CANCELLED,
    checked: false,
    disabled: false
  }
];

export const getColumns = (selectedItems: string[], handleSelectAll: () => void, dataLength: number) => [
  columnHelper.accessor('checked', {
    header: () => (
      <Checkbox
        onClick={handleSelectAll}
        checked={selectedItems.length === dataLength}
        className="m-0"
        labelPlacement="end"
        label=""
      />
    ),
    cell: ({ row }) => {
      return (
        <Checkbox
          className="m-0"
          checked={selectedItems.includes(row.original.id ?? '')}
          labelPlacement="end"
          label=""
        />
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'staff',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Service staff
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Flex>
          <Box sx={{ marginRight: '8px' }}>
            <Flex>
              {row.original.serviceStaff.map((service, index) => {
                return (
                  <>
                    {service?.userAvatar?.url ? (
                      <img
                        width={24}
                        height={24}
                        key={index}
                        src={service.userAvatar?.url}
                        style={{
                          borderRadius: '16px',
                          border: '1px solid #F7F8F9',
                          position: 'relative',
                          marginLeft: index ? '-5px' : '0px',
                          zIndex: `${row.original.serviceStaff.length - index}`
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          flex: '0 0 24px',
                          borderRadius: '50%',
                          background: 'rgb(240, 240, 241)',
                          border: '1px solid #F7F8F9',
                          marginLeft: index ? '-5px' : '0px',
                          zIndex: `${row.original.serviceStaff.length - index}`
                        }}
                      />
                    )}
                  </>
                );
              })}
            </Flex>
          </Box>
          <Flex
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              margin: '4px 0 0 0 !important'
            }}
          >
            {row.original.serviceStaff.map((service, index) => {
              const isLast = index === row.original.serviceStaff.length - 1;
              const displayName =
                service?.firstName.length && service?.lastName.length
                  ? `${service.firstName} ${service.lastName}`
                  : EMPTY_CELL_VALUE;

              return (
                <Text sx={{ margin: '4px 0 0 0 !important' }} component={'span'} variant={'bodyMd'} key={index}>
                  {`${displayName}${!isLast ? ', ' : ''}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('title', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Round regulation
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box component={'span'} sx={{ width: 268 }}>
          {row.original?.name ? (
            <Text component={'span'} className={'m-0'} variant={'titleSm'}>
              <TooltipOverflowItem>{row.original?.name}</TooltipOverflowItem>
            </Text>
          ) : null}

          {row.original?.description ? (
            <Text
              component={'span'}
              variant={'bodySm'}
              sx={{ margin: '4px 0 0 0 !important', color: 'var(--text-subtle, #505668)' }}
            >
              <TooltipOverflowItem>{row.original?.description}</TooltipOverflowItem>
            </Text>
          ) : (
            EMPTY_CELL_VALUE
          )}
        </Box>
      );
    }
  }),
  columnHelper.accessor('equipment', {
    id: 'domain',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Domain
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <>
          {row.original.equipment[0]?.domain?.id ? (
            <DomainStatusChip
              color={row.original.equipment[0]?.domain?.color}
              label={row.original.equipment[0]?.domain?.name}
            />
          ) : (
            <Text className={'m-0'} variant={'bodyLg'}>
              {EMPTY_CELL_VALUE}
            </Text>
          )}
        </>
      );
    }
  }),
  columnHelper.accessor('assignee', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Role
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.assignee?.roleTemplate?.name ? row.original.assignee?.roleTemplate?.name : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  }),
  columnHelper.accessor('status', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Status
      </Text>
    ),
    cell: ({ row }) => {
      return <TaskStatusChip status={row.original.status} />;
    }
  }),
  columnHelper.accessor('createdDate', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Creation date & time
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.createdDate ? dayjs(row.original.createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDate: [],
    sortByStatus: [],
    sortByRound: [],
    sortByDomains: [],
    filterByRole: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<RoundRegulationsQuery>(GET_UNPLANNED_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        domainIds: state.filters.sortByDomains.length ? state.filters.sortByDomains : undefined
      },
      statuses: state.filters.sortByStatus.length ? state.filters.sortByStatus : undefined,
      permissionTags: state.filters.filterByRole.length ? state.filters.filterByRole : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options
        }
      };
    });
  }, []);

  const handleChangeByRound = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRound: options
        }
      };
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        filters: {
          search: [],
          hiddenColumns: [],
          sortByDate: [],
          sortByStatus: [],
          sortByRound: []
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleSelectAll = useCallback((items: string[]) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: items
      };
    });
  }, []);

  const handleChangeSortByDomains = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options?.length ? [...options] : []
        }
      };
    });
  }, []);

  const handleChangeFilterByRole = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByRole: options.length ? [...options] : []
        }
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleSelectAll,
    handleDoubleClickRow,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByStatus,
    handleResetSelectedRows,
    handleChangeByRound,
    handleResetFilters,
    handleChangeSortByDomains,
    handleChangeFilterByRole,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
