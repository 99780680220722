import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import { Avatar, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { selectUserData, useReduxSelector } from '@/logicLayers/infrastructure/redux';

import { useTranslation } from '@/i18n';

dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
});

export const HeaderMobile = () => {
  const { t: tGlobal } = useTranslation('global');
  const navigateTo = useNavigate();
  const { profile } = useReduxSelector(selectUserData);

  const userFirstName = profile?.firstName || '';
  const userLastName = profile?.lastName || '';
  const userLogo = profile?.profileAvatar?.url || '';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        background: '#FFFFFF'
      }}
    >
      <Box>
        <Text sx={{ fontSize: '18px', fontWeight: '600', color: '#121B2A' }}>
          {tGlobal('mobile.headerTitle', { value: userFirstName })}
        </Text>
        <Text sx={{ fontSize: '12px', color: '#505864' }}>
          {tGlobal('mobile.headerDate', { value: dayjs().format('ddd DD MMM') })}
        </Text>
      </Box>
      <Box>
        <Avatar
          className={'border-0'}
          size={'32'}
          sx={{ width: '40px', height: '40px', border: 'none', margin: '0 0 0 12px' }}
          src={userLogo}
          onClick={() => navigateTo('/profile-mobile')}
        >
          {userFirstName?.slice(0, 1)}
          {userLastName?.slice(0, 1)}
        </Avatar>
      </Box>
    </Box>
  );
};
