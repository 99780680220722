import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  Flex,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap
} from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { FilterByRole } from './components/FilterByRole';
import { FilterByServiceStuff } from './components/FilterByServiceStuff';
import { SortByDomain } from './components/SortByDomain';

import { FIELD_RESULTS_OF_COMPLETED_ROUNDS } from '../../helpers';
import { getColumns, initState, skeletonTableLoaderCells, useTableData } from './helpers';
export const ResultOfCompletedTasks = () => {
  const { t: tTasks } = useTranslation('tasks');
  const { t: tWidgets } = useTranslation('widgets');

  const { renderCount, ...tableState } = useTableData(initState);

  const { setValue } = useFormContext();

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const handleSelectAll = () => {
    const selectedItems = tableState.state.selectedItems.length ? [] : theData.map((item) => String(item.id));
    tableState.handleSelectAll(selectedItems);
  };

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns: getColumns(tableState.state.selectedItems, handleSelectAll, theData.length),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  useEffect(() => {
    setValue(FIELD_RESULTS_OF_COMPLETED_ROUNDS, tableState.state.selectedItems);
  }, [tableState.state.selectedItems]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item sx={{ marginTop: '20px' }}>
        <TablePageTopBox title={tWidgets('createWidgets.resultOfRounds.title')} isHideButton={true} />
        <Text
          sx={{
            maxWidth: '664px',
            fontSize: '14px',
            color: 'var(--text-subtle, #505668)',
            fontWeight: '400',
            marginBottom: '24px'
          }}
        >
          {tWidgets('createWidgets.resultOfRounds.subTitle')}
        </Text>

        <Box>
          <TableFiltersBox
            searchPlaceholder={tWidgets('createWidgets.multiStep.searchPlaceholder')}
            handleChangeBySearch={tableState.handleChangeBySearch}
            withInfoIcon={false}
            isCompactSearch
            sxBoxRight={{ display: 'flex', justifyContent: 'flex-end', flex: 'unset !important' }}
          >
            <FilterByRole handleChange={tableState.handleChangeFilterByRole} />
            <SortByDomain handleChange={tableState.handleChangeSortByDomains} />
            <FilterByServiceStuff handleChange={tableState.handleChangeFilterByServiceStuff} />
          </TableFiltersBox>
        </Box>

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {table.getVisibleLeafColumns().length ? (
              renderCount ? (
                <Flex>
                  <Box width={'100%'}>
                    <Box sx={{ overflow: 'auto' }}>
                      <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                        <Table className={'m-0 MuiTable-root__columns-5'}>
                          <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <TableCell
                                      key={header.id}
                                      className={header.column.id === 'checked' ? 'checkbox' : undefined}
                                    >
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableHead>

                          <TableBody>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <TableRowWrap
                                  key={row.original.id}
                                  id={row.original.id as string}
                                  isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                  handleClickRow={(rowId) => {
                                    tableState.handleClickRow(rowId);
                                  }}
                                  handleDoubleClickRow={tableState.handleDoubleClickRow}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <TableCell
                                        key={cell.id}
                                        className={cell.column.id === 'checked' ? 'checkbox' : undefined}
                                      >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </TableCell>
                                    );
                                  })}
                                </TableRowWrap>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <MuiTablePagination
                      count={renderCount}
                      page={tableState.state.page[0]}
                      rowsPerPage={tableState.state.rowsPerPage[0]}
                      rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                      onChangePage={tableState.handleChangePagination}
                      onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                    />
                  </Box>
                </Flex>
              ) : tableState.state.filters.search[0] ? (
                <NotFoundScreens type={'search'} />
              ) : (
                <NotFoundScreens type={'companies'} title={tTasks('tasks.notTasksYet')} />
              )
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};
