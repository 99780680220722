import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import {
  EDIT_EQUIPMENT,
  EDIT_PARAMETER,
  SEARCH_DOMAINS,
  SEARCH_UNITS,
  SearchDomainsQuery,
  SearchUnitsQuery
} from '@/logicLayers/domain';

import {
  AutocompleteOptionT,
  DomainStatusChip,
  EditAutocompleteTagsItem,
  EditInputItem,
  ImageLazy
} from '@/separatedModules/components';
import { EditEmergencyContact } from '@/separatedModules/components/EditItem/EditEmergencyContact';
import { EditFormula } from '@/separatedModules/components/EditItem/EditFormula';

import { PARAMETER_VALUE_REG_EXP, ROLE_DESCRIPTION_REG_EXP, TEXT_FIELD_REG_EXP } from '@/utils';

import { LINKS_IMAGES } from '@/subsidiaryBinders/constants';

import { ParameterTypeBox } from '../../../Parameter/components/ParameterTypeBox';

import { ITEM_FIELDS } from '../../../../helpers';
import { PARAMETER_TYPE, TYPE_LIST } from '../../../Parameter/helpers';
import {
  GetEditComponentProps,
  onSubmitUpdateContact,
  onSubmitUpdateDomainId,
  onSubmitUpdateItemDescription,
  onSubmitUpdateManufacturer,
  onSubmitUpdateModelNumber,
  onSubmitUpdateName,
  onSubmitUpdateParameterDescription,
  onSubmitUpdateParameterNumericValue,
  onSubmitUpdateParameterTitle,
  onSubmitUpdateParameterUnit,
  onSubmitUpdateTemplateDescription
} from './helpers';

interface ShortInfoProps {
  imageUrl: string | undefined;
  title: string;
  domain?: {
    color: string;
    name: string;
  };
  type?: string;
  isParameter?: boolean;
}

const ShortInfo = ({ imageUrl = LINKS_IMAGES.panorama, title, domain, isParameter = false, type }: ShortInfoProps) => {
  return (
    <Box
      sx={{
        borderRadius: 'var(--4, 4px)',
        background: 'var(--background-neutral-hovered, #F7F8F9)',
        display: 'inline-flex',
        alignItems: 'center',
        padding: '8px'
      }}
    >
      {imageUrl ? (
        <ImageLazy
          src={imageUrl}
          variant={'rectangular'}
          width={32}
          height={32}
          alt={`Equipment ${title} - image`}
          sxStyle={{ borderRadius: 'var(--2, 2px)', marginRight: '8px', display: 'flex' }}
        />
      ) : null}

      <Text variant={'labelLg'} sx={{ margin: '8px 8px 8px 0' }}>
        {title}
      </Text>

      {domain ? (
        <Box sx={{ margin: '8px 0' }}>
          <DomainStatusChip color={domain?.color} label={domain.name} />
        </Box>
      ) : null}

      {isParameter ? (
        <Box sx={{ marginLeft: '16px' }}>
          <ParameterTypeBox title={type?.includes('NUMERIC') ? 'Numeric data' : PARAMETER_TYPE[type || '']} />

          {type?.includes('NUMERIC') ? <ParameterTypeBox title={PARAMETER_TYPE[type]} isEdit /> : null}
        </Box>
      ) : null}
    </Box>
  );
};

export const EditProvider = ({
  /* custom fields */
  editType,
  navigateToUrl,
  tModel,
  tGlobal,
  isEquipmentTemplate,
  /* Company fields */
  ...restItem
}: GetEditComponentProps) => {
  const domainFormed: AutocompleteOptionT[] = restItem?.equipment.domain
    ? [
        {
          key: restItem.equipment.domain.id || '',
          label: restItem.equipment.domain.name || '',
          value: restItem.equipment.domain.id || ''
        }
      ]
    : [];

  const domainParameterFormed: AutocompleteOptionT[] = restItem?.dataItems?.unit
    ? [
        {
          key: restItem?.dataItems?.unitId || '',
          label: restItem?.dataItems?.unit.name || '',
          value: restItem?.dataItems?.unitId || ''
        }
      ]
    : [];

  return editType === ITEM_FIELDS.NAME ? (
    <EditInputItem
      editFieldName={ITEM_FIELDS.NAME}
      editFieldValue={restItem.equipment.name}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldLength')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={
        isEquipmentTemplate
          ? tModel('equipmentItem.edit.name.pageTitle')
          : tModel('equipmentItem.edit.name.pageTitleItem')
      }
      pageSubTitle={
        isEquipmentTemplate
          ? tModel('equipmentItem.edit.name.pageSubTitle', { value: restItem.equipment.name })
          : tModel('equipmentItem.edit.name.pageSubTitleItem')
      }
      inputLabel={tModel('equipmentItem.edit.name.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      onSubmitForm={onSubmitUpdateName({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.TEMPLATE_DESCRIPTION ? (
    <EditInputItem
      editFieldName={ITEM_FIELDS.TEMPLATE_DESCRIPTION}
      editFieldValue={restItem.equipment.templateDescription || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={tModel('equipmentItem.edit.templateDescription.pageTitle')}
      pageSubTitle={tModel('equipmentItem.edit.templateDescription.pageSubTitle', {
        value: restItem.equipment.name
      })}
      inputLabel={tModel('equipmentItem.edit.templateDescription.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      regExp={TEXT_FIELD_REG_EXP}
      regExpMaxLength={200}
      maxContentLength={200}
      rows={3}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      multiline={true}
      onSubmitForm={onSubmitUpdateTemplateDescription({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.ITEM_DESCRIPTION ? (
    <EditInputItem
      editFieldName={ITEM_FIELDS.ITEM_DESCRIPTION}
      editFieldValue={restItem.equipment.itemDescription || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={tModel('equipmentItem.edit.itemDescription.pageTitle')}
      pageSubTitle={tModel('equipmentItem.edit.itemDescription.pageSubTitle', {
        value: restItem.equipment.name
      })}
      inputLabel={tModel('equipmentItem.edit.itemDescription.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      regExp={TEXT_FIELD_REG_EXP}
      regExpMaxLength={200}
      maxContentLength={200}
      rows={3}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      multiline={true}
      onSubmitForm={onSubmitUpdateItemDescription({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.MANUFACTURER ? (
    <EditInputItem
      editFieldName={ITEM_FIELDS.MANUFACTURER}
      editFieldValue={restItem.equipment.manufacturer || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 64 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={
        isEquipmentTemplate
          ? tModel('equipmentItem.edit.manufacturer.pageTitle')
          : tModel('equipmentItem.edit.manufacturer.pageTitleItem')
      }
      pageSubTitle={tModel('equipmentItem.edit.manufacturer.pageSubTitle', { value: restItem.equipment.name })}
      inputLabel={tModel('equipmentItem.edit.manufacturer.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      onSubmitForm={onSubmitUpdateManufacturer({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.MODEL_NUMBER ? (
    <EditInputItem
      editFieldName={ITEM_FIELDS.MODEL_NUMBER}
      editFieldValue={restItem.equipment.modelNumber || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 64 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={
        isEquipmentTemplate
          ? tModel('equipmentItem.edit.modelNumber.pageTitle')
          : tModel('equipmentItem.edit.modelNumber.pageTitleItem')
      }
      pageSubTitle={tModel('equipmentItem.edit.modelNumber.pageSubTitle', { value: restItem.equipment.name })}
      inputLabel={tModel('equipmentItem.edit.modelNumber.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      onSubmitForm={onSubmitUpdateModelNumber({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.DOMAIN_ID ? (
    <EditAutocompleteTagsItem<SearchDomainsQuery>
      editFieldName={ITEM_FIELDS.DOMAIN_ID}
      editFieldValue={domainFormed}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={
        isEquipmentTemplate
          ? tModel('equipmentItem.edit.domainId.pageTitle')
          : tModel('equipmentItem.edit.domainId.pageTitleItem')
      }
      pageSubTitle={tModel('equipmentItem.edit.domainId.pageSubTitle', { value: restItem.equipment.name })}
      inputLabel={tModel('equipmentItem.edit.domainId.filedLabel')}
      mutationQuery={EDIT_EQUIPMENT}
      dataQuery={SEARCH_DOMAINS}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      generateOptions={(data: SearchDomainsQuery): AutocompleteOptionT[] => {
        return data?.dataItems.items.map((domain) => ({
          label: domain?.name as string,
          value: domain?.id as string,
          key: domain?.id as string
        }));
      }}
      onSubmitForm={onSubmitUpdateDomainId({ ...restItem })}
    />
  ) : editType === ITEM_FIELDS.PARAMETER_TITLE ? (
    <EditInputItem
      editFieldName={'title'}
      editFieldValue={restItem.dataItems?.title || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldLength')}
      minLengthMessage={tGlobal('validation.textFieldLength')}
      maxLengthMessage={tGlobal('validation.textFieldLength')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Parameter title'}
      pageSubTitle={`<ul>
      <li>Provide a descriptive and informative title for the parameter</li>
      <li>This title serves as a reference point for mechanics when conducting equipment rounds, helping them quickly identify the specific aspect of the equipment they need to monitor</li>
      </ul>`}
      inputLabel={tModel('Parameter title')}
      regExp={ROLE_DESCRIPTION_REG_EXP}
      mutationQuery={EDIT_PARAMETER}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.dataItems?.title || ''}
          imageUrl={TYPE_LIST[restItem?.dataItems?.type || '']}
          isParameter
          type={restItem.dataItems?.type}
        />
      }
      onSubmitForm={onSubmitUpdateParameterTitle({ ...restItem.dataItems })}
    />
  ) : editType === ITEM_FIELDS.NUMERIC_VALUE ? (
    <EditInputItem
      editFieldName={'value'}
      editFieldValue={restItem.dataItems?.value || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      patternMessage={tGlobal('validation.numberWithPoint', {
        value: 2
      })}
      minLengthMessage={tGlobal('validation.textFieldMinMaxNumericLength', {
        valueMin: 1,
        valueMax: 10
      })}
      maxLengthMessage={tGlobal('validation.textFieldMinMaxNumericLength', {
        valueMin: 1,
        valueMax: 10
      })}
      submitBtnText={tGlobal('editPage.btnSave')}
      regExpMinLength={1}
      regExpMaxLength={10}
      pageTitle={'Numeric value'}
      pageSubTitle={
        "Please enter a numeric value for using in formulas creation. Note that the parameter won't be directly visible during equipment rounds or in the round results"
      }
      inputLabel={'Numeric value'}
      regExp={PARAMETER_VALUE_REG_EXP}
      mutationQuery={EDIT_PARAMETER}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.dataItems?.title || ''}
          imageUrl={TYPE_LIST[restItem?.dataItems?.type || '']}
          isParameter
          type={restItem.dataItems?.type}
        />
      }
      onSubmitForm={onSubmitUpdateParameterNumericValue({ ...restItem.dataItems })}
    />
  ) : editType === ITEM_FIELDS.GUIDE ? (
    <EditInputItem
      editFieldName={'description'}
      editFieldValue={restItem.dataItems?.description || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 400 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Parameter guide'}
      pageSubTitle={
        'Please provide clear and concise instructions for the service user, who will be responsible for recording parameter data during equipment rounds'
      }
      inputLabel={'Parameter guide'}
      mutationQuery={EDIT_PARAMETER}
      regExp={TEXT_FIELD_REG_EXP}
      regExpMaxLength={400}
      maxContentLength={400}
      sxDescription={{
        '.MuiFormHelperText-filled': {
          justifyContent: 'end'
        },
        '.Mui-error': {
          justifyContent: 'start'
        }
      }}
      rows={9}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.dataItems?.title || ''}
          imageUrl={TYPE_LIST[restItem?.dataItems?.type || '']}
          isParameter
          type={restItem.dataItems?.type}
        />
      }
      multiline={true}
      onSubmitForm={onSubmitUpdateParameterDescription({ ...restItem.dataItems })}
    />
  ) : editType === ITEM_FIELDS.UNIT ? (
    <EditAutocompleteTagsItem<SearchUnitsQuery>
      editFieldName={'unitId'}
      isParameter
      editFieldValue={domainParameterFormed}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Parameter unit'}
      pageSubTitle={'Choose a unit from the list for the parameter'}
      inputLabel={'Parameter unit'}
      mutationQuery={EDIT_PARAMETER}
      dataQuery={SEARCH_UNITS}
      regExp={TEXT_FIELD_REG_EXP}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.dataItems?.title || ''}
          imageUrl={TYPE_LIST[restItem?.dataItems?.type || '']}
          isParameter
          type={restItem.dataItems?.type}
        />
      }
      generateOptions={(data: SearchDomainsQuery): AutocompleteOptionT[] => {
        return data?.dataItems.items.map((domain) => ({
          label: domain?.name as string,
          value: domain?.id as string
        }));
      }}
      onSubmitForm={onSubmitUpdateParameterUnit({ ...restItem.dataItems })}
    />
  ) : editType === ITEM_FIELDS.FORMULA ? (
    <EditFormula
      editFieldName={'value'}
      editFieldValue={restItem.dataItems?.value || ''}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      patternMessage={tGlobal('validation.textFieldMinMaxLength', { valueMin: 3, valueMax: 200 })}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Set formula'}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      pageSubTitle={`
      Set formula for the parameter:
      <ul>
        <li>Construct formulas using available numeric parameters and mathematical functions. Click on a function / parameter to insert it into the formula creation area, ensuring that the cursor is at the appropriate location within the area</li>
        <li>The calculation will be displayed in the round results
        </li>
        <li>The formula supports numbers with a dot as the decimal separator</li>
      </ul>`}
      inputLabel={tModel('equipmentItem.edit.itemDescription.filedLabel')}
      mutationQuery={EDIT_PARAMETER}
      regExp={TEXT_FIELD_REG_EXP}
      regExpMaxLength={200}
      maxContentLength={240}
      rows={9}
      name={restItem.dataItems?.title}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.dataItems?.title || ''}
          imageUrl={TYPE_LIST[restItem?.dataItems?.type || '']}
          isParameter
          type={restItem.dataItems?.type}
        />
      }
      templateId={restItem.dataItems?.equipmentId || ''}
      multiline={true}
      onSubmitForm={onSubmitUpdateParameterNumericValue({ ...restItem.dataItems })}
    />
  ) : editType === ITEM_FIELDS.CONTACT ? (
    <EditEmergencyContact
      editFieldName={'emergencyContact'}
      editFieldValue={restItem.equipment?.responsible?.id ? [restItem.equipment.responsible.id] : []}
      navigateToUrl={navigateToUrl}
      successAlertMessage={tGlobal('alertMessages.success.edits')}
      sxTitle={{ marginBottom: '16px', fontSize: '20px' }}
      errorAlertMessage={tGlobal('alertMessages.errors.base')}
      requiredMessage={tGlobal('validation.required')}
      submitBtnText={tGlobal('editPage.btnSave')}
      pageTitle={'Select emergency contact'}
      pageSubTitle={
        'Select the service staff (individual or group) responsible for this equipment. Choose a <br> user who has access to the domain corresponding to the current equipment template <br> domain'
      }
      mutationQuery={EDIT_EQUIPMENT}
      ContentAfterTitle={
        <ShortInfo
          title={restItem.equipment.name}
          imageUrl={restItem.equipment.fileUrl?.url}
          domain={restItem.equipment.domain}
        />
      }
      onSubmitForm={onSubmitUpdateContact({ ...restItem })}
    />
  ) : null;
};
