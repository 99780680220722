import { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { Flex } from '@/separatedModules/components';

interface DateAndTimePickerProps {
  value: {
    date: Dayjs;
    time: Dayjs;
  };
  onChange: (date: Dayjs, time: Dayjs) => void;
  minDate: Dayjs;
  maxDate: Dayjs;
  displayText: string;
  minTime: Dayjs;
}

export const DateAndTimePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  displayText,
  minTime: initialMinTime
}: DateAndTimePickerProps) => {
  const [minTime, setMinTime] = useState<Dayjs | null>(null);
  useEffect(() => {
    if (value.date.isSame(dayjs(), 'day')) {
      setMinTime(dayjs());
    } else {
      setMinTime(null);
    }
  }, [value.date]);

  return (
    <>
      <Flex sx={{ padding: '0px 16px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            value={value.date}
            onChange={(newDate) => onChange(newDate!, value.time)}
            sx={{ width: '288px', marginTop: '16px', marginRight: '16px' }}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              textField: {
                helperText: 'MM/DD/YYYY'
              }
            }}
          />
          <TimePicker
            label="Start time"
            value={value.time}
            onChange={(newTime) => onChange(value.date, newTime!)}
            minTime={minTime || initialMinTime}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock
            }}
            sx={{
              width: '288px'
            }}
            slotProps={{
              textField: {
                helperText: 'HH:MM'
              }
            }}
          />
        </LocalizationProvider>
      </Flex>
      <Text variant={'bodyMd'} sx={{ color: 'var(--text-subtle, #505668)', padding: '0px 16px', marginTop: '20px' }}>
        {displayText}
      </Text>
    </>
  );
};
