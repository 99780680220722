import { gql as gqlApollo } from '@apollo/client';

export const GET_IMAGE_URL = gqlApollo(`
  query GetImageUrl($fileKey: String!) {
    fileUrl(fileKey: $fileKey) {
      fileKey
      url
    }
  }
`);

export const SEARCH_UNITS = gqlApollo(`
  query SearchUnits($searchCriteria: SearchCriteriaInput) {
    dataItems: searchUnits(searchCriteria: $searchCriteria) {
      items {
        id
        name
      }
    }
  }
`);

export const SUBSCRIBE_TO_NOTIFICATIONS = gqlApollo(`
  subscription SubscribeToNotifications {
    subscribeToNotifications {
      id
      content
      type
      title
    }
  }
`);

export const GET_NOTIFICATIONS = gqlApollo(`
  query GetNotifications($searchCriteria: SearchCriteriaInput) {
    notifications(searchCriteria: $searchCriteria) {
      items {
        content {
          content
          title
        }
        createdDate
        id
        type
        read
      }
      total
    }
  }
`);

export const MARK_ALL_NOTIFICATIONS_AS_READ = gqlApollo(`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead
  }
`);

export const MARK_NOTIFICATION_AS_READ = gqlApollo(`
  mutation MarkNotificationAsRead($notificationId: String!) {
    markNotificationAsRead(notificationId: $notificationId)
  }
`);

export const DELETE_NOTIFICATION = gqlApollo(`
  mutation DeleteNotification($notificationId: String!) {
    deleteNotification(notificationId: $notificationId)
  }
`);
