import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { EquipmentParameterRes, RoundRegulationsQuery } from '@/logicLayers/domain';
import { GET_TASKS } from '@/logicLayers/domain/tasks';

import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps';

import { EMPTY_CELL_VALUE, Flex, TooltipOverflowItem } from '@/separatedModules/components';
import { TaskStatusChip } from '@/separatedModules/components/Chips/TaskStatusChip';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

export const getParamItemConfig = (row: any) => {
  return PARAMETERS_LIST_FULL.find((paramItem) => row.original.type === paramItem.parameterType);
};

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const ParameterTypeTypedAccomplished = {
  COMPLETED: 'COMPLETED',
  OVERDUE: 'OVERDUE',
  CANCELLED: 'CANCELLED'
};

export const ParametersTypesListAccomplished = [
  {
    label: 'Completed',
    value: ParameterTypeTypedAccomplished.COMPLETED,
    checked: false,
    disabled: false
  },
  {
    label: 'Overdue',
    value: ParameterTypeTypedAccomplished.OVERDUE,
    checked: false,
    disabled: false
  },
  {
    label: 'Cancelled',
    value: ParameterTypeTypedAccomplished.CANCELLED,
    checked: false,
    disabled: false
  }
];

export const columns = [
  columnHelper.accessor('title', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t('tasks.table.roundTitle', { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box component={'span'} sx={{ width: 268 }}>
          {row.original?.name ? (
            <Text component={'span'} className={'m-0'} variant={'titleSm'}>
              <TooltipOverflowItem>{row.original?.name}</TooltipOverflowItem>
            </Text>
          ) : null}

          {row.original?.description ? (
            <Text
              component={'span'}
              variant={'bodySm'}
              sx={{ margin: '4px 0 0 0 !important', color: 'var(--text-subtle, #505668)' }}
            >
              <TooltipOverflowItem>{row.original?.description}</TooltipOverflowItem>
            </Text>
          ) : (
            EMPTY_CELL_VALUE
          )}
        </Box>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'staff',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t('tasks.table.serviceStaff', { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Flex>
          <Box sx={{ marginRight: '8px' }}>
            <Flex>
              {row.original.serviceStaff.map((service, index) => (
                <>
                  {service?.userAvatar?.url ? (
                    <img
                      width={24}
                      height={24}
                      key={index}
                      src={service.userAvatar?.url}
                      style={{
                        borderRadius: '16px',
                        border: '1px solid #F7F8F9',
                        position: 'relative',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        flex: '0 0 24px',
                        borderRadius: '50%',
                        background: 'rgb(240, 240, 241)',
                        border: '1px solid #F7F8F9',
                        marginLeft: index ? '-5px' : '0px',
                        zIndex: `${row.original.serviceStaff.length - index}`
                      }}
                    />
                  )}
                </>
              ))}
            </Flex>
          </Box>
          <Flex
            sx={{
              maxWidth: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              margin: '4px 0 0 0 !important'
            }}
          >
            {row.original.serviceStaff.map((service, index) => {
              const isLast = index === row.original.serviceStaff.length - 1;
              const displayName =
                service?.firstName.length && service?.lastName.length
                  ? `${service.firstName} ${service.lastName}`
                  : EMPTY_CELL_VALUE;

              return (
                <Text sx={{ margin: '4px 0 0 0 !important' }} component={'span'} variant={'bodyMd'} key={index}>
                  {`${displayName}${!isLast ? ', ' : ''}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('createdDate', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t('tasks.table.creationDate', { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Text className={'m-0'} component={'span'} variant={'bodyMd'}>
          {row.original.createdDate ? dayjs(row.original.createdDate).format('DD MMM YYYY, HH:mm A') : EMPTY_CELL_VALUE}
        </Text>
      );
    }
  }),
  columnHelper.accessor('status', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {i18n.t('tasks.table.status', { ns: 'tasks' })}
      </Text>
    ),
    cell: ({ row }) => {
      return <TaskStatusChip status={row.original.status} />;
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDate: [],
    sortByStatus: [],
    sortByRound: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

const getRoundIds = (items: string[]) => {
  const result: string[] = [];

  items.forEach((item: any) => result.push(item.split(',')));

  return result.flat();
};

export const useTableData = (initState: initStateT, equipmentId: string) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<RoundRegulationsQuery>(GET_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined
        }
      },
      type: 'ROUND',
      statuses: state.filters.sortByStatus.length ? state.filters.sortByStatus : ['COMPLETED', 'OVERDUE', 'CANCELLED'],
      roundRegulationIds: state.filters.sortByRound.length ? getRoundIds(state.filters.sortByRound) : undefined,
      equipmentIds: [equipmentId]
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options
        }
      };
    });
  }, []);

  const handleChangeByRound = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRound: options
        }
      };
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        filters: {
          search: [],
          hiddenColumns: [],
          sortByDate: [],
          sortByStatus: [],
          sortByRound: []
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByStatus,
    handleResetSelectedRows,
    handleChangeByRound,
    handleResetFilters,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
