import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

// Todo Insert to Theme
export const StyledTableRow = styled(TableRow)(() => ({
  borderBottom: '1px solid #090E1626'
}));

// Todo Insert to Theme
export const StyledTableBodyRow = styled(StyledTableRow)<{ selected: boolean }>(({ selected }) => ({
  borderBottom: '1px solid #090E1626',
  background: selected ? '#090E1608' : 'initial',

  ':hover': {
    background: '#090E1608'
  }
}));

// Todo Insert to Theme
export const StyledTableHeaderRow = styled(StyledTableRow)(() => ({
  borderBottomWidth: '2px'
}));

// Todo Insert to Theme
export const StyledTableCell = styled(TableCell)<{ hide?: boolean }>(({ hide }) => ({
  padding: '16px 8px',
  display: hide ? 'none' : 'flex',
  flexWrap: 'wrap',
  gap: '8px'
}));
