import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Text } from 'infinitytechnologies-ui';

import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';

import { EquipmentParameterRes, GET_ROUND_USERS, GetUsersQuery } from '@/logicLayers/domain';

import { DomainStatusChip, Flex, ImageLazy } from '@/separatedModules/components';
import { EMPTY_CELL_VALUE, skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

const columnHelper = createColumnHelper<EquipmentParameterRes>();

export const columns = [
  columnHelper.accessor('title', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Name'}
      </Text>
    ),
    cell: ({ row }) => {
      const displayName =
        row.original.firstName.length && row.original.lastName.length
          ? `${row.original.firstName} ${row.original.lastName}`
          : EMPTY_CELL_VALUE;

      return (
        <Flex sx={{ width: 268 }} alignItems="center">
          {row.original.userAvatar ? (
            <ImageLazy
              src={row.original.userAvatar?.url}
              alt={row.original.userAvatar?.url}
              width={24}
              height={24}
              sxStyle={{ marginRight: '8px' }}
            />
          ) : (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                marginRight: '8px',
                borderRadius: '50%',
                background: 'rgb(240, 240, 241)'
              }}
            />
          )}

          <Text sx={{ fontWeight: '600', fontSize: '14px' }}>{displayName}</Text>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'staff',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Role'}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Box component={'span'} sx={{ width: 268 }}>
          <Text sx={{ fontSize: '14px' }}>{row.original.permissionTag}</Text>
        </Box>
      );
    }
  }),
  columnHelper.accessor('type', {
    id: 'status',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        {'Domain'}
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <Tooltip title={`${row.original.domains.map((domain) => domain?.name)}`} placement="top-start">
          <Flex
            sx={{
              overflow: 'hidden',
              position: 'relative',
              width: '100%'
            }}
          >
            {row.original.domains.map((domain, index) => (
              <Box key={index} sx={{ marginRight: '8px' }}>
                <DomainStatusChip color={domain?.color} label={domain?.name} />
              </Box>
            ))}
          </Flex>
        </Tooltip>
      );
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isAsideOpen: boolean;
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    domains: [],
    roles: [],
    sortByDate: {}
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isAsideOpen: false,
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT, companyId: string) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<GetUsersQuery>(GET_ROUND_USERS, {
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        sortable: {
          column: state.filters.sortByDate.length ? 'createdDate' : undefined,
          direction: state.filters.sortByDate.length ? state.filters.sortByDate[0] : undefined,
          nullPrecedence: state.filters.sortByDate.length ? 'NULLS_LAST' : undefined
        },
        domainIds: state.filters.domains,
        companyId
      },
      permissions: state.filters.roles
    },
    fetchPolicy: 'no-cache'
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string, singleSelect: boolean = false) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = singleSelect
        ? [rowId]
        : inSelectedExist
          ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
          : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);
  const handleClearSelectedItems = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options
        }
      };
    });
  }, []);

  const handleToggleAside = useCallback(() => {
    setState((state) => ({ ...state, isAsideOpen: !state.isAsideOpen }));
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByDomains = useCallback((value: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          domains: value
        }
      };
    });
  }, []);

  const handleChangeByRoles = useCallback((value: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          roles: value
        }
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleDoubleClickRow,
    handleToggleAside,
    handleChangeBySearch,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0,
    handleChangeByDomains,
    handleChangeByRoles,
    handleClearSelectedItems,
    handleChangeSortByDate
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
