import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function CallIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#313849', fontSize: 20, ...sx }}
      {...restProps}
    >
      <path
        d="M16.2006 17.5831C14.6301 17.5831 13.0524 17.218 11.4675 16.4878C9.88252 15.7575 8.42633 14.7274 7.09889 13.3972C5.77143 12.0671 4.74258 10.6109 4.01235 9.02867C3.28211 7.44641 2.91699 5.87003 2.91699 4.29952C2.91699 4.04723 3.00033 3.83699 3.16699 3.6688C3.33366 3.5006 3.54199 3.4165 3.79199 3.4165H6.50991C6.72038 3.4165 6.90601 3.48515 7.0668 3.62244C7.2276 3.75973 7.32989 3.92934 7.37368 4.13125L7.85124 6.58315C7.88437 6.81072 7.87742 7.00623 7.83041 7.16969C7.78341 7.33315 7.69901 7.47043 7.5772 7.58155L5.65253 9.45494C5.96237 10.0222 6.31627 10.5588 6.71424 11.0647C7.11222 11.5706 7.54303 12.0538 8.0067 12.5143C8.46398 12.9715 8.9501 13.3962 9.46506 13.7883C9.98001 14.1804 10.5361 14.5452 11.1333 14.8828L13.0035 12.9966C13.1339 12.8609 13.2917 12.7659 13.4771 12.7114C13.6624 12.6569 13.855 12.6435 14.0548 12.6713L16.3689 13.1425C16.5793 13.198 16.7511 13.3054 16.8841 13.4646C17.0171 13.6238 17.0836 13.8043 17.0836 14.0063V16.7081C17.0836 16.9581 16.9995 17.1665 16.8313 17.3331C16.6631 17.4998 16.4529 17.5831 16.2006 17.5831ZM5.0612 8.27228L6.54839 6.84919C6.5751 6.82782 6.59246 6.79844 6.60047 6.76105C6.60849 6.72364 6.60715 6.68892 6.59647 6.65688L6.23428 4.79469C6.2236 4.75196 6.20491 4.71991 6.1782 4.69855C6.15149 4.67717 6.11677 4.66648 6.07403 4.66648H4.29197C4.25993 4.66648 4.23322 4.67717 4.21185 4.69855C4.19047 4.71991 4.17978 4.74662 4.17978 4.77867C4.22252 5.34812 4.31574 5.92664 4.45943 6.51425C4.60314 7.10187 4.80373 7.68787 5.0612 8.27228ZM12.3112 15.4742C12.8635 15.7317 13.4397 15.9285 14.0396 16.0647C14.6395 16.2009 15.2001 16.2819 15.7215 16.3075C15.7535 16.3075 15.7802 16.2968 15.8016 16.2755C15.823 16.2541 15.8336 16.2274 15.8336 16.1953V14.4421C15.8336 14.3994 15.823 14.3647 15.8016 14.3379C15.7802 14.3112 15.7482 14.2925 15.7054 14.2819L13.9554 13.9261C13.9234 13.9154 13.8953 13.9141 13.8713 13.9221C13.8473 13.9301 13.8219 13.9475 13.7952 13.9742L12.3112 15.4742Z"
        fill="#265299"
      />
    </SvgIcon>
  );
}
