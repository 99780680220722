import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function FilterIcon(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      sx={{ fill: '#3C4656', fontSize: 18, ...sx }}
      {...restProps}
    >
      <path d="M11.3848 19.5C11.1335 19.5 10.9232 19.4153 10.754 19.2461C10.5847 19.0769 10.5001 18.8666 10.5001 18.6153V12.8269L4.90208 5.71538C4.70978 5.45896 4.6819 5.19229 4.81843 4.91538C4.95497 4.63846 5.18541 4.5 5.50976 4.5H18.4905C18.8148 4.5 19.0452 4.63846 19.1818 4.91538C19.3183 5.19229 19.2904 5.45896 19.0981 5.71538L13.5001 12.8269V18.6153C13.5001 18.8666 13.4155 19.0769 13.2462 19.2461C13.077 19.4153 12.8667 19.5 12.6155 19.5H11.3848ZM12.0001 12.3L16.9501 5.99998H7.05011L12.0001 12.3Z" />
    </SvgIcon>
  );
}
