import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { EquipmentRes, GET_EQUIPMENTS, RoundRegulationsQuery } from '@/logicLayers/domain';

import { PARAMETERS_LIST_FULL } from '@/separatedModules/pages/Templates/pages/ParameterItemCreate/components/Steps';

import {
  Breadcrumbs,
  BuildingIcon,
  ChevronRightIcon,
  DomainStatusChip,
  EMPTY_CELL_VALUE,
  Flex,
  ImageLazy
} from '@/separatedModules/components';
import { skeletonTableCellImage, skeletonTableCellRect } from '@/separatedModules/components/Table';

import { i18n } from '@/i18n';

function getBreadcrumbs(locationFolder: any) {
  const breadcrumbs = [{ link: '/locations', titleFallback: i18n.t('pageTitle', { ns: 'locations' }) }];

  if (locationFolder?.parentsLocationFolders) {
    [...locationFolder.parentsLocationFolders].reverse().forEach((parent: any) => {
      breadcrumbs.push({
        link: `/locations/${parent.id}`,
        titleFallback: parent.name
      });
    });
  }

  if (locationFolder?.name) {
    breadcrumbs.push({
      link: '',
      titleFallback: locationFolder?.name
    });
  }

  if (breadcrumbs.length > 2) {
    breadcrumbs.splice(1, breadcrumbs.length - 2, { link: '', titleFallback: '...' });
  }

  return breadcrumbs;
}

export const getParamItemConfig = (row: any) => {
  return PARAMETERS_LIST_FULL.find((paramItem) => row.original.type === paramItem.parameterType);
};

const columnHelper = createColumnHelper<EquipmentRes>();

export const getColumns = (selectedItems: string[], handleSelectAll: () => void, dataLength: number) => [
  columnHelper.accessor('checked', {
    header: () => (
      <Checkbox
        onClick={handleSelectAll}
        checked={selectedItems.length === dataLength}
        className="m-0"
        labelPlacement="end"
        label=""
      />
    ),
    cell: ({ row }) => {
      return (
        <Checkbox
          className="m-0"
          checked={selectedItems.includes(row.original.id ?? '')}
          labelPlacement="end"
          label=""
        />
      );
    }
  }),
  columnHelper.accessor('name', {
    id: 'equipment',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Equipment
      </Text>
    ),
    cell: ({ row }) => {
      const fileUrl = row.original.fileUrl;
      const name = row.original.name;
      const description = row.original.itemDescription;

      return (
        <Flex alignItems="center">
          {fileUrl ? (
            <ImageLazy
              src={fileUrl.url}
              variant={'rectangular'}
              width={24}
              height={24}
              alt={`Equipment ${name} - image`}
            />
          ) : (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                flex: '0 0 24px',
                margin: '0',
                background: 'rgba(9, 14, 22, 0.06)',
                border: '1px solid var(--border-inverse, #F7F8F9)'
              }}
            />
          )}

          <Box sx={{ margin: '0 0 0 8px' }}>
            {name ? (
              <Text
                className={'m-0'}
                variant={'inherit'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all'
                }}
              >
                {name}
              </Text>
            ) : (
              EMPTY_CELL_VALUE
            )}

            <Text
              className={'m-0'}
              sx={{
                color: '#505668',
                fontSize: '12px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-all'
              }}
            >
              {description ? description : EMPTY_CELL_VALUE}
            </Text>
          </Box>
        </Flex>
      );
    }
  }),
  columnHelper.accessor('location', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Location
      </Text>
    ),
    cell: ({ row }) => {
      const breadcrumbsLinks = getBreadcrumbs(row.original.location);

      return (
        <>
          {breadcrumbsLinks.length > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px' }}>
              <BuildingIcon />
              <Breadcrumbs
                links={breadcrumbsLinks}
                separator={<ChevronRightIcon sx={{ height: '10px', width: '16px' }} />}
                sx={{ padding: 0, width: '160px' }}
                fontSize="14px"
                separatorStyle={{ padding: '0px', width: '16px' }}
              />
            </Box>
          ) : (
            EMPTY_CELL_VALUE
          )}
        </>
      );
    }
  }),
  columnHelper.accessor('domain', {
    id: 'domain',
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        Domain
      </Text>
    ),
    cell: ({ row }) => {
      return (
        <>
          {row.original.domain?.id ? (
            <DomainStatusChip color={row.original.domain?.color} label={row.original.domain?.name} />
          ) : (
            <Text className={'m-0'} variant={'bodyLg'}>
              {EMPTY_CELL_VALUE}
            </Text>
          )}
        </>
      );
    }
  }),
  columnHelper.accessor('systemName', {
    header: () => (
      <Text className={'m-0'} component={'span'} variant={'labelMd'} sx={{ color: 'var(--text-subtle, #505668)' }}>
        System name
      </Text>
    ),
    cell: ({ row }) => {
      return <Text sx={{ fontSize: '14px', color: '#326CC9' }}>{row.original.systemName}</Text>;
    }
  })
];

export type initStateT = {
  actions: any;
  resultSearchCount: number;
  resultTotalCount: number;
  filters: Record<any, any>;
  selectedItems: string[];
  page: number[];
  rowsPerPage: number[];
  rowsPerPageOptions: number[];
  isVisiblePagination: boolean;
  isVisibleActions: boolean;
};

export const initState: initStateT = {
  filters: {
    search: [],
    hiddenColumns: [],
    sortByDate: [],
    sortByStatus: [],
    sortByRound: [],
    filterByLocation: [],
    filterByRole: [],
    sortByDomains: []
  },
  actions: {},
  page: [0],
  rowsPerPage: [20],
  rowsPerPageOptions: [20, 50, 100],
  resultSearchCount: 0,
  resultTotalCount: 0,
  selectedItems: [],
  isVisiblePagination: false,
  isVisibleActions: false
};

export const useTableData = (initState: initStateT) => {
  const [state, setState] = useState<initStateT>(initState);

  const { data: Data, loading } = useQuery<RoundRegulationsQuery>(GET_EQUIPMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        query: state.filters.search.length ? state.filters.search[0] : undefined,
        pageable: {
          page: state.page[0],
          pageSize: state.rowsPerPage[0]
        },
        domainIds: state.filters.sortByDomains.length ? state.filters.sortByDomains : undefined
      },
      type: 'EQUIPMENT',
      locationIds: state.filters.filterByLocation.length ? state.filters.filterByLocation : undefined
    }
  });

  const handleChangePagination = useCallback((newPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: [newPage]
      };
    });
  }, []);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setState((state) => {
      return {
        ...state,
        page: initState.page,
        rowsPerPage: [newRowsPerPage]
      };
    });
  }, []);

  const handleDoubleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: [rowId],
        isAsideOpen: true
      };
    });
  }, []);

  const handleClickRow = useCallback((rowId: string) => {
    setState((state) => {
      const inSelectedExist = state.selectedItems.includes(rowId);

      const updatedSelectedItems = inSelectedExist
        ? state.selectedItems.filter((selectedRowId) => selectedRowId !== rowId)
        : [rowId, ...state.selectedItems];

      return {
        ...state,
        selectedItems: updatedSelectedItems
      };
    });
  }, []);

  const handleChangeSortByDate = useCallback((options?: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDate: options?.length ? options : []
        }
      };
    });
  }, []);

  const handleChangeBySearch = useCallback((value: string) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: value ? [value] : []
        }
      };
    });
  }, []);

  const handleChangeByStatus = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByStatus: options
        }
      };
    });
  }, []);

  const handleChangeByRound = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRound: options
        }
      };
    });
  }, []);

  const handleChangeByLocation = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByLocation: options
        }
      };
    });
  }, []);

  const handleChangeByRole = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          filterByRole: options
        }
      };
    });
  }, []);

  const handleChangeByDomain = useCallback((options: string[]) => {
    setState((state) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByDomains: options
        }
      };
    });
  }, []);

  const handleResetFilters = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        filters: {
          search: [],
          hiddenColumns: [],
          sortByDate: [],
          sortByStatus: [],
          sortByRound: []
        }
      };
    });
  }, []);

  const handleResetSelectedRows = useCallback(() => {
    setState((state) => {
      return {
        ...state,
        selectedItems: []
      };
    });
  }, []);

  const handleSelectAll = useCallback((items: string[]) => {
    setState((state) => {
      return {
        ...state,
        selectedItems: items
      };
    });
  }, []);

  const data = useMemo(() => Data, [Data]);

  return {
    handleChangePagination,
    handleChangeRowsPerPage,
    handleClickRow,
    handleSelectAll,
    handleDoubleClickRow,
    handleChangeSortByDate,
    handleChangeBySearch,
    handleChangeByStatus,
    handleResetSelectedRows,
    handleChangeByRound,
    handleResetFilters,
    handleChangeByLocation,
    handleChangeByRole,
    handleChangeByDomain,
    state,
    data,
    loading,
    renderCount: data?.dataItems?.total || 0,
    renderItems: data?.dataItems?.items?.length || 0
  };
};

export const skeletonTableLoaderCells = [
  [skeletonTableCellImage, skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect],
  [skeletonTableCellRect]
];
