import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Checkbox, Text } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { GET_LOCATIONS_LIST, GetLocationsList } from '@/logicLayers/domain';

import { CloseIcon, SearchIcon } from '@/separatedModules/components';

import { useTranslation } from '@/i18n';

import { StyledSearchInput } from './styles';

interface MenuOption {
  id: string;
  name: string;
  checked?: boolean;
  parentsLocationFolders: { id: string; name: string; depth: number }[];
}

interface MobileLocationFilterProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onChangeFilter: (filter: MenuOption[]) => void;
}

export const MobileLocationFilter = ({ isOpen, onClose, onOpen, onChangeFilter }: MobileLocationFilterProps) => {
  const { t: tTasks } = useTranslation('tasks');

  const [searchFilter, setSearchFilter] = useState('');
  const [items, setItems] = useState<MenuOption[]>([]);

  const { data: locationsData } = useQuery<GetLocationsList>(GET_LOCATIONS_LIST, {
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      },
      includeHierarchy: false
    }
  });

  useEffect(() => {
    setItems(locationsData?.locationFolders.items.map((item) => ({ ...item, checked: false })) ?? []);
  }, [locationsData]);

  const areAllChecked = items.every((option) => option.checked);

  const handleItemClick = (option: MenuOption) => {
    setItems((prev) =>
      prev.map((prevColumn) => {
        if (prevColumn.id === option.id) {
          return { ...prevColumn, checked: !option.checked };
        }

        return prevColumn;
      })
    );
  };

  const handleSelectAllClick = () => {
    setItems((prev) => prev.map((prevColumn) => ({ ...prevColumn, checked: !areAllChecked })));
  };

  const handleApplyClick = () => {
    onChangeFilter(areAllChecked ? [] : items.filter((item) => item.checked));
    onClose();
  };

  const handleCancelClick = () => {
    setItems((prev) => prev.map((prevColumn) => ({ ...prevColumn, checked: false })));
    onChangeFilter([]);
    onClose();
  };

  const filteredMenuList = useMemo(() => {
    return items.filter((item) => item.name.toLowerCase().includes(searchFilter.toLowerCase()));
  }, [items, searchFilter]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        sx: { borderTopRightRadius: '20px', borderTopLeftRadius: '20px' }
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '32px',
              height: '4px',
              backgroundColor: '#5C6C83',
              opacity: '0.4',
              borderRadius: '100px',
              margin: '16px'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '40px',
            position: 'relative',
            alignItems: 'center'
          }}
        >
          <Text m={0} sx={{ fontSize: '18px', fontWeight: '600' }}>
            {tTasks('mobile.filterTitle')}
          </Text>
          <CloseIcon
            sx={{
              fontSize: '24px',
              position: 'absolute',
              right: '12px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            onClick={onClose}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '16px 16px 8px 16px', gap: '16px' }}>
          <Text sx={{ fontSize: '16px', fontWeight: '600' }}> {tTasks('mobile.locationFilter')}</Text>
          <FormControl fullWidth variant={'outlined'} sx={{ margin: '0px 16px 0px 0px' }}>
            <StyledSearchInput
              value={searchFilter}
              placeholder={tTasks('mobile.searchPlaceholder')}
              startAdornment={
                <InputAdornment position={'start'}>
                  <SearchIcon />
                </InputAdornment>
              }
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFilter?.(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            margin: '8px 0px 8px 16px'
          }}
        >
          <Checkbox
            className={'m-0'}
            size="small"
            label={tTasks('mobile.showAll')}
            inputProps={{ 'aria-label': 'controlled' }}
            onClick={handleSelectAllClick}
            checked={areAllChecked}
          />
        </Box>
        <Divider />
        <Box sx={{ maxHeight: '168px', overflowY: 'scroll' }}>
          {filteredMenuList.map((item) => {
            const locationLabelNames = item.parentsLocationFolders
              .map((item) => item.name)
              .reverse()
              .join(' / ');

            const locationLabel = locationLabelNames.length ? locationLabelNames.concat(' / ') : locationLabelNames;
            return (
              <Box
                key={item.id}
                sx={{
                  height: '42px',
                  padding: '8px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%'
                }}
              >
                <Checkbox
                  className={'m-0'}
                  size="small"
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Text
                        sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                          overflow: 'hidden',
                          direction: 'rtl',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          textAlign: 'left',
                          width: 'calc(100vw - 80px)'
                        }}
                      >
                        {locationLabel}
                        <strong>{item.name}</strong>
                      </Text>
                    </Box>
                  }
                  onClick={() => handleItemClick(item)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={item.checked}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '12px 16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '16px 0px 37px 0px'
          }}
        >
          <Button variant="text" onClick={handleCancelClick}>
            {tTasks('mobile.clearBtn')}
          </Button>
          <Button variant={'contained'} sx={{ margin: '16px' }} onClick={handleApplyClick}>
            {tTasks('mobile.applyBtn')}
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
