import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from '@tanstack/react-table';

import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { SEARCH_ROUND_TASKS, SearchRoundTasksQuery } from '@/logicLayers/domain';

import {
  Flex,
  handleGetSavedColumns,
  handleSetSavedColumns,
  MuiTablePagination,
  NotFoundScreens,
  SkeletonTableLoader,
  SORT_BY_DATE_CREATE,
  TableAside,
  TableDropdownCheckbox,
  TableDropdownColumnsVisibility,
  TableFiltersBox,
  TablePageTopBox,
  TableRowWrap,
  TableSortByOneItem
} from '@/separatedModules/components';

import { useIsUserCanCRUD } from '@/utils';

import { LINKS_PAGES } from '@/subsidiaryBinders/constants';

import { i18n, useTranslation } from '@/i18n';

import { ActionsContainer } from '../Templates/pages/Equipment/pages/EquipmentItem/pages/Items/components';

import { AsideContent } from './components/AsideContent';

import {
  columns,
  initState,
  ParametersTypesList,
  ParametersTypesListAccomplished,
  skeletonTableLoaderCells,
  useTableData
} from './helpers';

export const TasksPage = () => {
  const { templateId = '' } = useParams();
  const { t: tTasks } = useTranslation('tasks');
  const navigateTo = useNavigate();
  const TABLE_SAVED_COLUMNS_ID = `templates/equipment/item/${templateId}/parameters`;
  const location = useLocation();
  const isAccomplished = location.pathname.includes('accomplished');
  const isUserCanCRUD = useIsUserCanCRUD({
    permissionsCheck: ['ROLE_EQUIPMENTS_CREATING', 'ROLE_EQUIPMENTS_UPDATING']
  });

  const { renderCount, ...tableState } = useTableData(initState, isAccomplished);

  const [checked, setChecked] = useState(false);

  const theData = useMemo(() => {
    return Array.isArray(tableState.data?.dataItems.items) ? tableState.data?.dataItems.items : [];
  }, [tableState.data]);

  const [columnVisibility, setColumnVisibility] = useState(() => {
    return handleGetSavedColumns(TABLE_SAVED_COLUMNS_ID);
  });

  const { data: roundTasksData } = useQuery<SearchRoundTasksQuery>(SEARCH_ROUND_TASKS, {
    fetchPolicy: 'no-cache',
    variables: {
      searchCriteria: {
        pageable: {
          pageSize: 1000
        }
      },
      statuses: isAccomplished
        ? ['COMPLETED', 'OVERDUE', 'CANCELLED']
        : ['IN_PROGRESS', 'ON_HOLD', 'ON_HOLD_REQUESTED', 'PENDING']
    }
  });

  const roundTasksOptions = useMemo(() => {
    if (!roundTasksData) {
      return [];
    }

    return roundTasksData?.dataItems.items.map((round) => ({
      label: round?.name as string,
      value: round?.ids as string[],
      checked: false,
      disabled: false
    }));
  }, [roundTasksData]);

  const table = useReactTable({
    // @ts-ignore
    data: theData,
    columns,
    state: {
      columnVisibility: columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  const handleClick = () => navigateTo(LINKS_PAGES.tasksCreate);

  const columnsVisibility = useMemo(() => {
    const columnsVisibilityStatus = table.getAllLeafColumns().reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.id]: currentValue.getIsVisible() };
    }, {});

    handleSetSavedColumns(TABLE_SAVED_COLUMNS_ID, columnsVisibilityStatus);

    return table.getAllLeafColumns().map((column) => {
      return {
        value: column.id,
        label: i18n.t(`equipmentItem.parameters.table.cells.${column.id}`, { ns: 'templates' }),
        checked: column.getIsVisible(),
        onToggleVisibility: column.toggleVisibility
      };
    });
  }, [columnVisibility]);

  useEffect(() => {
    if (tableState.state.selectedItems.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [tableState.state.selectedItems]);

  useEffect(() => {
    tableState.handleResetFilters();
  }, [location.pathname]);

  return (
    <Grid justifyContent={'center'} container row>
      <Grid xs={12} item>
        <TablePageTopBox
          title={isAccomplished ? tTasks('tasksAccomplished.pageTopTitle') : tTasks('tasks.pageTopTitle')}
          dataFoundTitle={i18n.t('pageTitleFound', { value: renderCount, ns: 'global' })}
          btnText={tTasks('tasks.pageTopButton')}
          onClickBtn={handleClick}
          isHideButton={!isUserCanCRUD}
        />

        <ActionsContainer
          handleResetSelectedRows={tableState.handleResetSelectedRows}
          state={tableState.state}
          handleToggleAside={tableState.handleToggleAside}
        />

        <Collapse in={!checked}>
          <TableFiltersBox
            searchPlaceholder={tTasks('tasks.searchTask')}
            selectedItems={tableState.state.selectedItems}
            handleToggleAside={tableState.handleToggleAside}
            handleChangeBySearch={tableState.handleChangeBySearch}
          >
            <TableDropdownColumnsVisibility
              menu={columnsVisibility}
              toggleAllColumnsVisible={table.toggleAllColumnsVisible}
            />

            <TableDropdownCheckbox
              // ToDo Refactor
              title={tTasks('tasks.roundRegulationFilterTitle')}
              menuList={roundTasksOptions as any}
              withSelectAll
              withSearch
              searchPlaceholder={tTasks('tasks.roundRegulationSearchFilterTitle')}
              selectAllTitle={tTasks('tasks.roundRegulationSearchFilterShowAll')}
              onChange={tableState.handleChangeByRound}
            />

            <TableDropdownCheckbox
              // ToDo Refactor
              title={tTasks('tasks.roundRegulationFilterStatus')}
              menuList={!isAccomplished ? ParametersTypesList : ParametersTypesListAccomplished}
              withSelectAll
              withSearch
              searchPlaceholder={tTasks('tasks.roundRegulationSearchFilterStatus')}
              selectAllTitle={tTasks('tasks.roundRegulationSearchFilterShowAll')}
              onChange={tableState.handleChangeByStatus}
            />

            <TableSortByOneItem
              menu={SORT_BY_DATE_CREATE}
              showIcon={true}
              onChange={tableState.handleChangeSortByDate}
            />
          </TableFiltersBox>
        </Collapse>

        {tableState.loading ? (
          <SkeletonTableLoader cells={skeletonTableLoaderCells} />
        ) : (
          <>
            {table.getVisibleLeafColumns().length ? (
              renderCount ? (
                <Flex>
                  <Box width={'100%'}>
                    <Box sx={{ overflow: 'auto' }}>
                      <TableContainer sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                        <Table className={'m-0 MuiTable-root__columns-5'}>
                          <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                  <TableCell key={header.id}>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(header.column.columnDef.header, header.getContext())}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableHead>

                          <TableBody>
                            {table.getRowModel().rows.map((row) => {
                              return (
                                <TableRowWrap
                                  key={row.original.id}
                                  id={row.original.id as string}
                                  isSelected={tableState.state.selectedItems.includes(row.original.id as string)}
                                  handleClickRow={tableState.handleClickRow}
                                  handleDoubleClickRow={tableState.handleDoubleClickRow}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <TableCell key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </TableCell>
                                    );
                                  })}
                                </TableRowWrap>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <MuiTablePagination
                      count={renderCount}
                      page={tableState.state.page[0]}
                      rowsPerPage={tableState.state.rowsPerPage[0]}
                      rowsPerPageOptions={tableState.state.rowsPerPageOptions}
                      onChangePage={tableState.handleChangePagination}
                      onChangeRowsPerPage={tableState.handleChangeRowsPerPage}
                    />
                  </Box>

                  <TableAside
                    rows={table.getRowModel().rows}
                    selectedItems={tableState.state.selectedItems}
                    isAsideOpen={tableState.state.isAsideOpen}
                    handleToggleAside={tableState.handleToggleAside}
                    sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {(props: any) => (
                      <AsideContent
                        handleChangeTest={() => {
                          navigateTo(`/tasks/${tableState.state.selectedItems[0]}/task`);
                        }}
                        {...props}
                      />
                    )}
                  </TableAside>
                </Flex>
              ) : tableState.state.filters.search[0] ? (
                <NotFoundScreens type={'search'} />
              ) : (
                <NotFoundScreens
                  type={'companies'}
                  title={isAccomplished ? tTasks('tasks.notAccomplishedTasksYet') : tTasks('tasks.notTasksYet')}
                  subTitle={tTasks('tasks.createTask')}
                />
              )
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};
