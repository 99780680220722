import { FC } from 'react';
import { Checkbox } from 'infinitytechnologies-ui';

import Box from '@mui/material/Box';

import { TextBlockControl } from '@/separatedModules/components';

import { StyledParameterPlate } from './style';

interface WidgetPlateProps {
  isSelected: boolean;
  widget: any;
  onClick: () => void;
}

export const WidgetPlate: FC<WidgetPlateProps> = ({ widget, isSelected, onClick }) => {
  return (
    <StyledParameterPlate key={widget.id} selected={isSelected} onClick={onClick}>
      <Box>
        <TextBlockControl
          className={'m-0'}
          title={widget.title}
          subTitle={widget.description}
          sxTitle={{ marginBottom: '8px' }}
          sxSubTitle={{ marginBottom: 0 }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Checkbox
          className={'m-0'}
          size={'medium'}
          label={''}
          inputProps={{ 'aria-label': 'controlled' }}
          checked={isSelected}
          labelPlacement="start"
        />
      </Box>
    </StyledParameterPlate>
  );
};
