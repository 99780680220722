import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledBreadcrumbs = styled(Box)(({ fontSize }) => ({
  padding: '32px 0 16px',
  display: 'flex',
  alignItems: 'center',
  '.breadcrumbs': {
    '&__item': {
      display: 'flex',
      alignItems: 'center',
      'a, p': {
        color: ' #090e16',
        fontSize: fontSize || '14px',
        lineHeight: '1.5',
        maxWidth: parseInt(fontSize, 10) > 14 ? '160px' : '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all'
      },
      '&:last-child': {
        'a, p': {
          fontWeight: '600'
        }
      },
      a: {
        transition: 'all .2s ease-in-out',
        '&:hover': {
          // color: 'var(--dark-orchid)'
        }
      },
      p: {
        fontWeight: '500'
      },
      span: {
        padding: '0 8px',
        fontSize: '16px',
        lineHeight: '1'
      }
    }
  }
}));
