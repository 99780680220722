import { Switch, Text } from 'infinitytechnologies-ui';

import { Box } from '@mui/material';

import { Flex } from '@/separatedModules/components/Layout';

interface ActivateRoundProps {
  title: string;
  subTitle: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const AdditionalBlock = ({ title, subTitle, value, onChange }: ActivateRoundProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--background-neutral-hovered, #F5F7FA)',
        padding: '8px 12px',
        borderRadius: 'var(--4, 4px)',
        maxWidth: '301px',
        height: '83px'
      }}
    >
      <Flex alignItems="center" justifyContent="space-between" marginBottom="4px">
        <Text
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            color: 'var(--text-default, #121B2A)'
          }}
        >
          {title}
        </Text>
        <Switch label="" size="small" className="m-0" checked={value} onChange={() => onChange(!value)} />
      </Flex>
      <Text
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          color: 'var(--text-default, #505864)'
        }}
      >
        {subTitle}
      </Text>
    </Box>
  );
};
