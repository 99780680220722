import { Text } from 'infinitytechnologies-ui';

import { TableCell, TableHead, TableRow } from '@mui/material';

import { CONFIG_TABLE } from '../../../helpers';

export const TableHeader = () => (
  <TableHead>
    <TableRow sx={{ alignItems: 'center' }}>
      {CONFIG_TABLE.cells.map(({ columnName, title }, index) => {
        return (
          <TableCell
            key={columnName}
            className={CONFIG_TABLE.cells[index].columnClass}
            sx={{
              flex: index === 0 ? '0 1 2% !important' : '0 1 15% !important',
              padding: '0 !important'
            }}
          >
            <Text variant={'labelMd'}>{title}</Text>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);
