import { useTranslation } from 'react-i18next';

import { TableBody, TableRow } from '@mui/material';

import { useContextState } from '@/separatedModules/pages/Locations/components/Providers';

import { CONFIG_TABLE } from '../../helpers';

export const TableWrapper = () => {
  const { selectedItems, bodyDataRows } = useContextState();
  const { t: tCompanies } = useTranslation('companies');

  const selectedData = bodyDataRows.filter((row) => selectedItems.includes(row.id || ''));

  return (
    <TableBody>
      {selectedData.map((row) => {
        return (
          <TableRow key={row.id}>
            {CONFIG_TABLE.cells.map((i, index) => {
              const className = `${CONFIG_TABLE.cells[index].columnClass}`;

              return i.Render(row, className, index, tCompanies);
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};
